import React from 'react'
import axios from "axios";
import { baseUrlDefiner } from '../../utils/config';
import { useState, useEffect } from 'react'
import { config } from "../../utils/config";
import styled from "styled-components"
import Cookies from 'js-cookie';

const { apiEndpoint } = config

// -------------------------------------Styled Components Start ---------------------------------------------------------
const Heading = styled.h5
    `
    letter-spacing: 3px;
    padding-left: 5px;
    margin-top: 10px;
    font-weight: 500

`

const ApplyButton = styled.button
    ` width: 90px; padding: 2px 6px; outline: none ;border: none; marginRight: 20px; font-weight: 600;
    margin-right: 10px; background-color : ${props => props.color}
`

// -------------------------------------Styled Components Start ---------------------------------------------------------
export default function Applyfilter(props) {

    useEffect(() => {
        brandlisting();
        categoryListing();
    }, [])

// --------------------------------------------all states start-----------------------------------------------------------------
    const [openfilterselection, setopenfilterselection] = useState(false);
    const [brandlist, setbrandlist] = useState([]);
    const [Nadata, setNadata] = useState([]);
    const [databc, setDatabc] = useState({
        brand: [],
        category: []
    })
    const [arrows, setArrows] = useState({});

// --------------------------------------------all states end-----------------------------------------------------------------

//---------------------------------------------- code functions for apply&filter start------------------------------------------------

    async function brandlisting() {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getBrandList`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
            })

            if (data.status === 200) {
                setbrandlist(data.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    // console.log(brandlist)

    const changeFunction = (brand_id) => {

        setDatabc((previousData) => {
            let newBrand;
            let z = previousData.brand.some(item => item == brand_id);
            if (!z) {
                newBrand = [...previousData.brand, brand_id];
            } else {
                newBrand = previousData.brand.filter((item) => item !== brand_id);
            }


            return {
                ...previousData,
                brand: newBrand
            }
        })
    }



    const [categoryChecked, setCategoryChecked] = useState({});

    const changeCFunction = (category_id) => {
        setCategoryChecked((prevChecked) => ({
            ...prevChecked,
            [category_id]: !prevChecked[category_id],
        }));

        setDatabc((previousData) => {
            let newCategory;
            let z = previousData.category.some(item => item == category_id);
            if (!z) {
                newCategory = [...previousData.category, category_id];
            } else {
                newCategory = previousData.category.filter((item) => item !== category_id);
            }
            return {
                ...previousData,
                category: newCategory
            }
        })
    }

    const MakeCategory = ({ cat_name, subcategory, id }) => {
        const subCategoryChecker = subcategory && subcategory.length >= 1;
        // const [checked, setChecked] = useState(false);


        return (
            <div>
                <ul style={{ marginLeft: "20px" }}>
                    <li key={id} >
                        <input
                            type="checkbox"
                            value={id}
                            name="sub-category"
                            onChange={() => { changeCFunction(id) }}
                            checked={categoryChecked[id] || false}

                        />{" "}
                        -{cat_name}{" "}
                    </li>

                    {subCategoryChecker &&
                        subcategory.map((item) => <MakeCategory {...item} />)}
                </ul>
            </div>
        );
    };


    const categoryListing = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getcategories`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
            });
            // console.log("category listing---->", data);
            if (data.status === 200) {
                // setCategoryList(data.data);
                if (data.status === 200) {
                    // Ndata = data.response;

                    const parsedData = data.response.map(
                        (item) => ({
                            id: item.id,
                            parent_id: item.parent_id,
                            cat_name: item.cat_name,
                            status: item.status,
                            subcategory: item.subcategory || [],
                        })
                    );
                    // console.log("parsed data-->", parsedData);
                    setNadata(parsedData);
                }
            }
        } catch (error) { }
    };

    async function submitFilteredData() {
        let apiEndpoint =  baseUrlDefiner();
        setArrows({})
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/${props.url}`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: databc

            })

            // console.log(data)
            if (data.status == 200) {
                props.updateProductList(data?.data);
                setopenfilterselection(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    function checkedChecker(id) {
        let z = databc.brand.some(item => item === id);
        if (z) {
            return true
        } else {
            return false
        }
    }

    function clearFilter() {
        setDatabc({
            brand: [],
            category: []
        })
        setopenfilterselection(false)
        setCategoryChecked({})
        setArrows({})
        props.getproductlist()
    }

    const handleClick = (id) => {
        setArrows((prevArrows) => {
          const newArrows = Object.fromEntries(
            Object.entries(prevArrows).map(([key, value]) => [key, false])
          );
          newArrows[id] = !prevArrows[id];
          return newArrows;
        });
      };

    //--------------------------------- code functions for apply&filter end------------------------------------------------
    return (
        <>
            <div className="" style={{ width: "60%" }} >
                <button
                    type="button"
                    className="btn btn-secondary btnvar position-relative w-100 m-0 d-flex justify-content-between align-items-center"
                    style={{ fontSize: "2.5vw" }}
                    onClick={() => { setopenfilterselection(!openfilterselection) }}

                >
                    <h5 className='m-0' style={{ fontSize: "14px", fontWeight: "500" }}>Apply Filter</h5>

                    <h6 className='m-0'>
                        <i className={openfilterselection ? `fas fa-angle-down ` : `fas fa-angle-right`}></i>
                    </h6>
                </button>
                {openfilterselection &&
                    <div className="filterselection" style={{ margintop: "10px" }}>
                        <section className='d-flex justify-content-between p-1 align-items-center'>
                            <Heading>Filters</Heading>
                            <div className='w-75 d-flex justify-content-end'>
                            <ApplyButton onClick={clearFilter} color='#858796' >Clear Filter</ApplyButton>
                            <ApplyButton onClick={submitFilteredData} color='#0094ff'>Apply</ApplyButton>
                            </div>
                        </section>

                        {/* //---------------------------------------------- brand HTML for apply&filter start------------------------------------------------ */}
                        <li className="nav-item" style={{ listStyle: "none" }}>

                            <div
                                className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                                href="replace"
                                data-toggle="collapse"
                                data-target="#mastercataloge"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                                id="master-catalog"
                                onClick={() => handleClick("Brand")}

                            >
                                <div >
                                    <span style={{ color: "black", fontSize: "14px", fontWeight: "500" }}>Brand</span>
                                </div>
                                <i className={arrows["Brand"] ? `fas fa-angle-down ` : `fas fa-angle-right `
                                    }
                                ></i>
                            </div>


                            <div id="mastercataloge" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className={` collapse-inner rounded`} style={{ overflow: "scroll", maxHeight: "265px", padding: "20px", background: "#dddfeb" }}>
                                    {
                                        brandlist?.map(item => {
                                            return <p href="replace" className={`collapse-item`} > <input type="checkbox" onChange={() => { changeFunction(item?.id) }}
                                                checked={checkedChecker(item.id)}

                                            />  {item?.brand_name} </p>
                                        })
                                    }



                                </div>
                            </div>
                        </li>


                        {/* //---------------------------------------------- brand HTML for apply&filter end------------------------------------------------ */}


                        {/* //---------------------------------------------- Category HTML for apply&filter start------------------------------------------------ */}
                        <li className="nav-item" style={{ listStyle: "none" }}>

                            <div
                                className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                                href="replace"
                                data-toggle="collapse"
                                data-target="#category"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                                id="master-catalog"
                                onClick={() => handleClick("category")}
                            >
                                <div >
                                    <span style={{ color: "black", fontSize: "14px", fontWeight: "500" }}>Category</span>
                                </div>
                                <i className={arrows["category"] ? `fas fa-angle-down ` : `fas fa-angle-right `}
                                ></i>
                            </div>


                            <div id="category" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                {/* <div className={` collapse-inner rounded`} style={{ overflow: "scroll", maxHeight: "265px", padding: "20px", background: "#dddfeb" }}>
                                    {
                                        brandlist?.map(item => {
                                            return <p href="replace" className={`collapse-item`} > <input type="checkbox" />  {item?.brand_name} </p>
                                        })
                                    }



                                </div> */}

                                <div className="multiselectdivision" style={{ overflow: "scroll", maxHeight: "265px", padding: "20px", background: "#dddfeb" }}>
                                    {Nadata.map((item, index) => {
                                        return (
                                            <>
                                                <MakeCategory
                                                    {...item}
                                                // onSubcategoryChange={handleSubcategoryChange}
                                                // key={id}
                                                />
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </li>

                        {/* //---------------------------------------------- Category HTML for apply&filter end------------------------------------------------ */}
                    </div>}
            </div>

        </>
    )
}
