import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { BasicTable } from "../../components/Table";
import { Paginated } from "../../components/Paginated";
import "../../components/table.css";
import Papa from 'papaparse';
import InputTemplate from "../../components/Search&Options/InputTemplate";
import ProductTemplate from "../../components/ProductViewTemplate/ProductTemplate";
import "./Master.css"
const { apiEndpoint } = config;
var $ = require("jquery");
var count = 1;
const ProductList = () => {

    const decoded = tokenDecode();
    // console.log("sdlsadsa", decoded)
    const [loading, setLoading] = useState(false);
    //code for importing the csv file
    const [file, setFile] = useState(null);
    const [importdata, setimportdata] = useState([]);
    const [maxprice, setmaxprice] = useState("")
    const handleImport = async () => {
        // console.log(file)
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: ({ data }) => {
                // console.log(data)
                let abc = data.filter(item => item.name !== null)
                let orders = abc.map(order => ({
                    name: order.name,
                    position: order.position,
                    product_type: order.product_type,
                    pf_brand_owner_fassai_license_no: order.pf_brand_owner_fassai_license_no,
                    pc_manufacturer_or_packer_name: order.pc_manufacturer_or_packer_name,
                    pc_common_of_generic_name_of_commodity: order.pc_common_of_generic_name_of_commodity,
                    pc_common_of_generic_name_of_commodity_in_pkg: order.pc_common_of_generic_name_of_commodity_in_pkg,
                    pc_month_year_of_manufacturer_packing_import: order.pc_month_year_of_manufacturer_packing_import,
                    pf_nutritional_info: order.pf_nutritional_info,
                    pf_additives_info: order.pf_additives_info,
                    pf_other_fassai_license_no: order.pf_other_fassai_license_no,
                    pf_importer_fassai_license_no: order.pf_importer_fassai_license_no,
                    mrp: order.mrp,
                    selling_price: order.selling_price,
                    tax_included_price: order.tax_included_price,
                    tax: order.tax,
                    hsn: order.hsn,
                    tags: order.tags,
                    short_description: order.short_description,
                    long_description: order.long_description,
                    sku: order.sku,
                    country_of_origin: order.country_of_origin,
                    customer_care_contact: order.customer_care_contact,
                    pc_manufacturer_or_packer_address: order.pc_manufacturer_or_packer_address,
                    time_to_ship: order.time_to_ship,
                    returnable: order.returnable,
                    canceleable: order.canceleable,
                    return_window: order.return_window,
                    seller_pickup_return: order.seller_pickup_return,
                    replaceable: order.replaceable,
                    cod: order.cod,
                    image: order.image,
                    status: order.status,
                    approve_status: order.approve_status,
                    retail_approve_status: order.retail_approve_status,
                    brand: order.brand,
                    upc: order.upc,
                    //extraas:-
                    seller_id: order.seller_id,
                    category: order.category,
                    ondc_product_id: order.ondc_product_id,

                }))
                let jsonData = {
                    orders: orders
                };
                console.log(JSON.stringify(jsonData))
                sendDataToDb(jsonData)
            },
        });
    };
    async function sendDataToDb(jsonData) {
        try {
            setLoading(true);
            // console.log(jsonData)
            let result = await axios({
                url: "https://shikahrapp.hulsecure.in/public/index.php/api/upload",
                method: 'POST',
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    // "Content-Type": "multipart/form-data"

                },
                data: jsonData,
            })
            console.log(result)
            setLoading(false);
            if (result.status === 200) {
                console.log("success")
                toast.success("Inventory updated successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                categoryListing()
            } else {
                console.log("this is from succes if")
                toast.error("403 error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } catch (error) {
            setLoading(false);
            console.log("this is from catch")
            toast.error("403 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }

    function maxPriceUpdateHandler() {
        console.log("maxPriceUpdateHandler working")
    }
    //code for importing the csv file

    const [productList, setProductList] = useState([]);
    const [sproductList, setSproductList] = useState([]);
    const [sellerList, setSellerList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [sellers, setSellers] = useState({
        seller: "",
    });
    const rawData = {
        seller_id: "",
    };
    const rawpData = {
        seller_id: "",
        min_price: "",
    };
    const rawsData = {
        seller_id: "",
        sku: "",
    };
    const [categories, setCategories] = useState();
    const [searchData, setSearchData] = useState(rawData);
    const [searchpData, setSearchpData] = useState(rawpData);
    const [searchsData, setSearchsData] = useState(rawsData);

    const [valuename, setValuename] = useState("");
    const [valueprice, setValueprice] = useState("");
    const [valuesku, setValuesku] = useState("");
    const [toggle, setToggle] = useState(false);
    const decode = tokenDecode();
    const { data } = decode;
    const {
        permission: { category, order, product, role, seller, tax, user, mastercatalog },
    } = data;
    const categoryListing = async () => {
        try {
            const { data } = await axios({
                url: "https://shikahrapp.hulsecure.in/public/index.php/api/showallproductmaster",
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    seller_id: decode?.data?.user?.seller_id,
                },
            });

            if (data.status === 200) {
                setProductList(data.response);
                console.log(data.response)
            }

        } catch (error) { }
    };
    const [openInput, setopenInput] = useState(null)
    function maxPriceHandler(id) {
        // console.log("clicked");
        setopenInput(openInput === id ? null : id);
        const maxPriceProduct = productList.filter(item => item.id == id);
        console.log(maxPriceProduct);
        let mp = maxPriceProduct[0].mrp
        // console.log(mp)
        setmaxprice(mp);
    }


    // const fetchSellerList = async () => {
    //     try {
    //         const { data } = await axios({
    //             url: `${apiEndpoint}/getsellerlist`,
    //             method: "GET",
    //             headers: {
    //                 "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
    //             },
    //         });
    //         if (data.status === 200) {
    //             setSellerList(data.data);
    //         }
    //     } catch (error) {
    //         console.log("error", error);
    //     }
    // };
    // const fetchCatgoryList = async () => {
    //     try {
    //         const { data } = await axios({
    //             url: `${apiEndpoint}/getcategorylist`,
    //             method: "GET",
    //             headers: {
    //                 "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
    //             },
    //         });
    //         if (data.status === 200) {
    //             setCategoryList(data.data);
    //         }
    //     } catch (error) {
    //         console.log("error", error);
    //     }
    // };
    const deleteProduct = async (id) => {

        try {
            const { data } = await axios({
                url: "https://shikahrapp.hulsecure.in/public/index.php/api/deleteproductcatalog",
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: { id },
            });
            if (data.status === 200) {
                setProductList(productList.filter((item) => item.id !== id));
                toast.success("Product deleted!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("403 error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("403 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
    const inputHandler = async (e) => {
        var optionvalue = e.target.value;
        setValuename("");
        setValueprice("");
        setValuesku("");
        if (optionvalue) {
            try {
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
                setToggle(true);
                /*if(e.target.name==="seller"){etSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                        setSellers({[e.target.name]: e.target.value})
                    }
                    if(e.target.name==="category"){
                        setCategories({[e.target.name]: e.target.value})
                    }
                    console.log(sellers);*/
                console.log(optionvalue);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterproductbyseller`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        seller_id: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                    setSproductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            setProductList(sproductList);
        }
    };
    const inputeHandler = async (e) => {
        const optionvalue = e.target.value;
        setValuename(optionvalue);
        if (optionvalue) {
            try {
                const { seller_id } = searchData;

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });

                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerproduct`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        seller_id: { searchData },
                        name: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputeeHandler = async (e) => {
        const optionvalue = e.target.value;
        setValuename(optionvalue);
        if (optionvalue) {
            try {
                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });

                const { data } = await axios({
                    url: `${apiEndpoint}/filterproductsellerlogin`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        name: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            categoryListing();
        }
    };
    const inputpHandler = async (e) => {
        const optionvalue = e.target.value;
        setValueprice(optionvalue);
        if (optionvalue) {
            try {
                const { seller_id, min_price } = searchpData;
                setToggle(true);

                setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                console.log("line 239" + rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerproduct`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        seller_id: { searchData },
                        min_price: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputppHandler = async (e) => {
        const optionvalue = e.target.value;
        setValueprice(optionvalue);
        if (optionvalue) {
            try {
                setToggle(true);

                setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                console.log("line 239" + rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterproductsellerlogin`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        min_price: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            categoryListing();
        }
    };
    const inputsHandler = async (e) => {
        const optionvalue = e.target.value;
        setValuesku(optionvalue);
        if (optionvalue) {
            try {
                const { seller_id, sku } = searchsData;
                console.log(searchsData);
                setToggle(true);

                setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
                console.log("line 195" + rawsData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerproduct`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        seller_id: { searchData },
                        sku: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputssHandler = async (e) => {
        const optionvalue = e.target.value;
        setValuesku(optionvalue);
        if (optionvalue) {
            try {
                console.log(searchsData);
                setToggle(true);

                setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
                console.log("line 195" + rawsData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterproductsellerlogin`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        sku: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            categoryListing();
        }
    };
    const inputHandlerforcategory = async (e) => {
        try {
            var optionvalue = e.target.value;
            const { data } = await axios({
                url: `${apiEndpoint}/filtercategory`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    category_id: optionvalue,
                },
            });
            console.log("product data", data.response);

            if (data.status === 200) {
                setProductList(data.response);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row, count) => {
                return <>{count + 1}</>;
            },
            disableFilters: true,
            sticky: "left",
        },
        {
            Header: "Image",
            Footer: "Image",
            accessor: (row) => {
                return (
                    <img
                        src={
                            row.image
                                ? row.image
                                : "http://cdn.onlinewebfonts.com/svg/img_546302.png"
                        }
                        alt="ok"
                        height={80}
                        width={80}
                    />
                );
            },
            sticky: "left",
        },
        {
            Header: "Name",
            Footer: "Name",
            accessor: "name",
            sticky: "left",
        },
        {
            Header: "Price",
            Footer: "Price",
            accessor: "price",
            sticky: "left",
        },
        {
            Header: "Sku",
            Footer: "Sku",
            accessor: "sku",
            sticky: "left",
        },
        {
            Header: "Status",
            Footer: "Status",
            accessor: (row) => {
                return <>{row.status ? "Active" : "Draft"}</>;
            },
            sticky: "left",
        },

        {
            Header: "Action",
            Footer: "Action",
            accessor: (row) => {
                return (
                    <>
                        {product.update ? (
                            <Link
                                to={`ondcsellerproductadd/${row.id}`}
                                className="btn-sm m-1 btn btn-success btn-circle"
                            >
                                <i className="fas fa-pen"></i>
                            </Link>
                        ) : null}
                        {product.delete ? (
                            <button
                                className="btn-sm m-1 btn btn-danger btn-circle"
                                onClick={() => deleteProduct(row.id)}
                            >
                                <i className="fas fa-trash"></i>
                            </button>
                        ) : null}


                        {/* code for quick update:- */}


                        <>
                            <button class="btn btn-secondary btn-circle" type="button"  >
                                <span onClick={(e) => {
                                    e.preventDefault()
                                    setopenInput(row.id)
                                    console.log("from spab")
                                    maxPriceHandler(row.id)
                                }}>  QU</span>
                            </button>
                            <>
                                {openInput === row.id && (<div style={{ position: "relative" }}>
                                    <div class="pop-up">
                                        <p>Max Price</p>
                                        <div class="pop-up-content">
                                            <div class="form-group">
                                                <input
                                                    type="text"
                                                    class="form-control form-control-sm w-25"
                                                    value={maxprice}
                                                    className="w-50"
                                                />
                                                <button style={{ marginLeft: "2px", backgroundColor: "blue", padding: "2px 5px", borderRadius: "3px", border: "none" }}> Update </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>)}
                            </>



                            {/* <button class="btn btn-secondary btn-circle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                <span onClick={(e) => {
                                    e.preventDefault()
                                    console.log("from spab")
                                    maxPriceHandler(row.id)
                                }}>  QU</span>
                            </button>

                            <div class="dropdown-menu m-1" aria-labelledby="dropdownMenuButton">
                                <form class="form-group d-flex align-items-center flex-column">
                                    <div class="form-group d-flex align-items-center flex-column">
                                        <label for="inputText">Update Price</label>
                                        <input type="text" class="form-control w-75" id="inputText" value={maxprice} />
                                    </div>
                                    <button type="submit" className="btn btn-primary ms-1" style={{ padding: "3px", fontSize: "13px" }} onClick={maxPriceUpdateHandler}>Update</button>
                                </form>
                            </div> */}

                        </>
                        {/* code for quick update:- */}

                    </>
                );
            },
            sticky: "left",
        },
    ];
    count++;
    const getondcproduct = async () => {
        let { data } = await axios({
            url: "https://shikahrapp.hulsecure.in/public/index.php/api/ondcsellerproduct",
            headers: {
                "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
            },
            method: "post",

        })
        if (data.status === 200) {
            setProductList(data.data);
            console.log(data.data)
        }
    }
    useEffect(() => {
        // categoryListing();

        getondcproduct();
        //fetchSellerList();
        //fetchCatgoryList();
    }, []);

    console.log(productList)

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0">
                    <div className="col-md-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary text-left">
                                    Upload File
                                </h6>
                                {/* {seller?.read ? (
                                    <>
                                        <b>Filter By Seller: </b>
                                        <select
                                            name="seller_id"
                                            id=""
                                            onChange={(e) => inputHandler(e)}
                                        >
                                            <option value="">Select Seller</option>
                                            {sellerList.map((item) => (
                                                <option value={item?.seller_id}>{item?.name}</option>
                                            ))}
                                        </select>
                                    </>
                                ) : (
                                    ""
                                )} */}

                                {/* code of import  */}
                                {/* <input type="file" id="inputFile" onChange={(e) => {
                                    console.log("change working");
                                    setFile(e.target.files[0]);
                                }} style={{ marginTop: "30px" }} />
                                <button className="btn btn-primary " onClick={handleImport} > Submit </button> */}
                                {/* code of import  end*/}

                                {/*<b>Filter By Category: </b><select name="category" id="" onChange={(e:any)=>inputHandlerforcategory(e)} defaultValue="">
                                <option value="">Select Category</option>
                                {
                                    categoryList.map((item:any)=>(
                                        <option value={item?.id}>{item?.category_name}</option>
                                    ))
                                }
                                       
                            </select>*/}
                                <div className="search mt-1">
                                    <div className="row">
                                        <div className="inputs_desktop">
                                            <input
                                                type="search"
                                                name="name"
                                                placeholder="Search by name"
                                                className="mr-2"
                                                onChange={(e) => {
                                                    seller?.read ? inputeHandler(e) : inputeeHandler(e);
                                                }}
                                                value={valuename ? valuename : ""}
                                            />
                                            <input
                                                type="search"
                                                name="min_price"
                                                placeholder="Search by price"
                                                className="mr-2"
                                                onChange={(e) => {
                                                    seller?.read ? inputpHandler(e) : inputppHandler(e);
                                                }}
                                                value={valueprice ? valueprice : ""}
                                            />
                                            <input
                                                type="search"
                                                name="sku"
                                                placeholder="Search by sku"
                                                className="mr-2"
                                                onChange={(e) => {
                                                    seller?.read ? inputsHandler(e) : inputssHandler(e);
                                                }}
                                                value={valuesku ? valuesku : ""}
                                            />
                                        </div>
                                        <div className="inputs">
                                            <InputTemplate placeholder="Search By Name" />
                                            <InputTemplate placeholder="Search By Price" />
                                            <InputTemplate placeholder="Search By Sku" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive">
                                    {/*} <table className="table table-bordered" id="dataTable" width="100%" >
                                        <thead>
                                            <tr>
                                                <th>Sn</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Sku</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                productList.map((item: any, index: any) => (
                                                    <tr key={item?.id}>
                                                        <td> {index + 1} </td>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <img src={item?.image ? item?.image : "http://cdn.onlinewebfonts.com/svg/img_546302.png"} alt="ok" height={80} width={80} />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td> {item?.name} </td>
                                                        <td> {item?.min_price} </td>
                                                        <td> {item?.sku}</td>
                                                        <td> {item?.status ? "Active" : "Draft"}</td>

                                                        <td>
                                                            <div className="row">
                                                                {
                                                                    product?.update ? (
                                                                        <div className="col-md-4">
                                                                            <Link to={`/product/${item?.id}`} className="btn btn-success btn-circle">
                                                                                <i className="fas fa-eye"></i>
                                                                            </Link>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                {
                                                                    product?.delete ? (
                                                                        <div className="col-md-4">
                                                                            <button className="btn btn-danger btn-circle"
                                                                                onClick={() => deleteProduct(item?.id)}
                                                                            >
                                                                                <i className="fas fa-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                    ) : null
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>*/}
                                    {/* <Paginated data={productList} columns={COLUMNS} /> */}
                                    <div className="productListing_desktop">
                                        <Paginated data={productList} columns={COLUMNS} />
                                    </div>
                                    <div className="productListing_mobile">
                                        <ProductTemplate data={productList} deleteProduct={deleteProduct} handle="ondcsellerproductadd"></ProductTemplate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    );
};

export default ProductList;
