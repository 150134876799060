import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import ThemeMaker from "../../components/thememaker";
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import { config } from "../../utils/config";
import btnstyle from "../../components/Buttons/Buttons.module.css"
import styles from "../transaction/transaction.module.css"
import Categorylist from "../../components/CategoryTemplate/Categorylist";
const { apiEndpoint } = config
var $ = require('jquery');

const Category = () => {
    const decoded: any = tokenDecode();
    const { data } = decoded;
    const { permission: { category } } = data;
    const [listing, setListing] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [listingLoading, setListingLoading] = useState(false);
    const [taxData, setTaxData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tax, setTax] = useState({
        tax: '',
        status: true,
        id: ''
    });
    const taxD = {
        tax: false
    }
    const [taxErrorData, setTaxErrorData] = useState(taxD);
    const errorHandler = (e: any) => {
        if (e.target.value) {
            setTaxErrorData({ ...taxErrorData, [e.target.name]: false });
        } else {
            setTaxErrorData({ ...taxErrorData, [e.target.name]: true });
        }
    }
    const createTaxHandler = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            setLoading(true);
            console.log("TAX", tax);
            if (tax?.tax) {
                const { data } = await axios({
                    url: `${apiEndpoint}/createcategory`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                      },
                    data: {
                        "category_name": tax.tax,
                        "status": tax.status
                    }
                });
                console.log("data---", data);
                if (data.status === 200) {
                    toast.success("New Category created !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const taxListHanler = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            setListing(true);
            setListingLoading(true);
            const { data } = await axios({
                url: `${apiEndpoint}/getcategorylist`,
                method: "GET",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                  },
            });
            console.log("tax listing", data);
            setListingLoading(false);
            if (data.status === 200) {
                setTaxData(data.data);
            }

        } catch (error) {
            setListingLoading(false);


        }
    }
    const setCrete = () => {
        setListing(false);
        setIsUpdate(false);
        setTax({
            tax: '',
            status: true,
            id: ''
        })
    }
    const editRedirect = async (id: any) => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getcategory`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                  },
                data: {
                    "id": id
                }
            });
            console.log("editRedirect data", data);
            if (data.status === 200) {
                setTax({
                    tax: data?.data?.category_name,
                    status: data?.data?.status,
                    id: data?.data?.id
                })
            }
        } catch (error) {

        }
        setIsUpdate(true);
        setListing(false);

    }

    const updateTax = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            if (tax?.tax) {
                setLoading(true);
                console.log("tax", tax);
                const { data } = await axios({
                    url: `${apiEndpoint}/updatecategory`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                      },
                    data: {
                        "category_name": tax.tax,
                        "status": tax.status,
                        id: tax.id
                    }
                });
                console.log("data----", data);
                if (data.status === 200) {
                    toast.success("Category Updated !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const deleteTax = async (id: any) => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            setLoading(true);
            console.log("category", tax);
            const { data } = await axios({
                url: `${apiEndpoint}/deletecategory`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                  },
                data: { id }
            });
            console.log("data----", data);
            if (data.status === 200) {
                setTaxData(taxData.filter((item: any) => item.id !== id));
                toast.success("Category Deleted !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("403 Error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    useEffect(() => {

    }, [])
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 m-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary d-flex justify-content-center" style={{ gap: "10px" }}>
                                    <button style={{ fontSize: "14px" }}
                                        onClick={setCrete}
                                        className={btnstyle.button}
                                    >
                                        Create new category
                                    </button>
                                    <button
                                        onClick={taxListHanler} style={{ fontSize: "15px" }}
                                        className={btnstyle.button}
                                    >
                                        Category list
                                    </button>
                                </h6>
                            </div>
                            {
                                listing ? (
                                 <>  <div className={`card-body ${styles.transactionListing_desktop}`} >
                                        <div className="table-responsive">
                                            <table className="table table-bordered" id="dataTable" width="100%" >
                                                <thead>
                                                    <tr>
                                                        <th>Sn</th>
                                                        <th>Category name</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        taxData.map((item: any, index: any) => (
                                                            <>
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.category_name}</td>
                                                                    <td>
                                                                        {
                                                                            item?.status ?
                                                                                <span className="badge badge-success">Active</span> :
                                                                                <span className="badge badge-danger">Deactive</span>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <div className="row">
                                                                            {
                                                                                category?.update ? (
                                                                                    <div className="col-md-4">
                                                                                        <button className="btn btn-success btn-circle"
                                                                                            onClick={() => editRedirect(item.id)}
                                                                                        >
                                                                                            <i className="fas fa-eye"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                category?.delete ? (
                                                                                    <div className="col-md-4">
                                                                                        <button className="btn btn-danger btn-circle"
                                                                                            onClick={() => deleteTax(item.id)}
                                                                                        >
                                                                                            <i className="fas fa-trash"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                ) : null
                                                                            }

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={`${styles.transactionListing_mobile}`} style={{ padding: "10px" }}>  {
                                            taxData.map((item, index) => {
                                                return <Categorylist item={item} index={index} editRedirect={editRedirect} deleteTax={deleteTax}/>
                                            })
                                        }</div>
                                    </> 
                                ) : (
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Category name</label>
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        taxErrorData?.tax ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="tax" placeholder="Category"
                                                        value={tax.tax}
                                                        onChange={(e) => setTax({ ...tax, [e.target.name]: e.target.value })}
                                                        onBlur={(e) => errorHandler(e)}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Status</label> 
                                                    <input type="checkbox" checked={tax.status} name="status" placeholder="Name"
                                                        value=""
                                                        onChange={(e) => setTax({ ...tax, [e.target.name]: !tax.status })}
                                                        style={{marginLeft: "10px"}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                {
                                                    !isUpdate && !listing ? (
                                                        <button type="submit" className="btn btn-primary"
                                                            onClick={createTaxHandler}
                                                        >
                                                            Save
                                                        </button>
                                                    ) : (
                                                        <button type="submit" className="btn btn-success"
                                                            onClick={updateTax}
                                                        >
                                                            Update
                                                        </button>
                                                    )
                                                }

                                            </div>
                                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default Category;