/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */

import "./switch.css";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import moment from "moment";
import { useEffect, useState } from "react";
import style from "../../components/Search&Options/Search&Options.module.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import ThemeMaker from "../../components/thememaker";
import { Paginated } from "../../components/Paginated";
import * as XLSX from 'xlsx';
import Cookies from 'js-cookie';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


const Sstyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: "10px", bgcolor: 'background.paper', border: '1px solid #000', boxShadow: 24, p: 2,
};


const customerOrder = () => {
  const decoded: any = tokenDecode();
  const [orderList, setOrderList] = useState([]);
  const [quote, setQuote]: any = useState([]);
  const [allorders, setallorders] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const { apiEndpoint } = config;
  const [toggle, setToggle] = useState(false);
  const rawData = {
    provider_id: "",
  };
  const [_orderDetail, _setorderDetail]: any = useState({
    billing_address: "",
    contact_number: "",
    created_at: "",
    email: "",
    id: "",
    name: "",
    order_id: "",
    order_state: "",
    provider_id: "",
    shipping_address: "",
    status: "",
    total_price: 0,
  });

  const [state, setState] = useState<State>({
    sopen: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, sopen } = state;
  const [updateOrder_, setUpdateOrder_]: any = useState({
    order_id: "",
    tracking_url: "",
    order_state: ""
  })
  const [searchData, setSearchData]: any = useState(rawData);
  const [sorderList, setSorderList] = useState([]);
  const [valuename, setValuename] = useState("");
  const [valueid, setValueid] = useState("");
  const [valuecontact, setValuecontact] = useState("");
  const [valuestatus, setValuestatus] = useState("");
  const [valuedate, setValuedate] = useState("");
  const [SelectionParam, setSelectionParam]: any = useState(false);
  const [selectedBox, setselectedBox]: any = useState([]);

  const [bulkdata, setbuldata] = useState({
    order_status: "",
    fulfillment_state: "",
    order_id: [...selectedBox]
  })
  const { data } = decoded;
  const {
    permission: { seller },
  } = data;

  const fetchSellerList = async () => {
    let apiEndpoint =  baseUrlDefiner();
    setIsLoading(true);
    let parentId = decoded?.data?.user?.parent_id;
    let SellerId = decoded?.data?.user?.seller_id;
    let payload;
    if (parentId > 1) {
      payload = { seller_id: parentId }

    } else {
      payload = { seller_id: SellerId }

    }
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerorderlistnew`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: payload,
      });
      setOrderList(data.data);
      if (data.status === 200) {
        setOrderList(data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const updateOrder = async (order_id: any) => {
    try {

      const { data } = await axios({
        url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: updateOrder_
      });
      // console.log(data, "datadata");

      if (data.message === "flag1") {
        toast.success("Order updated !");
        fetchSellerList();
      } else {
        toast.error("Something went wrong !");
      }

    } catch (error) {
      console.log("update error", error);
      toast.error("404 error !");
    }
  }

  const inputHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    setValuename("");
    setValueid("");
    setValuecontact("");
    setValuestatus("");
    setValuedate("");
    var optionvalue = e.target.value;
    if (optionvalue) {
      try {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbyseller`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data);
          setOrderList(data.data);
          setSorderList(data.data);
          setToggle(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setOrderList(sorderList);
    }
  };
  const inputeHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        const { provider_id } = searchData;
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            name: optionvalue,
            order_status: "open-order"
          },
        });

        // console.log({
        //   data: {
        //     provider_id: { searchData },
        //     name: optionvalue,
        //   },
        // });

        if (data.status === 200) {
          // console.log(data);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeeHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            name: optionvalue,
            order_status: "open-order"
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputoHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            order_id: optionvalue,
            order_status: "Accepted, Created, In-progress"
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log("line 332", data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputooHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            order_id: optionvalue,
            order_status: "open-order"
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputcHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            contact_number: optionvalue,
            order_status: "open-order"
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputccHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            contact_number: optionvalue,
            order_status: "open-order"
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const inputdHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            created_at: optionvalue,
            order_status: "open-order"
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputddHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            created_at: optionvalue,
            order_status: "open-order"
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const inputpHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    const optionvalue = e.target.value;
    setValuestatus(optionvalue);
    if (optionvalue) {
      try {

        setToggle(true);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
          },
          data:
          {
            provider_id: { searchData },
            status: optionvalue,
            order_status: "open-order"
          }

        });

        if (data.status === 200) {
          setOrderList(data.response);
        }


      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }


  };

  const inputppHandler = async (e: any) => {
    let apiEndpoint =  baseUrlDefiner();
    const optionvalue = e.target.value;
    setValuestatus(optionvalue);
    if (optionvalue) {
      try {

        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
          },
          data: {
            status: optionvalue,
            order_status: "open-order"
          }
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);

        }

      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  function checkHandler(row: any) {
    let hasId = selectedBox.some((item: any) => item == row.order_id)
    if (!hasId) {
      setselectedBox((prev: any) => {
        return [...prev, row.order_id]
      })
    } else {
      setselectedBox((prev: any) => {
        return prev.filter((item: any) => item !== row.order_id)
      })
    }

  }


  const handleClick = (newState: SnackbarOrigin) => {

    setState({ ...newState, sopen: true });
  };

  const [orderDetails, setOrderDetails] = useState(null);
  const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
  const [orderState, setOrderState] = useState({
    order_id: "",
    order_state: "",
    fulfillment_state: "",
    tracking_url: ""
  })
  const [orderStatus, setorderStatus] = useState([
    "Created",
    "Accepted",
    "In-progress",
    "Completed",
  ]);

  async function getorderDetailsByOrderID(order_id: string) {
    let apiEndpoint =  baseUrlDefiner();
    setOrderState((prev) => {
      return { ...prev, order_id: order_id }
    })
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerorder`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          order_id,
        },
      });

      console.log("setOrderDetails--------->", data?.data[0]?.order_state);

      if (data.status == 200) {
        setOrderDetails(data);
        setOrderState((prev) => {
          return { ...prev, order_state: data?.data[0]?.order_state, fulfillment_state: data?.products[0]?.fulfillment_state, tracking_url: data?.products[0]?.tracking_url }
        })
        if (data.data[0].order_state !== "") {

          const index = orderStatus.findIndex((item) => item === data.data[0].order_state);

          if (index !== -1) {
            let slicedStatus = orderStatus.slice(index);

            setorderStatus([...slicedStatus]);
          }
        }
      } else {
        toast.error("Error getting order details!")
      }
      setshipmentModalOpen(true)
    } catch (err) {
      toast.error("Something Went Wrong!!")
    }

  }
  const COLUMNS = [
    !SelectionParam ? {
      Header: "Serial No.",
      Footer: "Serial No.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    } : {
      Header: "Select",
      Footer: "Select",
      accessor: (row: any) => {
        return <input type="checkbox" onChange={() => checkHandler(row)} checked={selectedBox.includes(row.order_id)} />

      },
      disableFilters: true,
      sticky: "left",

    },
    {
      Header: "Order ID",
      accessor: (a: any) => (a?.order_id ? a?.order_id : null),
      sticky: "left"
    },
    {
      Header: "Order Status",
      accessor: (a: any) => (a?.order_state ? a?.order_state : null),
      sticky: "left",
    },
    {
      Header: "Fulfillment Status",
      Footer: "Fulfillment Status",
      accessor: "fulfillment_state",
      sticky: "left"
    },
    {
      Header: "Item",
      accessor: (a: any) => (a?.item_count ? a?.item_count : null),
      sticky: "left",
    },
    {
      Header: "Customer Name",
      accessor: (a: any) => (a?.name ? a?.name : null),
      sticky: "left"
    },
    {
      Header: "Total (In Rs)",
      accessor: "order_total",
      sticky: "left"
    },
    {
      Header: "Payment Status",
      accessor: (row: any) => {
        return <>{row.status === "NOT-PAID" ? "COD" : "PAID"}</>
      },
      sticky: "left"
    },
    {
      Header: "Created Date",
      accessor: (row: any) => {
        if (row?.created_at) {
          return moment.utc(row?.created_at).format("DD-MM-YYYY  HH:mm:ss")
        } else {
          return ""
        }
      },
      sticky: "left"
    },

    {
      Header: "Action",
      accessor: (item: any) => {
        return <div className="d-flex"><Link className="text-dark" to={`./orderCompleteDetail/${item.order_id}`}> <span className="badge badge-primary" style={{ fontSize: "15px", fontWeight: "400" }}>Get Order Details</span></Link>
          <button className="btn btn-success btn-circle" onClick={() => getorderDetailsByOrderID(item.order_id)}><i className="fas fa-pen"></i></button> </div>
      },
      sticky: "left"
    },

  ];

  function handleShipementModalClose() {
    setOrderState({
      order_id: "", order_state: "", fulfillment_state: "", tracking_url: ""
    });
    setorderStatus(["Created", "Accepted", "In-progress", "Completed"])
    setshipmentModalOpen(false);
    setOrderDetails(null)
  }

  const exportorders = async () => {
    let apiEndpoint =  baseUrlDefiner();
    let payload;
    let sellerId = decoded?.data?.user?.seller_id;
    if (sellerId == 1 || sellerId > 1) {
      payload = sellerId
    }

    if (sellerId == 0) {
      payload = decoded?.data?.user?.parent_id;
    }

    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getallstatusorder`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          seller_id: payload,
        }
      });
      if (data.status === 200) {

        setallorders(data.data)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const exportToExcel = (orders: any) => {
    const headers = [
      'S.No', "Order Id", "Created At", "Seller Name", "Order State", " Customer Name", " Customer Email", " Customer Contact", "Status", "Item Count", "Order Total"

    ];

    // // Prepare data with just the values to be displayed in the columns
    const data = orders.map((order: any, index: any) => {

      let orderCreatedDate = moment(order?.created_at).format("DD-MM-YYYY hh:mm:ss")
      let sellerName = order?.address?.start?.location?.descriptor?.name
      let sellerEmail = order?.address?.end?.contact?.email
      let sellerContact = order?.address?.end?.contact?.phone
      return {
        'S.No': index + 1,
        "Order Id": order.order_id,
        "Created At": orderCreatedDate,
        "Seller Name": sellerName,
        "Order State": order?.order_state,
        " Customer Name": order.name,
        " Customer Email": sellerEmail,
        " Customer Contact": sellerContact,
        "Status": order?.status,
        "Item Count": order?.item_count,
        "Order Total": order?.order_total

      }
    });

    // // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });

    // // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

    // // Generate a unique name for the Excel file (you can customize this as needed)
    const fileName = `allorders_${new Date().getTime()}.xlsx`;

    // // Export the workbook as an Excel file
    XLSX.writeFile(wb, fileName);
  };

  interface State extends SnackbarOrigin {
    sopen: boolean;
  }



  const handlesClose = () => {
    setState({ ...state, sopen: false });
  };

  const updateThisOrder = async (e: any) => {
    console.log(orderState)
    let apiEndpoint =  baseUrlDefiner();
    try {

      const { data } = await axios({
        url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: orderState,
      });


      if (data.status === 200) {

        if (orderState.order_state === "Created") {
          toast.success("Order Created successfully!");
        } else if (orderState.order_state === "Accepted") {
          toast.success("Order Accepted successfully!");
        } else if (orderState.order_state === "In-progress") {
          toast.success("Order In-progress!");
        } else if (orderState.order_state === "Completed") {
          toast.success("Order Completed successfully!");
        } else if (orderState.order_state === "Cancelled") {
          toast.success("Order Cancelled successfully!");
        }
        fetchSellerList()
      } else {
        toast.error("Something went wrong !");
      }
    } catch (error) {
      toast.error("Something went wrong 500 !")
    }
  };


  async function clearSelectionHandler() {
    setSelectionParam(false)
    setselectedBox([])
    handlesClose()

  }

console.log("orderList",orderList)

  const action = (
    <div>
      <div className="d-flex justify-content-between align-items-center" style={{ gap: "10px", padding: "20px", position: "relative" }} >
        <div className="col-md-6">
          <label htmlFor=""> Order status </label>
          <select value={bulkdata?.order_status} className="form-control" name="order_status"
            onChange={(e) => setbuldata({ ...bulkdata, [e.target.name]: e.target.value })}
          >
            <option value="" disabled selected>Select Order Status</option>
            <option value="Created">Created</option>
            <option value="Accepted">Accepted</option>
            <option value="In-progress">In-progress</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Returned">Returned</option>
          </select>
        </div>

        <div className="col-md-6 mt-2">
          <label htmlFor=""> Fulfillment status </label>
          <select value={bulkdata?.fulfillment_state} className="form-control" name="fulfillment_state"
            onChange={(e) => setbuldata({ ...bulkdata, [e.target.name]: e.target.value })}
          >
            <option value="" disabled selected>Select Fulfillment Status</option>
            <option value="Pending">Pending</option>
            <option value="Packed">Packed</option>
            <option value="Order-picked-up">Order-picked-up</option>
            <option value="Out-for-delivery">Out-for-delivery</option>
            <option value="Order-delivered">Order-delivered</option>
          </select>
        </div>

        <IconButton size="small" aria-label="close" color="inherit" onClick={clearSelectionHandler} style={{ marginLeft: "20px", position: "absolute", top: "10px", right: "10px" }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>


      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-primary" onClick={bulkOrderUpdateHandler}>Submit</button>
      </div>
    </div>
  );

  async function bulkOrderUpdateHandler() {
    let apiEndpoint =  baseUrlDefiner();
    const { data } = await axios({
      url: `${apiEndpoint}/bulkorderupdate`,
      method: "POST",
      headers: {
        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
      },
      data: {
        ...bulkdata
      }
    })

    if (data?.status == 200) {
      toast.success("Orders Updated Successfully!");
      fetchSellerList();
      handlesClose()

    } else {
      toast.error("Something went wrong!");
    }
  }

  useEffect(() => {
    fetchSellerList();
    exportorders();
  }, []);

  useEffect(() => {
    if (selectedBox.length > 0) {
      handleClick({ vertical: 'top', horizontal: 'center' });
      setbuldata({ ...bulkdata, order_id: [...selectedBox] })
    }
  }, [selectedBox])
  const fullUrl = window.location.host

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-2">
          <div className="col-md-12 ">
            <div className="card shadow mb-4 mt-1">
              <div className="card-header py-3">
                <div className="row d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Customer Order List</h6>

                  <div className={`search mt-1`}>
                    <div className={`row d-flex justify-content-end mb-1`}  >
                      <div className="col-12 col-sm-12 col-md-3 mb-1">
                        <input
                          className="form-control"
                          type="search"
                          name="order_id"
                          placeholder="Enter Order ID"
                          onChange={(e) => {
                            seller?.read ? inputoHandler(e) : inputooHandler(e);
                          }}
                          value={valueid ? valueid : ""}
                        />
                      </div>

                      <div className="col-12 col-sm-12 col-md-3 mb-1 d-none">
                        <input
                          className="form-control"
                          type="search"
                          name="name"
                          placeholder="Search by name"
                          onChange={(e) => {
                            seller?.read ? inputeHandler(e) : inputeeHandler(e);
                          }}
                          value={valuename ? valuename : ""}
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 mb-1 d-none">
                        <input type="search"
                          className="form-control"
                          name="contact_number"
                          placeholder="Search by Contact"
                          onChange={(e) => {
                            seller?.read ? inputcHandler(e) : inputccHandler(e);
                          }}
                          value={valuecontact ? valuecontact : ""}
                        />
                      </div>
                      {/* </div> */}
                      {/* <input type="search" name="payment_status" placeholder="Search by Payment Status" className="mr-2" onChange={(e) => { seller?.read ? inputpHandler(e) : inputppHandler(e) }} value={valuestatus ? valuestatus : ""} /> */}
                      <div className="col-12 col-sm-12 col-md-4 mb-1">
                        <select name="payment_status" onChange={(e) => { seller?.read ? inputpHandler(e) : inputppHandler(e) }} value={valuestatus ? valuestatus : ""} className={`form-control`} >
                          <option value="" disabled selected >Payment Status</option>
                          <option value="NOT-PAID">Cod</option>
                          <option value="pa">Paid</option>
                        </select>
                      </div>
                      {/* <div className="input-group mb-3" style={{ width: "30%" }}> */}
                      {/* <span className="input-group-text">Filter By Date</span> */}
                      <div className="col-12 col-sm-12 col-md-3 mb-1">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Search by date"
                          name="date"
                          onChange={(e) => {
                            seller?.read ? inputdHandler(e) : inputddHandler(e);
                          }}
                          value={valuedate ? valuedate : ""}
                        />
                      </div>

                      <div className="col-12 col-sm-12 col-md-2 mb-1">
                        <button className="btn btn-primary" onClick={() => setTimeout(() => {
                          exportToExcel(allorders)
                        }, 2000)} >Export </button>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
              {!isLoading ? <div className="card-body">

               {(fullUrl === "preprodadmin.xpressbaazaar.com")&& <>{!SelectionParam ? <span onClick={() => setSelectionParam(true)} className="text-center d-block text-dark rounded my-2 mx-2 " style={{ background: "lightgrey", fontSize: "14px", width: "80px", cursor: "pointer", paddingTop: "3px", fontWeight: "600", }}>Edit</span> :
                  <span onClick={clearSelectionHandler} className="text-center d-block text-dark rounded my-2 mx-2 " style={{ background: "lightgrey", fontSize: "14px", width: "80px", cursor: "pointer", paddingTop: "3px", fontWeight: "600", }}>Cancel</span>} </>}

                {orderList &&
                  <>
                    <div className="showdiv table-responsive table-bordered">

                      <Paginated data={orderList} columns={COLUMNS} />
                    </div>
                    <div className="hidediv">
                      {orderList.map((item: any) => {
                        let sellerName = item?.address?.start?.location?.descriptor?.name
                        if (sellerName) {
                          sellerName = sellerName
                        } else {
                          console.log(item)
                          const parsedData = item?.fulfillments ? JSON.parse(item.fulfillments) : '';
                          const selletName = parsedData[0]?.start?.location?.descriptor?.name
                          sellerName = selletName
                        }

                        return <div className="border p-1">
                          <div className="d-flex  mt-1 align-items-center" style={{ gap: "10px" }}>
                            <span className="genericlabel" style={{ width: "28%" }} >Order ID</span>
                            <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.order_id}</span>
                          </div>
                          <div className="d-flex  mt-1" style={{ gap: "10px" }}>
                            <span className="genericlabel " style={{ width: "28%" }} >Order Status</span>
                            <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.order_state}</span>
                          </div>
                          <div className="d-flex mt-1" style={{ gap: "10px" }}>
                            <span className="genericlabel " style={{ width: "28%" }} > Seller Name</span>
                            <span className="" style={{ fontSize: "14px", width: '70%' }}>{sellerName}</span>
                          </div>
                          <div className="d-flex mt-1" style={{ gap: "10px" }}>
                            <span className="genericlabel " style={{ width: "31%" }}>Payment Status</span>
                            <span className="" style={{ fontSize: "14px", width: '69%' }}>{item?.status}</span>
                          </div>
                          <div className="mt-2">
                            <Link to={`../../orderCompleteDetail/${item.order_id}`}> <p style={{ width: "120px", fontWeight: 600, borderRadius: "17px", color: "white", background: "blue", padding: "6px", textAlign: "center" }} >Check Order</p>
                            </Link>
                          </div>
                        </div>
                      })}
                    </div>
                  </>

                }
              </div> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}

            </div>
          </div>
        </div>
      </ThemeMaker>


      <div style={{ width: "25%" }}>
        <Snackbar anchorOrigin={{ vertical, horizontal }}
          open={sopen} sx={{ width: "100%" }}          // onClose={handleClose}          key={vertical + horizontal}
          action={action} />
      </div>

      <Modal
        open={shipmentModalOpen}
        onClose={handleShipementModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Sstyle} className="col-md-4 ">

          <div className="modal-content " >
            <div className="modal-header p-2 d-flex align-items-center justify-content-between">
              <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Order ID : {orderState.order_id} </p>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
              </button>
            </div>
            <div className="modal-body">

              <div>
                <label htmlFor="">Order Status</label> <br />
                <select name="" id="" value={orderState?.order_state} onChange={(e) => {
                  setOrderState((prev) => {
                    return { ...prev, order_state: e.target.value }
                  })
                }} >
                  <option value="" disabled selected>Select Order Status </option>
                  {orderStatus.map((item) => {
                    return <option value={item} key={item}>{item}</option>
                  })
                  }
                </select>
              </div>

              <div className="mt-2">
                <label htmlFor="">Fulfillment Status</label> <br />
                <select name="" id="" value={orderState?.fulfillment_state} onChange={(e) => {
                  setOrderState((prev) => {
                    return { ...prev, fulfillment_state: e.target.value }
                  })
                }}>
                  <option value="">Select Fulfillment Status </option>
                  {(orderState?.order_state === "Created" || orderState?.order_state === "Accepted") && <option value="Pending">Pending </option>}

                  {orderState?.order_state === "In-progress" &&
                    <> <option value="Packed">Packed</option>
                      <option value="Order-picked-up">Order-picked-up</option>
                      <option value="Out-for-delivery">Out-for-delivery</option>
                    </>}
                  {orderState?.order_state == "Completed" && <option value="Order-delivered">Delivered</option>}
                </select>
              </div>


            </div>
            <div className="modal-footer d-flex justify-content-between">
              <input type="text" className="form-control col-md-8" placeholder="Tracking Url" value={orderState?.tracking_url}
                onChange={(e) => {
                  setOrderState((prev: any) => {
                    return { ...prev, tracking_url: e.target.value }
                  })
                }}
              />

              <button className="btn btn-success" onClick={updateThisOrder} > Update </button>

            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default customerOrder;