import React, { useEffect, useState } from "react";
import ThemeMaker from "../../components/thememaker";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { tokenDecode } from "../../utils/helper";
import axios from "axios"
import aws from 'aws-sdk';
import { config } from "../../utils/config";
import { useParams } from "react-router-dom";
import btnstyle from "../../components/Buttons/Buttons.module.css"
import styled from "styled-components"
import { show, hide } from "react-functional-modal"
import "./Master.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Variants from "../../components/Variants/Variants";
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import ImageAdd from "../../components/ImageAdd/ImageAdd";
import { removeExifData } from "./helper";
import { baseUrlDefiner } from "../../utils/config";
const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;

const ImageContainer = styled.div
  `
 border: 1px solid black;
    border-radius: 27px;
  width: 83%;
  background: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

    
    img{
      width: 100%!important;
    height: 80%;
    object-fit: contain;
  }

  button{
    position: absolute;
    top: 4px;
    right: 11px;
    background: none;
    outline: none;
    border: none;
  }
    `

const smstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  minHeight: 400,
  maxHeight: 700,
  overflow: "scroll",
  color: "black"
};


function MasterProductAdd() {
  const decode = tokenDecode();
  let current_sellerid = decode.data.user.seller_id

  const [pageNamem, setpageName] = useState(true);
  const [uploading, setUpLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [readonly, setreadonly] = useState(false)
  const [brandlist, setbrandlist] = useState([])
  const returnWindowLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const timeToShipLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

  //newly added state after change:-
  const [Nadata, setNadata] = useState([]);
  const [subcattoggle, setSubcattoggle] = useState(false);
  // let Ndata = arr;
  let rawSubCatData = {
    parent_id: "",
    name: "",
    status: true,
    id: ""
  }
  const [Subcategorydata, setSubcategorydata] = useState(rawSubCatData);

  const handleSubcategoryChange = (id) => {
    setSubcategorydata({ ...Subcategorydata, parent_id: id });
  };
  const [mopen, setmopen] = React.useState(false);
  const [iopen, setiopen] = React.useState(false);
  const handleOpen = () => setmopen(true);
  const handleImageOpen = () => setiopen(true);
  const handleClose = () => setmopen(false);
  const handleimageClose = () => setiopen(false);


  useEffect(() => {
    taxListHanler()
    categoryListing()
    brandlisting()
    if (id) {
      setpageName(false);
      getProduct();
      if (current_sellerid > 1) {
        setreadonly(true)
      }
      // setProductData(JSON.parse(user))
    } else {
      setpageName(true);
    }
  }, [])

  const getProduct = async () => {
    let apiEndpoint =  baseUrlDefiner();
    // console.log("comming to getproduct function")
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getmas`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: { product_id: id },
      });
      console.log("getProduct >---------> ", data);
      if (data.status === 200) {
        console.log(data.data)
        setProductData(data.data);
        const parsedData = data.response.map(item => ({
          id: item.id,
          parent_id: item.parent_id,
          cat_name: item.cat_name,
          status: item.status,
          subcategory: item.subcategory || []
        }));
        console.log("parsed data-->", parsedData)
        setNadata(parsedData);
      }
    } catch (error) { }

  };

  async function brandlisting() {
    let apiEndpoint =  baseUrlDefiner();
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getBrandList`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
      })

      if (data.status === 200) {
        setbrandlist(data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  let params = useParams();
  const { id } = params;

  // console.log(rawData.seller_id)
  const rawData = {
    seller_id: decode?.data?.user?.seller_id,
    name: "",
    net_qty: "",
    pc_manufacturer_or_packer_name: "",
    quantity: "",
    short_description: "",
    description: "",
    categories_id: Subcategorydata.parent_id,
    mrp: 0,
    selling_price: 0,
    tax_included_price: 0,
    tax: 0,
    tags: "",
    image: [],
    sku: "",
    hsn: "",
    position: "",
    status: false,
    food_type_veg: "",
    returnable: "",
    cancelable: "",
    return_window: "",
    seller_pickup_return: "",
    time_to_ship: "",
    cod: "",
    product_type: "",
    preservatives: "",
    preservatives_details: "",
    flavours_and_spices: "",
    ready_to_cook: "",
    ready_to_eat: "",
    rice_grain_type: "",
    recommended_age: "",
    baby_weight: "",
    absorption_duration: "",
    scented_or_flavour: "",
    herbal_or_ayurvedic: "",
    theme_or_occasion_type: "",
    hair_type: "",
    mineral_source: "",
    caffeine_content: "",
    capacity: "",
    pf_brand_owner_FSSAI_license_no: "",
    tax_included: "",
    country_of_origin: "",
    uom: "",
    customer_care_contact: "",
    replaceable: "",
    brand: ""

  };
  const rawDataError = {
    name: false,
    short_description: false,
    description: false,
    categories_id: false,
    net_qty: false,
    pc_manufacturer_or_packer_name: false,
    quantity: false,
    mrp: false,
    selling_price: false,
    tax_included_price: false,
    tax: false,
    customer_care_contact: false,
    tags: false,
    image: false,
    sku: false,
    hsn: false,
    position: false,
    status: false,
    food_type_veg: false,
    returnable: false,
    cancelable: false,
    return_window: false,
    seller_pickup_return: false,
    time_to_ship: false,
    cod: false,
    product_type: false,
    preservatives: false,
    preservatives_details: false,
    flavours_and_spices: false,
    ready_to_cook: false,
    ready_to_eat: false,
    rice_grain_type: false,
    recommended_age: false,
    baby_weight: false,
    absorption_duration: false,
    scented_or_flavour: false,
    herbal_or_ayurvedic: false,
    theme_or_occasion_type: false,
    hair_type: false,
    mineral_source: false,
    caffeine_content: false,
    capacity: false,
    pf_brand_owner_FSSAI_license_no: false,
    tax_included: false,
    country_of_origin: false,
    uom: false,
    replaceable: false,
    brand: false

  };
  const [productData, setProductData] = useState(rawData);
  const [productDataError, setProductDataError] = useState(rawDataError);

  // console.log("productData----",productData)

  const updateProductHanlder = async () => {
    let apiEndpoint =  baseUrlDefiner();
    // console.log("productData", productData);
    const errorEmptyAlert = () => {
      toast.error("Please fill the required fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const {
      name,
      short_description,
      description,
      categories_id,
      mrp,
      net_qty,
      pc_manufacturer_or_packer_name,
      quantity,
      selling_price,
      tax_included_price,
      tax,
      tags,
      image,
      sku,
      hsn,
      position,
      status,
      food_type_veg,
      returnable,
      cancelable,
      return_window,
      seller_pickup_return,
      time_to_ship,
      cod,
      product_type,
      pf_brand_owner_FSSAI_license_no,
      preservatives,
      preservatives_details,
      flavours_and_spices,
      ready_to_cook,
      ready_to_eat,
      rice_grain_type,
      recommended_age,
      baby_weight,
      absorption_duration,
      scented_or_flavour,
      herbal_or_ayurvedic,
      theme_or_occasion_type,
      hair_type,
      mineral_source,
      caffeine_content,
      capacity,
      tax_included,
      country_of_origin,
      uom,
      replaceable,
      brand,
      customer_care_contact
    } = productData;
    let validChe = false;
    let validChe_2 = false;
    if (name && short_description && description && mrp && tax && position && return_window && time_to_ship && product_type && status !== "" && returnable !== "" && cancelable !== "" && seller_pickup_return !== "" && cod !== "") {
      if (Number(categories_id) === 25) {
        if (food_type_veg !== "") {
          validChe_2 = true;
        } else {
          validChe_2 = false;


        }
      } else {
        validChe_2 = true;
      }


      if (product_type === "0") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "1") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
          // console.log("product_type = 1 false");

        }
      }
      if (product_type === "2") {
        if (pf_brand_owner_FSSAI_license_no && preservatives !== "" && preservatives_details && flavours_and_spices && ready_to_cook !== "" && ready_to_eat !== "") {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "3") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "4") {
        if (rice_grain_type && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "5") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "6") {
        if (scented_or_flavour) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "7") {
        if (recommended_age && capacity && mineral_source && caffeine_content && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "8") {
        if (scented_or_flavour && herbal_or_ayurvedic && hair_type && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "9") {
        if (pf_brand_owner_FSSAI_license_no && preservatives !== "" && preservatives_details && flavours_and_spices && ready_to_cook !== "" && ready_to_eat !== "" && scented_or_flavour && theme_or_occasion_type && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "10") {
        if (preservatives !== "" && preservatives_details && flavours_and_spices && ready_to_cook !== "" && ready_to_eat !== "" && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "11") {
        if (recommended_age && baby_weight && absorption_duration && scented_or_flavour && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "12") {
        if (preservatives !== "" && preservatives_details && flavours_and_spices && ready_to_cook !== "" && ready_to_eat !== "" && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "14") {
        if (theme_or_occasion_type) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "15") {
        validChe = true;
      }

      let updatedDataToSend;
      let url
      let dataForSeller2 = {
        product_id: productData.id,
        price: productData.selling_price,
        status: true
      }
      if (current_sellerid == 1) {
        updatedDataToSend = productData;
        // url = "https://shikahrapp.hulsecure.in/public/index.php/api/quickupdate"
        url = `${apiEndpoint}/upmaspro`
      } else {
        updatedDataToSend = dataForSeller2;
        url = `${apiEndpoint}/updateproductmaster`
        validChe = true
      }
      // console.log("updatedDataToSend---", updatedDataToSend)
      // console.log("sghdsdsa---", validChe, validChe_2)
      if (validChe && validChe_2) {

        try {

          setUpLoading(true);

          const { data } = await axios({
            url: `${url}`,
            method: "post",
            headers: {
              "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
            },
            data: updatedDataToSend,
          });
          setUpLoading(false);
          // console.log(data)
          if (data.status === 200) {
            toast.success("Product updated successfully !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("404 error !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (error) {
          setUpLoading(false);
          toast.error("404 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      } else {
        console.log("from last second")
        errorEmptyAlert();
      }
    } else {
      console.log("from last ")
      errorEmptyAlert();
    }

  };


  const inputHandler = (e) => {
    setProductData({ ...productData, [e.target.name]: e.target.value });

  }
  const errorHandler = (e) => {
    if (e.target.value) {
      setProductDataError({ ...productDataError, [e.target.name]: false });
    } else {
      setProductDataError({ ...productDataError, [e.target.name]: true });
    }
  }
  const taxListHanler = async () => {
    let apiEndpoint =  baseUrlDefiner();
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/gettaxlist`,
        method: "GET",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
      });
      // console.log("tax listing", data);
      if (data.status === 200) {
        setTaxData(data.data);
      }
    } catch (error) {
    }
  }


  const categoryListing = async () => {
    let apiEndpoint =  baseUrlDefiner();
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getcategories`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
      });
      // console.log("category listing", data);
      if (data.status === 200) {
        // setCategoryList(data.data);
        if (data.status === 200) {
          // Ndata = data.response;
          const parsedData = data.response.map(item => ({
            id: item.id,
            parent_id: item.parent_id,
            cat_name: item.cat_name,
            status: item.status,
            subcategory: item.subcategory || []
          }));
          console.log("parsed data-->", parsedData)
          setNadata(parsedData);


        }
      }
    } catch (error) { }
  };

  const priceHandler = (e) => {
    // console.log("e.target.name", e.target.name);
    if (e.target.name === "selling_price") {
      const percentAmmount =
        (parseFloat(productData.tax) / 100) * parseFloat(e.target.value);
      setProductData((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: productData?.tax_included ? e.target.value : parseFloat(e.target.value) + parseFloat(percentAmmount.toFixed(4))

      }));
    } else if (e.target.name === "tax") {
      const percentAmmount =
        (parseFloat(e.target.value) / 100) * parseFloat(productData.selling_price);
      setProductData((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: productData?.tax_included ? productData?.tax_included_price :
          parseFloat(productData.selling_price) +
          parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax_included") {
      const percentAmmount = (parseFloat(productData.tax) / 100) * parseFloat(productData.selling_price);
      setProductData({
        ...productData,
        [e.target.name]: !productData?.tax_included,
        tax_included_price: !productData?.tax_included ? productData.selling_price : percentAmmount + parseFloat(productData.selling_price)

      });

    }
  };

  const productImageHandler = async (e) => {
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];

      // Remove the Exif metadata using the custom function
      const modifiedArrayBuffer = await removeExifData(file);
      // Convert the modified data back to a Blob
      const strippedBlob = new Blob([modifiedArrayBuffer], { type: file.type });

      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
        region: awsRegion
      });

      const params = {
        Bucket: "ondc-marketplace",
        Key: `images/${uuidv4()}.${myFileType}`,
        Body: strippedBlob, // Use the stripped file
        ContentType: file?.type
      };

      let result = await s3.upload(params).promise();
      const { Location } = result;

      let c = { ...productData };
      c.image.push(Location);

      setProductData({ ...c });
    } catch (exception) {
      console.error('Error during image upload:', exception);
    }
  };

  const productTypeHandler = (e) => {
    if (e.target.value === "0") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        pf_brand_owner_FSSAI_license_no: ""
      });
    } else if (e.target.value === "1") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        pf_brand_owner_FSSAI_license_no: ""
      });
    } else if (e.target.value === "2") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        pf_brand_owner_FSSAI_license_no: "",
        preservatives: "",
        preservatives_details: "",
        flavours_and_spices: "",
        ready_to_cook: "",
        ready_to_eat: "",
      });
    }
    else if (e.target.value === "3") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        pf_brand_owner_FSSAI_license_no: "",
      });
    }
    else if (e.target.value === "4") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        rice_grain_type: "",
        pf_brand_owner_FSSAI_license_no: "",
      });
    }
    else if (e.target.value === "5") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        pf_brand_owner_FSSAI_license_no: ""
      });
    }
    else if (e.target.value === "6") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        scented_or_flavour: ""
      });
    }
    else if (e.target.value === "7") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        recommended_age: "",
        capacity: "",
        mineral_source: "",
        caffeine_content: "",
        pf_brand_owner_FSSAI_license_no: ""
      });
    }
    else if (e.target.value === "8") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        scented_or_flavour: "",
        herbal_or_ayurvedic: "",
        hair_type: "",
        capacity: "",

      });
    }
    else if (e.target.value === "9") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        preservatives: "",
        preservatives_details: "",
        flavours_and_spices: "",
        ready_to_cook: "",
        ready_to_eat: "",
        scented_or_flavour: "",
        theme_or_occasion_type: "",
        capacity: "",
        pf_brand_owner_FSSAI_license_no: ""
      });
    }
    else if (e.target.value === "10") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        preservatives: "",
        preservatives_details: "",
        flavours_and_spices: "",
        ready_to_cook: "",
        ready_to_eat: "",
        scented_or_flavour: "",
        capacity: ""
      });
    }
    else if (e.target.value === "11") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        recommended_age: "",
        baby_weight: "",
        absorption_duration: "",
        scented_or_flavour: "",
        pf_brand_owner_FSSAI_license_no: ""
      });
    }
    else if (e.target.value === "12") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        preservatives: "",
        preservatives_details: "",
        flavours_and_spices: "",
        ready_to_cook: "",
        ready_to_eat: "",
      });
    }
    else if (e.target.value === "13") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,

      });
    }
    else if (e.target.value === "14") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        theme_or_occasion_type: ""
      });
    }
    else if (e.target.value === "15") {
      setProductData({
        ...productData, [e.target.name]: e.target.value
      });
    }

    // setProductData({ ...productData, [e.target.name]: e.target.value })
  }

  const createproductConsole = async () => {
    let apiEndpoint =  baseUrlDefiner();
    setLoading(true);
    const errorEmptyAlert = () => {
      toast.error("Please fill the required fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const {
      name,
      short_description,
      description,
      categories_id,
      mrp,
      net_qty,
      pc_manufacturer_or_packer_name,
      quantity,
      selling_price,
      tax_included_price,
      tax,
      tags,
      image,
      sku,
      hsn,
      position,
      status,
      food_type_veg,
      returnable,
      cancelable,
      return_window,
      seller_pickup_return,
      time_to_ship,
      cod,
      product_type,
      pf_brand_owner_FSSAI_license_no,
      preservatives,
      preservatives_details,
      flavours_and_spices,
      ready_to_cook,
      ready_to_eat,
      rice_grain_type,
      recommended_age,
      baby_weight,
      absorption_duration,
      scented_or_flavour,
      herbal_or_ayurvedic,
      theme_or_occasion_type,
      hair_type,
      mineral_source,
      caffeine_content,
      capacity,
      tax_included,
      country_of_origin,
      uom,
      replaceable,
      customer_care_contact
    } = productData;
    let validChe = false;
    let validChe_2 = false;
    if (name && short_description && description && quantity && uom && sku && pc_manufacturer_or_packer_name && country_of_origin && customer_care_contact && net_qty && categories_id && mrp && selling_price && tax && position && return_window && time_to_ship && product_type && image && status !== "" && returnable !== "" && cancelable !== "" && seller_pickup_return !== "" && cod !== "") {

      //if not working delete this
      // if (Number(category_id) === 25) {
      //   if (food_type_veg) {
      //     validChe_2 = true;
      //   } else {
      //     validChe_2 = false;

      //   }
      // } else {
      //   validChe_2 = true;
      // }
      //if not working delete this


      if (product_type === "0") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "1") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
          // console.log("product_type = 1 false");

        }
      }
      if (product_type === "2") {
        if (pf_brand_owner_FSSAI_license_no && preservatives && preservatives_details && flavours_and_spices && ready_to_cook && ready_to_eat) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "3") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "4") {
        if (rice_grain_type && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "5") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "6") {
        if (scented_or_flavour) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "7") {
        if (recommended_age && capacity && mineral_source && caffeine_content && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "8") {
        if (scented_or_flavour && herbal_or_ayurvedic && hair_type && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "9") {
        if (pf_brand_owner_FSSAI_license_no && preservatives && preservatives_details && flavours_and_spices && ready_to_cook && ready_to_eat && scented_or_flavour && theme_or_occasion_type && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "10") {
        if (preservatives && preservatives_details && flavours_and_spices && ready_to_cook && ready_to_eat && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "11") {
        if (recommended_age && baby_weight && absorption_duration && scented_or_flavour && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "12") {
        if (preservatives && preservatives_details && flavours_and_spices && ready_to_cook && ready_to_eat && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "14") {
        if (theme_or_occasion_type) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "15") {
        validChe = true;

      }
      if (validChe) {
        try {
          console.log("my sent data>>>>>>", productData)

          const { data } = await axios({
            // url: "https://shikahrapp.hulsecure.in/public/index.php/api/addmastercatalogs",
            url: `${apiEndpoint}/addmas`,
            method: "POST",
            headers: {
              "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
            },
            data: productData,
          });


          if (data.status === 200) {
            // setProductData(rawData);
            document.getElementById("image").value = ""
            toast.success("New product created successfully !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {

            toast.error("404 error !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setLoading(false);

        } catch (error) {

          setLoading(false);
          toast.error("404 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      } else {
        errorEmptyAlert();
      }
    } else {
      errorEmptyAlert();
    }
  }


  const MakeCategory = ({ cat_name, subcategory, id }) => {
    const subCategoryChecker = subcategory && subcategory.length >= 1;
    const [isChecked, setIsChecked] = useState(productData.categories_id && productData.categories_id.includes(id));
    const handleCategoryClick = () => {
      onCategoryClick(id)
        ;

    };
    console.log("productdata--->", productData)
    function onCategoryClick(id, checked) {
      if (checked) {
        setProductData({ ...productData, categories_id: [...productData.categories_id, id] });

      } else {
        setProductData({ ...productData, categories_id: productData.categories_id.filter((category_id) => category_id !== id) });
      }
    }

    const handleCheckboxChange = (event) => {
      const newCheckedState = event.target.checked;
      setIsChecked(newCheckedState);
      onCategoryClick(id, newCheckedState);
    };

    useEffect(() => {
      setIsChecked(productData.categories_id && productData.categories_id.includes(id));
    }, [productData.categories_id, id]);
    // console.log("fsdfhsdkffkdsfhsdfaf------->",Subcategorydata )
    return (
      <div>
        <ul style={{ marginLeft: "20px" }}  >
          <li key={id} onClick={handleCategoryClick}
          >
            <input type="checkbox" value={id} name="sub-category" checked={isChecked} onChange={handleCheckboxChange} /> -{cat_name} </li>

          {subCategoryChecker && subcategory.map((item) => (

            <MakeCategory {...item} />
          ))}
        </ul>
      </div>
    )
  }

  function removeImageHandler(index) {
    // alert(index);
    let z = [...productData.image]
    z.splice(index, 1)
    setProductData({ ...productData, image: [...z] })
  }


  const open = () => {
    show(
      <ImageContainer>
        <img src={productData.image} alt="" />
        <button onClick={() => {
          hide("key#1")
        }}>x</button>
      </ImageContainer>,
      { key: "key#1" }
    )
  }

  // console.log("productData", productData)
  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1" >
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  {pageNamem ? "Create product" : "Update product"}
                </h6>



              </div>
              <div className="card-body">
                <form enctype="multipart/form-data">

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Name</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.name ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Enter title"
                          value={productData.name}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Position</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.position ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="number"
                          className="form-control"
                          name="position"
                          placeholder="Position"
                          value={productData.position}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Product status</label>
                        <span className="text-danger" > * </span>

                        {
                          productDataError?.status ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select
                          className="form-control"
                          value={productData.status}
                          name="status"
                          id=""
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}

                        >
                          <option value="">select product status</option>
                          <option value="true">Active</option>
                          <option value="false">Draft</option>
                        </select>


                        <label>Country Origin</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.country_of_origin ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="text"
                          value={productData.country_of_origin}
                          className="form-control"
                          name="country_of_origin"
                          placeholder="Country Origin"
                          onChange={(e) => inputHandler(e)}
                          readOnly={readonly}
                          onBlur={(e) => errorHandler(e)}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Brand</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.brand ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select
                          className="form-control"
                          value={productData.brand}
                          name="brand"
                          id=""
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Select Brand</option>
                          {brandlist.map((item) => (
                            <option value={item.id}>{item.brand_name}</option>
                          ))}
                        </select>
                      </div>
                    </div>


                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Category</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.categories_id ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }



                        {/* code for multi selections:-- */}
                        <div className="selectcategory" onClick={() => setSubcattoggle(!subcattoggle)}>--Select Category--<span style={{ "float": "right" }}>▼</span>
                          {/* <input type="text" className="form-control" name="subcat" placeholder="Search" value={search} onChange={handleSearchChange} /> */}
                        </div>
                        {subcattoggle ? <div className="multiselectdivision">
                          {
                            Nadata.map((item) => {
                              return (
                                <>
                                  <MakeCategory {...item} onSubcategoryChange={handleSubcategoryChange} />
                                </>
                              )
                            })
                          }</div>
                          : ""}
                        {/* code for multi selections:-- */}
                      </div>
                    </div>
                    {
                      Number(productData?.categories_id) === 25 ? (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Food type</label>
                            <span className="text-danger" > * </span>

                            {
                              productDataError?.food_type_veg ? (
                                <span className="text-danger tiny_size" > This field is required ! </span>
                              ) : null
                            }
                            <select
                              className="form-control"
                              value={productData.food_type_veg}
                              name="food_type_veg"
                              id=""
                              onChange={(e) => inputHandler(e)}
                              onBlur={(e) => errorHandler(e)}

                            >
                              <option value="">Select food type</option>
                              <option value="true">Veg</option>
                              <option value="false">Non veg</option>
                            </select>
                          </div>
                        </div>
                      ) : null
                    }
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Short description</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.short_description ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <textarea
                          className="form-control"
                          name="short_description"
                          placeholder="Short description"
                          onChange={(e) => inputHandler(e)}
                          value={productData.short_description}
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Long description</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.description ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <textarea
                          className="form-control"
                          name="description"
                          placeholder="Long description"
                          value={productData.description}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Selling price
                          <span className="text-danger" > * </span>
                          <input type="checkbox" name="tax_included" style={{ marginLeft: "20px" }}
                            onChange={(e) => priceHandler(e)}
                            checked={productData?.tax_included}
                            disabled={readonly}
                          /> Tax included
                        </label>
                        {
                          productDataError?.selling_price ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="number"
                          className="form-control"
                          name="selling_price"
                          placeholder="Selling Price"
                          value={productData.selling_price}
                          onChange={(e) => priceHandler(e)}

                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Max price</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.mrp ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="number"
                          className="form-control"
                          name="mrp"
                          placeholder="Max price"
                          value={productData.mrp ? productData.mrp : productData.price}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tax included price</label>
                        <input
                          type="number"
                          className="form-control"
                          disabled
                          name="tax_included_price"
                          placeholder=""
                          readOnly={readonly}
                          value={productData.tax_included_price}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tax ( % ) </label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.tax ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }

                        <select
                          className="form-control"
                          name="tax"
                          placeholder="Tax"
                          value={productData.tax}
                          onChange={(e) => priceHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Select tax</option>
                          {
                            taxData.map((item) => (
                              <option value={item?.tax_rate}>{item?.tax_rate}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Net Quantity</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.net_qty ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="number"
                          className="form-control"
                          name="net_qty"
                          placeholder="Net Quantity"
                          value={productData.net_qty}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Manufacturer or Packer Name</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.pc_manufacturer_or_packer_name ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="text"
                          className="form-control"
                          name="pc_manufacturer_or_packer_name"
                          placeholder="Manufacturer Name"
                          value={productData.pc_manufacturer_or_packer_name}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Quantity</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.quantity ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="number"
                          className="form-control"
                          name="quantity"
                          placeholder="Quantity"
                          value={productData.quantity}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tags</label>
                        <input
                          type="text"
                          className="form-control"
                          name="tags"
                          placeholder="Enter tags"
                          value={productData.tags}
                          onChange={(e) => inputHandler(e)}
                          readOnly={readonly}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>SKU</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.sku ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="text"
                          className="form-control"
                          name="sku"
                          placeholder="Enter sku"
                          value={productData.sku}
                          onChange={(e) => inputHandler(e)}
                          readOnly={readonly}
                          onBlur={(e) => errorHandler(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>HSN</label>
                        <input
                          type="text"
                          className="form-control"
                          name="hsn"
                          placeholder="Enter hsn"
                          value={productData.hsn}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>UOM</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.uom ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="text"
                          className="form-control"
                          name="uom"
                          placeholder="Enter uom"
                          value={productData.uom}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Customer Care Contact</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.customer_care_contact ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <input
                          type="text"
                          className="form-control"
                          readOnly={readonly}
                          name="customer_care_contact"
                          placeholder="Enter Customer Care Number"
                          value={productData.customer_care_contact}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Images</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.image ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        {productData.image && <a
                          href={productData.image[0]}
                          onClick={open}
                          target="_blank"
                          style={{
                            fontSize: "10px",
                            marginLeft: "3px",
                            textDecoration: "underline",
                          }}
                        >
                          View Document
                        </a>}
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => productImageHandler(e)}
                          accept="image/*"
                          name="image"
                          multiple
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readonly}
                          id="image"
                        />
                        {/*<span className="text-danger" >Only jpeg image is allowed </span>*/}
                      </div>
                    </div>
                    {/* <div className="col-md-2 d-flex align-items-end" >

                      <span className="badge badge-primary form-group" onClick={handleOpen} style={{ cursor: "pointer" }}>Add Variant</span>
                    </div>


                    <div className="col-md-2 d-flex align-items-end" >
                      <span className="badge badge-primary form-group" onClick={handleImageOpen} style={{ cursor: "pointer" }}>Add Images</span>
                    </div> */}

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Product Type</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.product_type ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select name="product_type" id="" className="form-control"
                          value={productData.product_type}
                          onChange={(e) => productTypeHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Select your product type</option>
                          {/* <option value="0">Fruits and Vegetables</option> */}
                          <option value="1">Masala and Seasoning</option>
                          <option value="2">Oil & Ghee</option>
                          <option value="3">Gourmet & World Foods</option>
                          <option value="4">Foodgrains</option>
                          <option value="5">Eggs, Meat & Fish</option>
                          <option value="6">Cleaning & Household</option>
                          <option value="7">Beverages</option>
                          <option value="8">Beauty & Hygiene</option>
                          <option value="9">Bakery, Cakes & Dairy </option>
                          <option value="10">Kitchen Accessories</option>
                          <option value="11">Baby Care</option>
                          <option value="12">Snacks & Branded Foods</option>
                          <option value="13">Pet Care</option>
                          <option value="14">Stationery</option>
                          <option value="15">Fruits and Vegetables</option>

                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {/* {
                      productData.product_type === "0" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Fruits and Vegetables</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FSSAI No.</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No."
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}
                                readOnly={readonly}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    } */}
                      {
                        productData.product_type === "1" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Masala and Seasoning</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>FASSAI No.</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  maxLength="14"
                                  className="form-control"
                                  name="pf_brand_owner_FSSAI_license_no"
                                  placeholder="Enter FASSAI No."
                                  value={productData.pf_brand_owner_FSSAI_license_no}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "2" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Oil & Ghee</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>FASSAI No.</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  maxLength="14"
                                  className="form-control"
                                  name="pf_brand_owner_FSSAI_license_no"
                                  placeholder="Enter FASSAI No."
                                  value={productData.pf_brand_owner_FSSAI_license_no}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Preservatives</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.preservatives ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.preservatives}
                                  name="preservatives"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Preservatives</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>

                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Preservatives Details</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.preservatives_details ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="preservatives_details"
                                  placeholder="Enter preservatives details"
                                  value={productData.preservatives_details}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Flavours and Spices</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.flavours_and_spices ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="flavours_and_spices"
                                  placeholder="Enter Flavour & Spices"
                                  value={productData.flavours_and_spices}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Ready to cook</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.ready_to_cook ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.ready_to_cook}
                                  name="ready_to_cook"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Ready to Cook</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Ready to eat</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.ready_to_eat ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.ready_to_eat}
                                  name="ready_to_eat"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Ready to Eat</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "3" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Gourmet and World Foods</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>FASSAI No.</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  maxLength="14"
                                  className="form-control"
                                  name="pf_brand_owner_FSSAI_license_no"
                                  placeholder="Enter FASSAI No."
                                  value={productData.pf_brand_owner_FSSAI_license_no}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "4" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Foodgrains</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>FASSAI No.</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  maxLength="14"
                                  className="form-control"
                                  name="pf_brand_owner_FSSAI_license_no"
                                  placeholder="Enter FASSAI No."
                                  value={productData.pf_brand_owner_FSSAI_license_no}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Rice Grain Type</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.rice_grain_type ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="rice_grain_type"
                                  placeholder="Enter Rice Grain Type"
                                  value={productData.rice_grain_type}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "5" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Eggs, Meat & Fish</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>FASSAI No.</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  maxLength="14"
                                  className="form-control"
                                  name="pf_brand_owner_FSSAI_license_no"
                                  placeholder="Enter FASSAI No."
                                  value={productData.pf_brand_owner_FSSAI_license_no}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "6" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Cleaning & Household</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Scented or Flavour</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.scented_or_flavour ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="scented_or_flavour"
                                  placeholder="Enter Scented/Flavour"
                                  value={productData.scented_or_flavour}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "7" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Beverages</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Recommended Age</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.recommended_age ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="recommended_age"
                                  placeholder="Enter Age No."
                                  value={productData.recommended_age}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Capacity</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.capacity ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="capacity"
                                  placeholder="Enter capacity"
                                  value={productData.capacity}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Mineral Source</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.mineral_source ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="mineral_source"
                                  placeholder="Enter Mineral Source"
                                  value={productData.mineral_source}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Caffeine Content</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.caffeine_content ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="caffeine_content"
                                  placeholder="Enter Caffeine Content"
                                  value={productData.caffeine_content}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>FASSAI No</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  maxLength="14"
                                  className="form-control"
                                  name="pf_brand_owner_FSSAI_license_no"
                                  placeholder="Enter FASSAI No"
                                  value={productData.pf_brand_owner_FSSAI_license_no}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "8" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Beauty & Hygiene</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Scented or Flavour</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.scented_or_flavour ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="scented_or_flavour"
                                  placeholder="Enter Scented or Flavour"
                                  value={productData.scented_or_flavour}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Herbal or Ayurvedic</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.herbal_or_ayurvedic ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="herbal_or_ayurvedic"
                                  placeholder="Enter Herbal or Ayurvedic"
                                  value={productData.herbal_or_ayurvedic}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Hair Type</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.hair_type ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="hair_type"
                                  placeholder="Enter Hair Type"
                                  value={productData.hair_type}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Capacity</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.capacity ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="capacity"
                                  placeholder="Enter Capacity"
                                  value={productData.capacity}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "9" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Bakery, Cakes and Dairy</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Preservatives</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.preservatives ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.preservatives}
                                  name="preservatives"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Preservatives</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Preservatives Details</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.preservatives_details ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="preservatives_details"
                                  placeholder="Enter Preservatives Details"
                                  value={productData.preservatives_details}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Flavours & Spices</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.flavours_and_spices ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="flavours_and_spices"
                                  placeholder="Enter Flavours & Spices"
                                  value={productData.flavours_and_spices}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Ready to cook</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.ready_to_cook ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.ready_to_cook}
                                  name="ready_to_cook"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Ready to Cook</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Ready to Eat</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.ready_to_eat ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.ready_to_eat}
                                  name="ready_to_eat"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Ready to Eat</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Scented or Flavour</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.scented_or_flavour ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="scented_or_flavour"
                                  placeholder="Enter Scented or Flavour"
                                  value={productData.scented_or_flavour}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Theme or Occasion Type</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.theme_or_occasion_type ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="theme_or_occasion_type"
                                  placeholder="Enter Theme or Occasion Type"
                                  value={productData.theme_or_occasion_type}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Capacity</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.capacity ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="capacity"
                                  placeholder="Enter Capacity"
                                  value={productData.capacity}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>FASSAI No.</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  maxLength="14"
                                  className="form-control"
                                  name="pf_brand_owner_FSSAI_license_no"
                                  placeholder="Enter FASSAI No"
                                  value={productData.pf_brand_owner_FSSAI_license_no}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "10" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Kitchen Accessories</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Preservatives</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.preservatives ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.preservatives}
                                  name="preservatives"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Preservatives</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Preservatives Details</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.preservatives_details ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="preservatives_details"
                                  placeholder="Enter Preservatives Details"
                                  value={productData.preservatives_details}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Flavours & Spices</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.flavours_and_spices ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="flavours_and_spices"
                                  placeholder="Enter Flavours & Spices"
                                  value={productData.flavours_and_spices}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Ready to cook</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.ready_to_cook ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.ready_to_cook}
                                  name="ready_to_cook"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Ready to Cook</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Ready to Eat</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.ready_to_eat ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.ready_to_eat}
                                  name="ready_to_eat"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Ready to Eat</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Capacity</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.capacity ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="capacity"
                                  placeholder="Enter Capacity"
                                  value={productData.capacity}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>

                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "11" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Baby Care</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Recommended Age</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.recommended_age ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="recommended_age"
                                  placeholder="Enter Recommended Age"
                                  value={productData.recommended_age}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Baby Weight</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.baby_weight ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="baby_weight"
                                  placeholder="Enter Weight Details"
                                  value={productData.baby_weight}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Absorption Duration (in Hrs)</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.absorption_duration ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="absorption_duration"
                                  placeholder="Enter Absorption Duration "
                                  value={productData.absorption_duration}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Scented or Flavour</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.scented_or_flavour ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="scented_or_flavour"
                                  placeholder="Enter Scented or Flavour"
                                  value={productData.scented_or_flavour}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>FASSAI No</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  maxLength="14"
                                  className="form-control"
                                  name="pf_brand_owner_FSSAI_license_no"
                                  placeholder="Enter FASSAI No"
                                  value={productData.pf_brand_owner_FSSAI_license_no}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>




                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "12" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Snacks & Branded Foods</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Preservatives</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.snacks_preservatives ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.preservatives}
                                  name="preservatives"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Preservatives</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Preservatives Details</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.preservatives_details ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="preservatives_details"
                                  placeholder="Enter Preservatives Details"
                                  value={productData.preservatives_details}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Flavours & Spices</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.flavours_and_spices ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="flavours_and_spices"
                                  placeholder="Enter Flavours & Spices"
                                  value={productData.flavours_and_spices}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Ready to cook</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.ready_to_cook ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.ready_to_cook}
                                  name="ready_to_cook"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Ready to Cook</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Ready to Eat</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.ready_to_eat ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <select
                                  className="form-control"
                                  value={productData.ready_to_eat}
                                  name="ready_to_eat"
                                  id=""
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  disabled={readonly}
                                >
                                  <option value="">Select Ready to Eat</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>FASSAI No.</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  maxLength="14"
                                  className="form-control"
                                  name="pf_brand_owner_FSSAI_license_no"
                                  placeholder="Enter FASSAI No."
                                  value={productData.pf_brand_owner_FSSAI_license_no}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>




                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "13" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Pet Care</p>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        productData.product_type === "14" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <p className='bold_mak' >Stationery</p>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Theme or Occasion Type</label>
                                <span className="text-danger" > * </span>
                                {
                                  productDataError?.theme_or_occasion_type ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                                <input
                                  type="text"
                                  className="form-control"
                                  name="theme_or_occasion_type"
                                  placeholder="Enter Theme or Occasion Type"
                                  value={productData.theme_or_occasion_type}
                                  onChange={(e) => inputHandler(e)}
                                  onBlur={(e) => errorHandler(e)}
                                  readOnly={readonly}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      }

                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Product returnable</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.returnable ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select
                          className="form-control"
                          value={productData.returnable}
                          name="returnable"
                          id=""
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Select returnable</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Product replaceable</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.replaceable ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select
                          className="form-control"
                          value={productData.replaceable}
                          name="replaceable"
                          id=""
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Select replaceable</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Product cancelable</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.cancelable ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select
                          className="form-control"
                          value={productData.cancelable}
                          name="cancelable"
                          id=""
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Select cancelable</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Return window</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.return_window ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select
                          className="form-control"
                          value={productData.return_window}
                          name="return_window"
                          id=""
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Select return window</option>
                          {returnWindowLength.map((item) => (
                            <option value={`P${item}D`}>{`P${item}D`}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Seller pickup return</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.seller_pickup_return ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select
                          className="form-control"
                          value={productData.seller_pickup_return}
                          name="seller_pickup_return"
                          id=""
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Select seller return pickup</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Time to ship</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.time_to_ship ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select
                          className="form-control"
                          value={productData.time_to_ship}
                          name="time_to_ship"
                          id=""
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Time to Ship</option>
                          {timeToShipLength.map((item) => (
                            <option value={`P${item}D`}>{`P${item}D`}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Cash on delivery</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.cod ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select
                          className="form-control"
                          value={productData.cod}
                          name="cod"
                          id=""
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Select cash on delivery</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Approve Status</label>
                        <span className="text-danger" > * </span>
                        {
                          productDataError?.approve_status ? (
                            <span className="text-danger tiny_size" > This field is required ! </span>
                          ) : null
                        }
                        <select
                          className="form-control"
                          value={productData.approve_status}
                          name="approve_status"
                          id=""
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={readonly}
                        >
                          <option value="">Select Approve Status</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                    <div className="form-group">
                      <label>Retail Approve Status</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.retail_approve_status ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select
                        className="form-control"
                        value={productData.retail_approve_status}
                        name="retail_approve_status"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={readonly}
                      >
                        <option value="">Select Retail Approve Status</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div> */}

                    <div className="col-md-12">
                      {pageNamem ? (
                        <button
                          type="submit"
                          className={btnstyle.button}
                          // onClick={createProductHandler}
                          onClick={createproductConsole}
                        >  {loading ? "Loading..." : "Create"}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-success"
                          onClick={updateProductHanlder}
                        > {uploading ? "Loading..." : "Update"}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-center"></h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-10">
                    <p> Location Name </p>
                  </div>
                  <div className="col-md-2">
                    <p> Quantity </p>
                  </div>
                  {/* {locatioList?.location?.map((item: any, index: any) => ( */}
                  <>
                    <div className="col-md-10">
                      {/* <p>
                          {" "}
                          {index + 1}. {item?.name}{" "}
                        </p> */}
                    </div>
                    <div className="col-md-2">
                      <input
                        type="number"
                        //   value={item?.quantity}
                        className="form-control"
                      //   onChange={(e) => setLocationValue(e, index)}
                      />
                    </div>

                  </>
                  {/* ))} */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                //   onClick={importCsvHanlder}
                >
                  {loading ? "updating..." : "Update"}
                </button>

                <button
                  type="button"
                  id="closebtn"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>

          <Modal
            open={mopen}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >

            <Box sx={smstyle}>
              <CloseIcon style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={handleClose} />
              <p className="text-center" style={{ fontSize: "20px", fontWeight: "700" }}>Variants</p>


              <div>
                <Variants></Variants>
              </div>
            </Box>
          </Modal>
          <Modal
            open={iopen}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >

            <Box sx={smstyle}>
              <CloseIcon style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={handleimageClose} />
              <p className="" style={{ fontSize: "20px", fontWeight: "700" }}> Images</p>


              <div>
                <ImageAdd productImageHandler={productImageHandler} productData={productData} removeImageHandler={removeImageHandler} />
              </div>
            </Box>
          </Modal>
        </div>
      </ThemeMaker>
    </>
  )
}
export default MasterProductAdd;