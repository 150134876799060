import * as React from "react";
import "./switch.css";
import { useEffect, useState } from "react";
import { tokenDecode } from "../../utils/helper";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import styled from "styled-components";
import { config } from "../../utils/config";
import Circularprogress from "../../components/Generics/Circularprogress";
import Applyfilter from "../../components/Applyfilter/Applyfilter";
import ReactPaginate from 'react-paginate';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import JsBarcode from "jsbarcode";
import Barcode from 'react-barcode';
import ThemeMaker from "../../components/thememaker";
const { apiEndpoint } = config
const ImageContainer = styled.div
  `
  max-height: 64px;
  width: 25%;
  
  img{
    width: 100%!important;
    height: 68px;
  object-fit: contain;
}
  `

const Mastercatalog = () => {
  const ref = React.useRef(" ");
  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id;
  // console.log("current Seller id:-----", current_sellerid)
  const decode = tokenDecode();
  const { data } = decode;
  const [productlist, setproductlist] = useState([]);
  const [noproducts, setNoproducts] = useState(false);
  const [productisLoading, setproductisLoading] = useState(false);
  const [searchValue, setsearchValue] = useState({
    name: "",
    searchFilter: ""
  })
  const [mopen, setmopen] = React.useState(false);
  const smstyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450, bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, minHeight: 200, maxHeight: 500, overflow: "scroll", color: "black",
  };
  const handleClose = () => setmopen(false);
  useEffect(() => {
    getproductlist();
  }, []);

  useEffect(() => {
    // When productlist changes, update itemCheckboxes to reflect the current state
    const checkboxes = {};
    productlist.forEach((item) => {
      checkboxes[item.id] = item.in_store;
    });
    setItemCheckboxes(checkboxes);
  }, [productlist]);

  async function getproductlist() {
    let apiEndpoint =  baseUrlDefiner();
    let parentId = decoded?.data?.user?.parent_id;
    let SellerId = decoded?.data?.user?.seller_id;
    let payload;
    if (parentId > 1) {
      payload = { seller_id: parentId }

    } else {
      payload = { seller_id: SellerId }

    }
    setproductisLoading(true)
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/showallproductmasters`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: payload,
      });
      // console.log("data of master product list-->", data)
      if (data) {
        setNoproducts(false)
        setproductlist(data.data);
        setproductisLoading(false)

      }
    } catch (error) { }
  }

  function searchHandler(e) {
    setsearchValue({ ...searchValue, [e.target.name]: e.target.value });
    if (e.target.value == "") {
      getproductlist();
    }
  }
  function selectHandler(e) {
    setsearchValue({ ...searchValue, searchFilter: e.target.value });
  }

  async function searchResult(e) {
    // console.log("searchValue", e.key);
    let apiEndpoint =  baseUrlDefiner();
    if (e.key == "Enter") {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/searchproduct`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            type: searchValue.searchFilter,
            name: searchValue.name,
          },
        });
        // console.log("data of /=master product list-->", data)
        if (data.status == 200) {
          setproductlist(data?.data);
          setItemOffset(0)
          if (data?.data?.length < 1) {
            setNoproducts(true)
          }
        } else {
          setproductlist([]);
        }
      } catch (error) { }
    }
  }

  function handleSort(e) {
    if (e.target.value == 0) {
      const sortedLowToHigh = [...productlist].sort((a, b) => {
        return parseInt(a.mrp) - parseInt(b.mrp);
      });
      setproductlist(sortedLowToHigh);
    } else if (e.target.value == 1) {
      const sortedHightoLow = [...productlist].sort((a, b) => {
        return parseInt(b.mrp) - parseInt(a.mrp);
      });
      setproductlist(sortedHightoLow);
    } else {
      getproductlist();
    }
  }

  //---------------------------------------------- code  for pagination------------------------------------------------
  const [itemCheckboxes, setItemCheckboxes] = useState({});
  useEffect(() => {
    // When productlist changes, update itemCheckboxes to reflect the current state
    const checkboxes = {};
    productlist.forEach((item) => {
      checkboxes[item.id] = item.in_store;
    });
    setItemCheckboxes(checkboxes);
  }, [productlist]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  // const currentItems = productlist.slice(itemOffset, endOffset);

  const [currentItems, setcurrentItems] = useState([]);

  useEffect(() => {
    let z = productlist.slice(itemOffset, endOffset)
    setcurrentItems([...z])
  }, [productlist, itemOffset])

  const pageCount = Math.ceil(productlist.length / 10);
  const handlePageClick = (event) => {
    window.scrollTo(0, 0)
    const newOffset = (event.selected * 10) % productlist.length;
    setItemOffset(newOffset);
  };
  //---------------------------------------------- code for pagination------------------------------------------------


  function updateProductList(productList) {
    setproductlist(productList)
  }
  const [svg, setsvg] = useState("")
  async function getBarcode(item) {
    // console.log("item--------->", item)
    setsvg(item.id)
   
  }



  return (
    <ThemeMaker>
    <>
      <div className="row mx-auto p-2">




        <div className="col-12">
          <div className="d-flex searchDiv">
            <select
              name="searchFilter"
              id=""
              className="m-0"
              onChange={selectHandler}
              value={searchValue.searchFilter}
            >
              <option value="">Search By</option>
              <option value="Product">Product</option>
              <option value="Brand">Brand</option>
              <option value="Category">Category</option>
            </select>

            <input
              type="search"
              name="name"
              placeholder="Product, Category or Brand"
              className="w-100 mr-2 rounded-pill m-0"
              value={searchValue.name}
              onChange={searchHandler}
              onKeyPress={searchResult}
              style={{ fontSize: "14px" }}
            />
          </div>
        </div>
        <div className="col-12 d-flex btn-toolbar p-0 d-flex justify-content-between align-items-center" role="toolbar">
          {/* <div
            className="btn-group p-2 w-50"
            role="group"
            style={{ height: "57px" }}
          >
            <button
              type="button"
              className="btn btn-secondary btnvar"
              style={{ fontSize: "2.5vw" }}
              onClick={searchResult}
            >
              Apply Filter
            </button>
          </div> */}
          <Applyfilter updateProductList={updateProductList} getproductlist={getproductlist} url="filtermastercatalog"></Applyfilter>
          <div className="btn-group" role="group" style={{ width: "37%" }}>
            <select
              type="button"
              className="btn btn-secondary w-100  "
              style={{ fontSize: "14px", margin: "5px 0px" }}
              onChange={handleSort}

            >
              <option value="">Sort</option>
              <option value="0">Low - High</option>
              <option value="1">High - Low</option>
            </select>
          </div>
        </div>
        {productisLoading ? <Circularprogress /> : <>{!noproducts ? <> {
          currentItems?.map((item, index) => {
            return <div className="d-flex w-100 align-items-center mb-2  py-2" style={{ gap: "3px", position: "relative", background: "white" }} key={item.id}>

              <ImageContainer>
                <img src={item.image} className="w-100" />
              </ImageContainer>
              <div className="prod_desc d-flex flex-column">
                <span>{item.name}</span>
                <span>Category : {item.category_name}</span>

                <span>Mrp : Rs{item.mrp}</span>

                <span className="badge badge-primary" style={{ width: "100px", cursor: "pointer" }}
                  onClick={() => { setmopen(true); getBarcode(item) }}>Show Barcode</span>

              </div>

              <div className="position-absolute" style={{ top: "3px", right: "5px" }}>
                <label className="switch">
                  <input type="checkbox" checked={itemCheckboxes[item.id]} onChange={async (e) => {
                    const itemId = item.id;
                    const isChecked = !itemCheckboxes[item.id];
                    setItemCheckboxes({ ...itemCheckboxes, [itemId]: isChecked });
                    let apiEndpoint =  baseUrlDefiner();
                    // console.log("objecthsjdshdksdjas---", { id: itemId, isCheked });
                    const { data } = await axios({
                      url: `${apiEndpoint}/addmastercatalogproduct`,
                      method: "POST",
                      headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                      },
                      data: { id: itemId, isCheked: isChecked },
                    });
                    // getproductlist()
                    // const updatedProductlist = [...productlist];
                    // updatedProductlist[index] = { ...item, in_store: isCheked };
                    // setproductlist(updatedProductlist);

                  }} />
                  <span className="slider round"></span>
                </label>
              </div>
              {/* quick update html */}
            </div>
          })
        } </> : <p className="w-100" style={{ fontSize: "16px", color: "black", fontWeight: "600", textAlign: "center" }}>No such products</p>}</>}


        {/* -------------------------------------------- pagination start ---------------------------------------- */}

        {!productisLoading && <div className='pagination_main'>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeLinkClassName="current_active"

          />

        </div>}
        {/* -------------------------------------------- pagination end ---------------------------------------- */}
      </div>

      <Modal
        open={mopen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={smstyle} className="variantBox">
          <CloseIcon style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={handleClose} />
          <p className="text-center" style={{ fontSize: "20px", fontWeight: "700" }}>Bar Code</p>
          <div className="d-flex justify-content-center">
          <Barcode value={svg} width={4}/>;
          </div>
        

        </Box>
      </Modal>
    </>
    </ThemeMaker>
  );
};

export default Mastercatalog;
