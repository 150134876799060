import React from 'react'
import eye from "../../icons/eye.png"
import styles from "../TaxlistTemplate/TaxlistTemplate.module.css"
import deleteicon from "../../icons/delete.png"
export default function Categorylist({ item, index, editRedirect, deleteTax }) {
    console.log("item-----", item)
    return (
        <>
            <div className={`d-flex justify-content-between w-100 ${styles.taxlistTemplatemain}`} key={item.id}>
                <div className={`${styles.taxlistTemplateImage}`}>
                    <span>0{index + 1}</span>
                </div>
                <div className={`${styles.taxlistTemplateDesc}`}>
                    <div>
                        <p className='m-0'>  <span>Category:</span> <span style={{ color: "black", fontWeight: "700" }}>{item.category_name
                        }</span></p>
                    </div>
                    <div>
                        <p className='m-0'>  <span>Created At:</span><span> {item.created_at} </span></p>
                    </div>

                    <div>
                        {item.status ? <button className={styles.success}>Active</button> : <button className={styles.danger}>Deactive</button>}
                    </div>


                </div>


                <div className={`${styles.taxlistTemplateedit}`}>

                    <div onClick={() => deleteTax(item.id)}>
                        <img src={deleteicon} alt="" />
                    </div>
                    <img src={eye} alt="" onClick={() => {
                        editRedirect(item.id)

                    }} data-target="#exampleModal1" data-toggle="modal" />
                </div>
            </div>

        </>
    )
}
