import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { BasicTable } from "../../components/Table";
import { Paginated } from "../../components/Paginated";
import "../../components/table.css";
import Papa from 'papaparse';
import "./Master.css"
import Pagination from "../../components/Paginated/Pagination";
import InputTemplate from "../../components/Search&Options/InputTemplate";
import ProductTemplate from "../../components/ProductViewTemplate/ProductTemplate";
import style from "../../components/Search&Options/Search&Options.module.css"
import searchicon_black from "../../icons/searchicon_black.png"
import btnstyle from "../../components/Buttons/Buttons.module.css"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

const { apiEndpoint } = config;
var $ = require("jquery");
var count = 1;
const ProductList = () => {

    const decoded = tokenDecode();
    let current_sellerid = decoded.data.user.seller_id
    console.log("current Seller id:-----", current_sellerid)
    const [loading, setLoading] = useState(false);
    //code for importing the csv file
    const [file, setFile] = useState(null);
    const [importdata, setimportdata] = useState([]);
    const [maxprice, setmaxprice] = useState("")

    const handleImport = async () => {
        let apiEndpoint =  baseUrlDefiner();
        // console.log(file)
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: ({ data }) => {
                console.log(data)
                let abc = data.filter(item => item.name !== null)
                let orders = abc.map(order => ({
                    name: order.name,
                    position: order.position,
                    category_id: order.category_id,
                    product_type: order.product_type,
                    pf_brand_owner_FSSAI_license_no: order.pf_brand_owner_FSSAI_license_no,
                    short_description: order.short_description,
                    description: order.description,
                    pc_manufacturer_or_packer_name: order.pc_manufacturer_or_packer_name,
                    pc_common_or_generic_name_of_commodity: order.pc_common_or_generic_name_of_commodity,
                    // pc_common_of_generic_name_of_commodity_in_pkg: order.pc_common_of_generic_name_of_commodity_in_pkg,
                    pc_month_year_of_manufacture_packing_import: order.pc_month_year_of_manufacture_packing_import,
                    pc_multiple_product_name_number_of_quantity: order.pc_multiple_product_name_number_of_quantity,
                    pc_imported_product_country_of_origin: order.pc_imported_product_country_of_origin,
                    // pf_nutritional_info: order.pf_nutritional_info,
                    // pf_additives_info: order.pf_additives_info,
                    // pf_other_fassai_license_no: order.pf_other_fassai_license_no,
                    // pf_importer_fassai_license_no: order.pf_importer_fassai_license_no,                    
                    pc_manufacturer_or_packer_address: order.pc_manufacturer_or_packer_address,
                    mrp: order.mrp,
                    uom: order.uom,
                    selling_price: order.selling_price,
                    tax_included_price: order.tax_included_price,
                    tax_included: order.tax_included,
                    tax: order.tax,
                    hsn: order.hsn,
                    tags: order.tags,
                    sku: order.sku,
                    country_of_origin: order.country_of_origin,
                    customer_care_contact: order.customer_care_contact,
                    time_to_ship: order.time_to_ship,
                    returnable: order.returnable,
                    cancelable: order.cancelable,
                    category_name: order.category_name,
                    return_window: order.return_window,
                    seller_pickup_return: order.seller_pickup_return,
                    replaceable: order.replaceable,
                    cod: order.cod,
                    image: order.image,
                    status: order.status,
                    approve_status: order.approve_status,
                    // retail_approve_status: order.retail_approve_status,
                    brand: order.brand,
                    // upc: order.upc,
                    hair_type: order.hair_type,
                    herbal_or_ayurvedic: order.herbal_or_ayurvedic,
                    quantity: order.quantity,
                    net_qty: order.net_qty,
                    ready_to_eat: order.ready_to_eat,
                    ready_to_cook: order.ready_to_cook,
                    rice_grain_type: order.rice_grain_type,
                    capacity: order.capacity,
                    preservatives: order.preservatives,
                    preservatives_details: order.preservatives_details,
                    flavours_and_spices: order.flavours_and_spices,
                    scented_or_flavour: order.scented_or_flavour,
                    theme_or_occasion_type: order.theme_or_occasion_type,
                    recommended_age: order.recommended_age,
                    mineral_source: order.mineral_source,
                    caffeine_content: order.caffeine_content,
                    absorption_duration: order.absorption_duration,
                    baby_weight: order.baby_weight,
                    brand_name: order.brand_name,
                    weight: order.weight,
                    reference_id: order?.reference_id,
                    //extraas:-
                    seller_id: order.seller_id,
                    updated_at: order.updated_at,
                    ondc_product_id: order.ondc_product_id,
                    product_type_name: order.product_type_name

                }))
                let jsonData = {
                    orders: orders
                };
                console.log(jsonData)
                sendDataToDb(jsonData)
            },
        });
    };
    async function sendDataToDb(jsonData) {
        let apiEndpoint =  baseUrlDefiner();
        try {
            setLoading(true);
            console.log(jsonData)
            let result = await axios({
                url: `${apiEndpoint}/uploadFileData`,
                method: 'POST',
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: jsonData,

            })
            // console.log(result)
            setLoading(false);
            if (result.status === 200) {
                // console.log("success")
                toast.success("Products added successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setFile(null)
                categoryListing()
                document.getElementById('inputFile').value = ''
            } else {
                // console.log("this is from succes if")
                toast.error("403 error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } catch (error) {
            setLoading(false);
            // console.log("this is from catch")
            toast.error("403 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const Sstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: "10px",
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 2,
    };

    const [productList, setProductList] = useState([]);
    const [sproductList, setSproductList] = useState([]);
    const [sellerList, setSellerList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [sellers, setSellers] = useState({
        seller: "",
    });
    const rawData = {
        seller_id: "",
    };
    const rawpData = {
        seller_id: "",
        min_price: "",
    };
    const rawsData = {
        seller_id: "",
        sku: "",
    };
    const [categories, setCategories] = useState();
    const [searchData, setSearchData] = useState(rawData);
    const [searchpData, setSearchpData] = useState(rawpData);
    const [searchsData, setSearchsData] = useState(rawsData);
    const [mprodcutlist, setmproductlist] = useState(rawData);
    const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
    const [valuename, setValuename] = useState("");
    const [valueprice, setValueprice] = useState("");
    const [valuesku, setValuesku] = useState("");
    const [toggle, setToggle] = useState(false);
    const [searchButtonDisable, setsearchButtonDisable] = useState(true)
    const decode = tokenDecode();
    const { data } = decode;
    const {
        permission: { category, order, product, role, seller, tax, user, mastercatalog },
    } = data;
    const categoryListing = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/showallproductmasters`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    seller_id: decode?.data?.user?.seller_id,
                },
            });
            console.log("data of master product list-->", data)
            if (data) {
                setProductList(data.data);
                // console.log(data.data)
            }

        } catch (error) { }
    };
    const [quickUpdatedMaxPrice, setquickUpdatedMaxPrice] = useState("")
    const [quickUpdatedMaxPrice2, setquickUpdatedMaxPrice2] = useState("")
    const [openInput, setopenInput] = useState(null)
    function maxPriceHandler(id) {
        // console.log("clicked");
        setopenInput(openInput === id ? null : id);
        const maxPriceProduct = productList.filter(item => item.id == id);
        setquickUpdatedMaxPrice2(...maxPriceProduct)
        // console.log(maxPriceProduct);
        let mp = maxPriceProduct[0].mrp
        // console.log(mp)
        // setmaxprice(mp);
        setquickUpdatedMaxPrice(mp);

    }

    async function QuickUpdateHandler(id) {
        let apiEndpoint =  baseUrlDefiner();
        let updatedDataToSend;
        let dataForSeller2 = {
            product_id: id,
            price: quickUpdatedMaxPrice,
            status: true,
        }
        if (current_sellerid == 1) {
            updatedDataToSend = { ...quickUpdatedMaxPrice2, mrp: quickUpdatedMaxPrice };
        } else {
            updatedDataToSend = dataForSeller2;
        }

        // console.log(updatedDataToSend)

        const { data } = await axios({
            url: `${apiEndpoint}/updateproductcatalog`,
            method: "post",
            headers: {
                "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
            },
            data: updatedDataToSend,
        });

        categoryListing();

    }



    // const fetchSellerList = async () => {
    //     try {
    //         const { data } = await axios({
    //             url: `${apiEndpoint}/getsellerlist`,
    //             method: "GET",
    //             headers: {
    //                 "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
    //             },
    //         });
    //         if (data.status === 200) {
    //             setSellerList(data.data);
    //         }
    //     } catch (error) {
    //         console.log("error", error);
    //     }
    // };
    // const fetchCatgoryList = async () => {
    //     try {
    //         const { data } = await axios({
    //             url: `${apiEndpoint}/getcategorylist`,
    //             method: "GET",
    //             headers: {
    //                 "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
    //             },
    //         });
    //         if (data.status === 200) {
    //             setCategoryList(data.data);
    //         }
    //     } catch (error) {
    //         console.log("error", error);
    //     }
    // };
    const deleteProduct = async (id) => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/deletemasterprod`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: { id },
            });
            if (data.status === 200) {
                setProductList(productList.filter((item) => item.id !== id));
                toast.success("Product deleted!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("403 error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("403 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
    const inputHandler = async (e) => {
        let apiEndpoint =  baseUrlDefiner();
        var optionvalue = e.target.value;
        setValuename("");
        setValueprice("");
        setValuesku("");
        if (optionvalue) {
            try {
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
                setToggle(true);
                /*if(e.target.name==="seller"){etSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                        setSellers({[e.target.name]: e.target.value})
                    }
                    if(e.target.name==="category"){
                        setCategories({[e.target.name]: e.target.value})
                    }
                    console.log(sellers);*/
                console.log(optionvalue);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterproductbyseller`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        seller_id: optionvalue,
                    },
                });
                if (data.status === 200) {
                    setProductList(data.response);
                    setSproductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            setProductList(sproductList);
        }
    };

    const [searchValue, setsearchValue] = useState("")
    const masterSearchHandler = async (e) => {
        let searchedData = e.target.value;
        setsearchValue(searchedData)
        if (searchedData.length > 0) {
            let dataToSend = {
                search: searchedData
            }
            try {
                const { data } = await axios({
                    url: `${apiEndpoint}/searchproduct`,
                    method: "post",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: dataToSend
                })
                console.log("Response from Server-------------", data)
                if (data.success === true) {
                    setProductList(data.data)
                } else {
                    console.log("Search API is not running!!");
                }

            } catch (err) {
                console.log(err)
            }
        } else {
            if (current_sellerid === 1) {
                categoryListing()
            } else {
                setProductList([])
            }

        }
    }

    const inputeHandler = async (e) => {
        const optionvalue = e.target.value;
        setValuename(optionvalue);
        if (optionvalue) {
            try {
                const { seller_id } = searchData;

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });

                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerproduct`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        seller_id: { searchData },
                        name: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputeeHandler = async (e) => {
        const optionvalue = e.target.value;
        setValuename(optionvalue);
        if (optionvalue) {
            try {
                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });

                const { data } = await axios({
                    url: `${apiEndpoint}/filterproductsellerlogin`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        name: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            categoryListing();
        }
    };
    const inputpHandler = async (e) => {
        const optionvalue = e.target.value;
        setValueprice(optionvalue);
        if (optionvalue) {
            try {
                const { seller_id, min_price } = searchpData;
                setToggle(true);

                setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                console.log("line 239" + rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerproduct`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        seller_id: { searchData },
                        min_price: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputppHandler = async (e) => {
        const optionvalue = e.target.value;
        setValueprice(optionvalue);
        if (optionvalue) {
            try {
                setToggle(true);

                setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                console.log("line 239" + rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterproductsellerlogin`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        min_price: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            categoryListing();
        }
    };
    const inputsHandler = async (e) => {
        const optionvalue = e.target.value;
        setValuesku(optionvalue);
        if (optionvalue) {
            try {
                const { seller_id, sku } = searchsData;
                console.log(searchsData);
                setToggle(true);

                setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
                console.log("line 195" + rawsData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerproduct`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        seller_id: { searchData },
                        sku: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputssHandler = async (e) => {
        const optionvalue = e.target.value;
        setValuesku(optionvalue);
        if (optionvalue) {
            try {
                // console.log(searchsData);
                setToggle(true);

                setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
                console.log("line 195" + rawsData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterproductsellerlogin`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        sku: optionvalue,
                    },
                });

                if (data.status === 200) {
                    setProductList(data.response);
                }
            } catch (error) {
                // console.log("error", error);
            }
        } else {
            categoryListing();
        }
    };
    const inputHandlerforcategory = async (e) => {
        try {
            var optionvalue = e.target.value;
            const { data } = await axios({
                url: `${apiEndpoint}/filtercategory`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    category_id: optionvalue,
                },
            });
            console.log("product data", data.response);

            if (data.status === 200) {
                setProductList(data.response);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row, count) => {
                return <>{count + 1}</>;
            },
            disableFilters: true,
            sticky: "left",
        },
        {
            Header: "Image",
            Footer: "Image",
            accessor: (row) => {
                return (
                    <img
                        src={
                            row.image
                                ? row.image
                                : "http://cdn.onlinewebfonts.com/svg/img_546302.png"
                        }
                        alt="ok"
                        height={80}
                        width={80}
                    />
                );
            },
            sticky: "left",
        },
        {
            Header: "Name",
            Footer: "Name",
            accessor: "name",
            sticky: "left",
        },
        {
            Header: "Price",
            Footer: "Price",
            accessor: "mrp",
            sticky: "left",
        },
        {
            Header: "Sku",
            Footer: "Sku",
            accessor: "sku",
            sticky: "left",
        },
        {
            Header: "Status",
            Footer: "Status",
            accessor: (row) => {
                return <>{row.status ? "Active" : "Draft"}</>;
            },
            sticky: "left",
        },

        {
            Header: "Action",
            Footer: "Action",
            accessor: (row) => {
                return (
                    <>
                        {product.update ? (
                            <Link
                                to={`mastercatalog/${row.id}`}
                                className="btn-sm m-1 btn btn-success btn-circle"
                            >
                                <i className="fas fa-pen"></i>
                            </Link>
                        ) : null}
                        {product.delete ? (
                            <button
                                className="btn-sm m-1 btn btn-danger btn-circle"
                                onClick={() => deleteProduct(row.id)}
                            >
                                <i className="fas fa-trash"></i>
                            </button>
                        ) : null}


                        {/* code for quick update:- */}


                        <>
                            <button class="btn btn-secondary btn-circle" type="button"  >
                                <span onClick={(e) => {
                                    e.preventDefault()
                                    setopenInput(row.id)
                                    // console.log("from spab")
                                    maxPriceHandler(row.id)
                                }}>  QU</span>
                            </button>
                            <>
                                {openInput === row.id && (<div style={{ position: "relative" }}>
                                    <div class="pop-up">
                                        <p style={{ fontWeight: "600", fontSize: "12px", color: "black", paddingTop: "10px" }} className="p-1">Max Price</p>
                                        <div class="pop-up-content">
                                            <div class="form-group">
                                                <input
                                                    type="text"
                                                    class="form-control form-control-sm w-25"
                                                    value={quickUpdatedMaxPrice}
                                                    className="w-50"
                                                    onChange={(e) => {
                                                        setquickUpdatedMaxPrice(e.target.value)
                                                    }}
                                                />
                                                <button style={{ marginLeft: "2px", backgroundColor: "blue", padding: "2px 5px", borderRadius: "3px", border: "none" }} onClick={() => {
                                                    QuickUpdateHandler(row.id)
                                                }}> Update </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>)}
                            </>



                            {/* <button class="btn btn-secondary btn-circle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                <span onClick={(e) => {
                                    e.preventDefault()
                                    console.log("from spab")
                                    maxPriceHandler(row.id)
                                }}>  QU</span>
                            </button>

                            <div class="dropdown-menu m-1" aria-labelledby="dropdownMenuButton">
                                <form class="form-group d-flex align-items-center flex-column">
                                    <div class="form-group d-flex align-items-center flex-column">
                                        <label for="inputText">Update Price</label>
                                        <input type="text" class="form-control w-75" id="inputText" value={maxprice} />
                                    </div>
                                    <button type="submit" className="btn btn-primary ms-1" style={{ padding: "3px", fontSize: "13px" }} onClick={maxPriceUpdateHandler}>Update</button>
                                </form>
                            </div> */}

                        </>
                        {/* code for quick update:- */}

                    </>
                );
            },
            sticky: "left",
        },
    ];
    count++;
    useEffect(() => {
        if (current_sellerid == 1) {
            categoryListing();
        }
        //fetchSellerList();
        //fetchCatgoryList();
    }, []);

    function handleShipementModalClose() {
        setshipmentModalOpen(false)
    }

    function searchHandler(e) {
        setsearchValue({ ...searchValue, [e.target.name]: e.target.value });
        if (e.target.value == "") {
            categoryListing();
        }
    }

    function selectHandler(e) {

        setsearchValue({ ...searchValue, searchFilter: e.target.value });
        if (e.target.value !== "") {
            setsearchButtonDisable(false)
        } else {
            setsearchButtonDisable(true)
        }

    }

    async function searchResult() {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/searchproduct`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    type: searchValue.searchFilter,
                    name: searchValue.name,
                },
            });
            // console.log("data of master product list-->", data)
            if (data) {
                setProductList(data.data);
                // console.log(data.data)
            }
        } catch (error) { }
    }



    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary text-left">Master Product List</h6>
                                {(current_sellerid == 1) && <>
                                    {/* {seller?.read ? (
                                    <>
                                        <b>Filter By Seller: </b>
                                        <select
                                            name="seller_id"
                                            id=""
                                            onChange={(e) => inputHandler(e)}
                                        >
                                            <option value="">Select Seller</option>
                                            {sellerList.map((item) => (
                                                <option value={item?.seller_id}>{item?.name}</option>
                                            ))}
                                        </select>
                                    </>
                                ) : (
                                    ""
                                )} */}
                                </>}

                                {/*<b>Filter By Category: </b><select name="category" id="" onChange={(e:any)=>inputHandlerforcategory(e)} defaultValue="">
                                <option value="">Select Category</option>
                                {
                                    categoryList.map((item:any)=>(
                                        <option value={item?.id}>{item?.category_name}</option>
                                    ))
                                }
                            </select>*/}
                            <div className="col-md-12 d-flex mt-2 justify-content-between">
                                <div className="col-md-4 col-6 search mt-1 ">
                                    <div className="row searchDiv">
                                        <select
                                            name="searchFilter"
                                            id=""
                                            className="m-0"
                                            onChange={selectHandler}
                                            value={searchValue.searchFilter}
                                        >
                                            <option value="">Search By</option>
                                            <option value="Product">Product</option>
                                            <option value="Brand">Brand</option>
                                            <option value="Category">Category</option>
                                        </select>
                                        <input
                                            type="search"
                                            name="name"
                                            placeholder="Product, Category or Brand"
                                            className="mr-2 rounded-pill m-0"
                                            value={searchValue.name}
                                            onChange={searchHandler}
                                            style={{ fontSize: "14px", width: "70%" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <button className="btn btn-primary d-flex" onClick={searchResult}  disabled={searchButtonDisable}>Search</button>
                                </div>
                                <div className="d-flex justify-content-end align-items-center mb-2" style={{ gap: "20px" }}>
                                    <span className="text-center" style={{ background: "lightgrey", borderRadius: "12px", fontSize: "14px", width: "150px", color: "black", fontWeight: "600", height: "27px", cursor: "pointer", paddingTop: "3px" }}
                                        onClick={() => {
                                            setshipmentModalOpen(true)
                                        }}
                                    >Import Products</span>
                                </div>
                            </div>
                            </div>


                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="table-responsive">
                                    {/*} <table className="table table-bordered" id="dataTable" width="100%" >
                                        <thead>
                                            <tr>
                                                <th>Sn</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Sku</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                productList.map((item: any, index: any) => (
                                                    <tr key={item?.id}>
                                                        <td> {index + 1} </td>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <img src={item?.image ? item?.image : "http://cdn.onlinewebfonts.com/svg/img_546302.png"} alt="ok" height={80} width={80} />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td> {item?.name} </td>
                                                        <td> {item?.min_price} </td>
                                                        <td> {item?.sku}</td>
                                                        <td> {item?.status ? "Active" : "Draft"}</td>

                                                        <td>
                                                            <div className="row">
                                                                {
                                                                    product?.update ? (
                                                                        <div className="col-md-4">
                                                                            <Link to={`/product/${item?.id}`} className="btn btn-success btn-circle">
                                                                                <i className="fas fa-eye"></i>
                                                                            </Link>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                {
                                                                    product?.delete ? (
                                                                        <div className="col-md-4">
                                                                            <button className="btn btn-danger btn-circle"
                                                                                onClick={() => deleteProduct(item?.id)}
                                                                            >
                                                                                <i className="fas fa-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                    ) : null
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>*/}
                                    <div className="productListing_desktop">
                                        <Paginated data={productList} columns={COLUMNS} />
                                    </div>
                                    <div className="productListing_mobile">
                                        {/* <ProductTemplate data={productList} deleteProduct={deleteProduct} handle="mastercatalog" categoryListing={categoryListing}></ProductTemplate> */}
                                        <Pagination itemsPerPage={20} items={productList} deleteProduct={deleteProduct} handle="mastercatalog" categoryListing={categoryListing}></Pagination>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    <Modal
                        open={shipmentModalOpen}
                        // onClose={handleShipementModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={Sstyle} className="col-md-4 w-100 w-md-25">

                            <div className="modal-content ">
                                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                                    <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Import Using CSV File..</p>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="importingSection">
                                    <input type="file" id="inputFile" onChange={(e) => {
                                            setFile(e.target.files[0]);
                                        }} style={{ marginTop: "30px" }} />


                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" onClick={handleImport} > Submit </button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </>
            </ThemeMaker>
        </>
    );
};

export default ProductList;
