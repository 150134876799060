import React from 'react'
import { useEffect, useState } from 'react';
import "./order.css"
import ThemeMaker from '../../components/thememaker';
import axios from "axios"
import { config } from "../../utils/config"
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { baseUrlDefiner } from '../../utils/config';

const { apiEndpoint } = config;
export default function Seeorderdetails() {
  // link= seeorderdetails?ODERID=2023-05-03-607222
  // const [cancel_qutantity, setcancel_qutantity] = useState(0)
  const [reason_code, setreason_code] = useState(0)
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const HULID = urlParams.get('HULID');
  const ACCESSTOKEN = urlParams.get('ACCESSTOKEN');
  const order_id = urlParams.get('ODERID');
  console.log(HULID)
  console.log(ACCESSTOKEN)
  console.log(order_id)
  const [orderList, setorderList] = useState([])
  const history = useHistory()
  useEffect(() => {
    async function fetchOrder() {
      let apiEndpoint =  baseUrlDefiner();
      const { data } = await axios(`${apiEndpoint}/getorderdetailsoutside`, {
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          order_id,
        },
      })

      if (data.status == 200) {
        console.log("data from api---->", data)
        let details = (data.data[0].items)
        // console.log(JSON.parse(details))
        setorderList(data.products)
      }
    }

    setTimeout(() => {
      if (order_id) {
        fetchOrder()
      }
    }, 300)
  }, [])
  console.log("orderList", orderList)

  const cnacelReason = [{ value: "", name: "Select Reason" }, { value: "002", name: "One or more items in the order is not available" }, { value: "005", name: "Merchant rejected the order" }, { value: "008", name: "Order not ready for pickup" }, { value: "011", name: "Buyer not found and cannot be founded" }, { value: "013", name: "Buyer refused to accept delivery" }, { value: "014", name: "Address not found" }, { value: "015", name: "Buyer not available at the location" }, { value: "016", name: "Accident / strike / rain / vehicle issues" }, { value: "017", name: "Order delivery delayed or not possible" }, { value: "018", name: "Delivery pin code not serviceable" },
  { value: "019", name: "Pickup pincode not serviceable" }]

  {/* -------------------------------------------- code function for partial cancellation start ---------------------------------- */ }

  async function partialCancelHandler(item) {
    let apiEndpoint =  baseUrlDefiner();
    const { product_id, cancel_qty } = item
    console.log(item)
    const dataToSend = {
      order_id: order_id,
      order_status: "Created",
      product_id: [
        {
          item_id: product_id,
          cancel_quantity: cancel_qty,
          reason_code,
          order_status: "Created"
        }
      ]
    }
    // console.log("dataToSend", dataToSend)
    const { data } = await axios({
      url: `${apiEndpoint}/sellerordercancel`,
      method: "POST",
      headers: {
        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
      },
      data: dataToSend

    })

    if (data.status == 200) {
      toast.success("Updated Successfully !", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error Occured !", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }


  {/* -------------------------------------------- code function for partial cancellation end ---------------------------------- */ }


  function handleQuantityChange(index, value) {
    const newProducts = [...orderList]; // make a copy of the products array
    newProducts[index].cancel_qty = value; // modify the quantity of the copied item
    setorderList(newProducts); // update the state with the new products array
  }



  {/* -------------------------------------------- code function for redirecting to order details page start ---------------------------------- */ }

  async function sendToOrderPage() {
    let apiEndpoint =  baseUrlDefiner();
    const data = await axios({
      url: `${apiEndpoint}/authenticatejwt`,
      method: "POST",
      data: {
        "hulid": HULID,
        "accesstoken": ACCESSTOKEN,

      }
    })
    console.log("data---->", data)
    if (data.data.code == 200) {
      // console.log("working")
      history.push(`/orderCompleteDetail/${order_id}`)
    }

  }


  {/* -------------------------------------------- code function for redirecting to order details page end ---------------------------------- */ }

  return (

    <div className='d-flex flex-column align-items-center w-100' style={{ minHeight: "100vh", margin: "7px auto", background: "white", gap: "10px" }}>

      <h3 style={{ fontSize: "18px", fontWeight: '700', color: "black" }}>Partial Accept Order</h3>
      <section className='w-100' style={{ padding: "30px" }}>
        <h3 className='w-100' style={{ fontSize: "18px", fontWeight: '700', color: "black", textAlign: "start" }}>Order Id:- {order_id}</h3>
      </section>
      {
        orderList.map((item, index) => {
          return (
            <div className='d-flex flex-column justify-content-center orderdetails'>



              <section className='d-flex flex-column' style={{ gap: "3px" }}>
                <div>
                  <label htmlFor="">Product Name:</label>  <span style={{ fontWeight: "500" }}>{item.product_name}</span>
                </div>

                <div>
                  <label htmlFor="">Current Quantity:</label>  <input type="number" value={item.quantity} style={{ width: "30px" }} />
                </div>
                <div>
                  <label htmlFor="">Cancel Quantity:</label> <input type="number" style={{ width: "30px" }} value={item.cancel_qty} onChange={(e) => handleQuantityChange(index, e.target.value)} />
                </div>

              </section>

              <div>
                <label htmlFor="">Cancel Reason:</label>
                <select name="" id="" className='w-100' onChange={(e) => {
                  setreason_code(e.target.value)
                }}>
                  {
                    cnacelReason.map(item => {
                      return <option value={item.value}>{item.name}</option>
                    })
                  }
                </select>

                <div className='d-flex justify-content-end'>
                  {/* <label htmlFor="">Update</label> <input type="checkbox" name="" id="" style={{ width: "30px" }} /> */}
                  <button className='btn btn-secondary' style={{ background: "#0094ff" }} onClick={() => {
                    partialCancelHandler(item)
                  }}>Cancel Partially</button>
                </div>
              </div>
            </div>


          )
        })
      }

      <div>
        <p style={{ color: "black", textDecoration: "underline" }} onClick={() => {
          sendToOrderPage()
        }}> Go to Order Details Page  </p>
      </div>
    </div>
  )
}
