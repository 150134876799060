import React, { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { baseUrlDefiner } from "../../utils/config";
import { NodebaseUrlDefiner } from "../../utils/config";
import { tokenDecode } from "../../utils/helper";
import axios from "axios";
import { Paginated } from "../../components/Paginated";
import { config } from "../../utils/config";
import CircularProgress from '@mui/material/CircularProgress';


export default function Shippingchargelist() {

    const [shippingList, setshippingList] = useState([])
    const [listLoading, setlistLoading] = useState(false)

    async function Shippinglist() {
        let apiEndpoint = NodebaseUrlDefiner();
        setlistLoading(true)
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/admin/api/getshippingchar`,
                method: "GET",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
            });
            setshippingList(data?.data)
            console.log("data", data)
            setlistLoading(false)
        } catch (error) {
            console.log("error", error);
        }
    }
    async function deleteShipingList(id) {
        let apiEndpoint = NodebaseUrlDefiner();
        setlistLoading(true)
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/admin/api/delshippingchar/${id}`,
                method: "DELETE",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
            });
            toast.success("Charges Deleted Successfully")
            Shippinglist()
            setlistLoading(false)
        } catch (error) {
            console.log("error", error);
        }
    }
    useEffect(() => {
        Shippinglist()
    }, []);
    const COLUMNS = [
        {
            Header: "Serial No.",
            accessor: (row, count) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },

        {
            Header: "Max Weight",
            accessor: (a) => a.max_weight ? a.max_weight : '',
            sticky: "left"
        },
        {
            Header: "Min Weight",
            accessor: (a) => a.min_weight ? a.min_weight : '',
            sticky: "left"
        },
        {
            Header: "UOM",
            accessor: (a) => a.uom ? a.uom : '',
            sticky: "left"
        },
        {
            Header: "Price",
            accessor: (a) => a.price ? a.price : '',
            sticky: "left"
        },
        {
            Header: "Update",
            accessor: (row) => {
                return (
                    <Link to={{ pathname: '/addshippingcharge', state: { info: row } }}>
                        <button className="btn-outline-warning btn-circle">
                            <i className="fas fa-edit text-gray-900"></i>
                        </button>
                    </Link>
                );
            },
            sticky: "left"
        },
        {
            Header: "Delete",
            accessor: (row) => {
                return <>
                    <button className="btn-outline-danger btn-circle"
                    onClick={
                        () => {
                            deleteShipingList(row?.id)}
                        }
                    >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </button></>
            },
            sticky: "left"
        }
    ];


    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className='font-weight-bold text-primary'> Shipping Charges List</h6>
                                <Link to="/addshippingcharge">  <button className='btn btn-primary'>Add Shipping Charge</button> </Link>
                            </div>
                            <div className={`card-body p-0`} style={{ minHeight: "70vh" }}>
                                <div className="shipingListing_desktop">
                                    {!listLoading ? <Paginated data={shippingList} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ThemeMaker>

        </>
    )
}
