import React from "react";
import ThemeMaker from "../../components/thememaker";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CustomerOrder from "./customerOrder";
import CompleteOrder from "./completeOrder";
import CancelOrder from "./cancelOrderlist";

const index = () => {

    

  return (
    <>
      <div className="row m-0 w-100 mt-1">
        <div className="col-md-12">
            <div className="card shadow mb-4">
                <div className="card-header p-0">
                <Tabs>
                  <TabList className="d-flex justify-content-start">
                    <Tab style={{ fontSize:'14px'}}>Customer Orders</Tab>
                    <Tab style={{ fontSize:'14px'}}>Complete Orders</Tab>
                    <Tab style={{ fontSize:'14px'}}>Cancelled Orders</Tab>
                  </TabList>

                  <TabPanel>
                    <CustomerOrder/>
                  </TabPanel>
                  <TabPanel>
                    <CompleteOrder/>
                  </TabPanel>
                  <TabPanel>
                    <CancelOrder/>
                  </TabPanel>
                </Tabs>
                </div>
            </div>
        </div>
      </div>
        
        
    </>
  );
};

export default index;