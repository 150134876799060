/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { useState, useEffect, SetStateAction } from "react";
import Cookies from 'js-cookie';
import ThemeMaker from "../../components/thememaker";
import Styles from "./style.module.css";
import { config } from "../../utils/config";
import axios from "axios";
import { Paginated } from "../../components/Paginated";
import "../../index.css";
import moment from "moment";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { NodebaseUrlDefiner } from "../../utils/config";



const logRejection = () => {

    const { apiEndpoint, nodeapiEndpoint, baseNodeapiUrl } = config;
    const [toggleState, setToggleState] = useState(1);
    const [details, setDetails]: any = useState('');
    const [isDetailsLoading, setIsDetailsLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [errorList, setErrorList]: any = useState([]);
    const [manageFilter, setManageFilter]: any = useState([]);
    const [settlementAmount, setSettlementAmount]: any = useState('');
    const [setAmount, setSetAmount]: any = useState({ settlements: 0, inProcess: 0 });
    const [open, setOpen] = useState(false);
    const modelOpen = () => setOpen(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOption, setSelectedOption] = useState('');

    const handleClose = () => {
        setOpen(false);
    };
    const toggleTab = (e: any) => {
        setToggleState(e);
    };



    const handleInputChange = (e: any) => {
        setSearchQuery(e.target.value);
    };

    const Mstyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // FILTER
    const filterBySearch = (event: any) => {
        setSearchQuery(event.target.value)
        const querySearch = event.target.value.toLowerCase();
        const filteredResults = errorList.filter((item: any) => {
            return (
                item?.body?.context?.transaction_id?.toString()?.includes(querySearch) ||
                item?.body?.context?.domain?.toString()?.includes(querySearch) ||
                item?.body?.context?.city?.toString()?.includes(querySearch) ||
                item?.body?.context?.bap_id?.toString()?.includes(querySearch)
            );
        });
        setManageFilter(filteredResults);
    };
    // 
    const handleStartDateChange = (event: any) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event.target.value);
    };

    const getErrorData = async (data: any) => {
        try {
            const result = await data ? data : "";
            setDetails(result)
        } catch (error) {
            console.error("Error occurred:", error);
        }
    }

    const totalSettlementAmount = async () => {
        let apiEndpoint = NodebaseUrlDefiner();
        try {
            setIsDetailsLoading(true)
            const { data } = await axios({
                url: `${apiEndpoint}/v1/get_catalog_rejection`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    startDate: startDate,
                    endDate: endDate
                }
            });
            const hasData = data?.data && data?.data?.length > 0;
            const sortedData = hasData ? data.data.reduce((acc: any, item: any) => {
                const timestamp = new Date(item?.context?.timestamp);
                const index = acc.findIndex((existing: any) => new Date(existing?.context?.timestamp) < timestamp);
                index !== -1 ? acc.splice(index, 0, item) : acc.push(item);
                return acc;
            }, []) : [];
            setErrorList(sortedData);
            setManageFilter(sortedData);
            setIsDetailsLoading(false)
        } catch (error) {
            console.log("Something Went Wrong!", error)
            setIsDetailsLoading(false)
        }
    };
    // 
    const handleSelectChange = (e: any) => {
        setSelectedOption(e.target.value);
        setManageFilter(errorList)
        setSearchQuery('')
    };


    const calculateSellerAmount = (manageFilter: any) => {
        const { settlements, inProcess } = manageFilter?.reduce((accumulator: any, currentTransaction: any) => {
            const amount = parseFloat(currentTransaction.seller_amount);
            if (currentTransaction.settlement_status === 0) {
                accumulator.inProcess += amount;
            }
            if (currentTransaction.settlement_status === 1) {
                accumulator.settlements += amount;
            }
            return accumulator;
        }, { settlements: 0, inProcess: 0 });
        return { settlements, inProcess }
    };

    const exportToCSV = (orderList: any) => {
        const headers = [
            'S.No',
            'Transaction Id',
            'Buyer Id',
            'City Code',
            'Domain',
            'Error Massage',
            'Path',
            'Timestamp'
        ];
        const data = manageFilter.map((item: any, i: any) => {
            const errors = item?.body?.errors || [];
            const massages = errors.map((error: any) => error?.message).join(' | ');
            const paths = errors.map((error: any) => error?.path).join(' | ');

            return [
                i + 1,
                item?.body?.context?.transaction_id || '',
                item?.body?.context?.bap_id || '',
                item?.body?.context?.city || '',
                item?.body?.context?.domain || '',
                massages,
                paths,
                item?.body?.context?.timestamp || '',
            ].join(',');
        });
        const csvContent = [headers.join(','), ...data].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `orders_${new Date().getTime()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }


    const COLUMNS = [
        {
            Header: "Sn.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Transaction Id",
            accessor: (item: any) => (item?.body?.context?.transaction_id ? item?.body?.context?.transaction_id : ""),
            sticky: "left"
        },
        {
            Header: "Buyer Id",
            accessor: (item: any) => (item?.body?.context?.bap_id ? item?.body?.context?.bap_id : ""),
            sticky: "left"
        },
        {
            Header: "Domain",
            accessor: (item: any) => (item?.body?.context?.domain ? item?.body?.context?.domain : ""),
            sticky: "left"
        },
        {
            Header: "City Code",
            accessor: (item: any) => (item?.body?.context?.city ? item?.body?.context?.city : ""),
            sticky: "left"
        },
        {
            Header: "Timestamp",
            accessor: (item: any) => (item?.body?.context?.timestamp ? moment((item?.body?.context?.timestamp)).format("DD-MM-YYYY hh:mm:ss") : ""),
            sticky: "left"
        },

        {
            Header: "Error Description",
            accessor: (a: any) => {
                return <><button className="btn btn-success btn-circle"
                    onClick={() => { getErrorData(a?.body ? a?.body : ""); modelOpen() }}
                >
                    <i className="fas fa-pen"></i>
                </button></>
            },
            sticky: "left"
        },
    ];


    useEffect(() => {
        const result = calculateSellerAmount(manageFilter);
        setSetAmount(result);
    }, [manageFilter]);
    useEffect(() => {
        totalSettlementAmount();
    }, [startDate, endDate]);

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-2">
                    <div className="col-md-12">
                        <div className="shadow mb-4">
                            <div className="card-header">
                                <div className="row d-flex justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary border-bottom-danger">Catalog Rejection</h6>
                                    {toggleState === 1 ? (
                                        <div className="col-sm-6 col-6 col-md-6 d-flex justify-content-end align-items-end p-0">
                                            <button
                                                type="button"
                                                className="badge btn btn-outline-primary btnHeight"
                                                onClick={() => exportToCSV(manageFilter)}
                                                disabled={isDetailsLoading || manageFilter.length === 0}
                                            >
                                                {isDetailsLoading ? 'Loading...' : 'Download Details'}
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="row justify-content-between">
                                    <div className="col-6 col-md-4 col-sm-4">
                                        <label htmlFor="searchInput" className={``}>Search by:</label>
                                        <div className={` ${Styles?.search_input_container}`}>
                                            <input type="search" name="search" value={searchQuery} className={`${Styles?.search_input} bg-transparent form-control`} placeholder="Search query..." onChange={(event) => filterBySearch(event)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end p-0">
                                        <div className="col-6">
                                            <label htmlFor="start-date"> Start Date : </label>
                                            <input type="date" className="form-control" placeholder="Select a date" id=""
                                                value={startDate} onChange={(e: any) => handleStartDateChange(e)} />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="end-date"> End Date : </label>
                                            <input type="date" className="form-control" value={endDate} id="" onChange={(e: any) => handleEndDateChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className={`${Styles.content_tabs}`}>
                                    <div className={toggleState === 1 ? `${Styles.contentShow}  ${Styles.active_content}` : `${Styles.contentShow}`}>
                                        {/* <div className="row " style={{ gap: "10px" }}>
                                            <div className="col-12 col-md-4 ">
                                                <div className="card border-primary mb-3 bg-success ">
                                                    <div className="card-body text-light">
                                                        <small className="card-text">Total Amout Settlements</small>
                                                        <h5 className="card-title">₹ {isDetailsLoading ? <small>Loading</small> : parseFloat(setAmount?.settlements || 0)?.toFixed(2)} </h5>
                                                        <small className="card-text">Amount Credit Your Account</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="card border-secondary mb-3 bg-transparent ">
                                                    <div className="card-body text-warning">
                                                        <small className="card-text text-secondary">Amount in Process</small>
                                                        <h5 className="card-title">₹ {isDetailsLoading ? <small>Loading</small> : parseFloat(setAmount?.inProcess || 0)?.toFixed(2)}</h5>
                                                        <small className="card-text text-secondary">(Amount will be Credited in 2-5 days.)</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="table-responsive table-bordered">
                                            {!isDetailsLoading ?
                                                <Paginated data={manageFilter} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box sx={Mstyle} style={{maxHeight: "70%", overflow: "scroll"}}>
                            <div className="modal-header position-relative">
                                <p className="modal-title bg-gradient-light px-2 rounded" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}>
                                    Transaction ID - {details?.context?.transaction_id}
                                </p>
                                <p className="modal-title bg-info px-2 rounded text-white" style={{ fontSize: "14px", fontWeight: "500", color: "black", textTransform: "uppercase", }}>
                                    {details?.context?.city}
                                </p>
                                <button type="button" className="close position-absolute" style={{ right: "0", top: "0" }} onClick={() => handleClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {!isDetailsLoading ? <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    {details?.errors?.map((item: any) => (
                                        <><TableHead>
                                            <TableRow >
                                                <TableCell align="center" className="bg-light">Message Description</TableCell>
                                                <TableCell className="bg-light font-weight-bold" >
                                                    {item?.message}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="center" className="bg-light">Path</TableCell>
                                                <TableCell className="bg-light font-weight-bold" >
                                                    {item?.path}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        </>
                                    ))}
                                </Table>
                            </TableContainer> : null}
                            {
                                isDetailsLoading ? <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div> : null
                            }
                        </Box>
                    </Modal>
                </div>
            </ThemeMaker >
        </>
    )
}

export default logRejection;
