import React, { useState, useEffect } from 'react';
import ThemeMaker from '../../components/thememaker';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { config } from '../../utils/config';
import { toast } from 'react-toastify';
import { baseUrlDefiner } from '../../utils/config';
const Sstyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: "10px", bgcolor: 'background.paper', border: '1px solid #000', boxShadow: 24, p: 2,
};

const { apiEndpoint } = config;
export default function Socialtagging() {

    useEffect(() => {
        getDetails()
    }, [])

    async function getDetails() {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getDetails`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                // data: socialTagState
            });

            if (data.status === 200) {
                if (data.data.length > 0) {
                    setsocialTagState((prev) => {
                        return { ...prev, ...data.data[0], hasAgreed: true, startTagging: true, has_Certificate_check:data.data[0].has_certification }
                    })
                    setdataalreadypresent(true);
                    setSelectedCities(data.data[0]?.subtags?.split(","))
                }
            }

            console.log(data)


        } catch (error) { }
    }
    const [selectedCities, setSelectedCities] = useState([]);
    const [socialTagState, setsocialTagState] = useState({
        hasAgreed: false,
        startTagging: false,
        tag_description: "",
        isd2c_brand: "",
        produces_handloom: "",
        handloom_value: "",
        has_Certificate_check: false,
        certificate_link: "",
        allCities: ["SHG","FPO", "Food Processing Micro/Nano Enterprise", "Handicraft Micro/Nano Enterprise", "Handloom Micro/Nano Enterprise", "OFPO","Silkmark", "Craftmark", "GI", "ODOP", "Fair Trade", "Agmark/FPO Mark", "Handloom Mark", "Khadi India Mark", "Organic", "Woman Owned Business", "Tribal Owned Business", "Aspirational District/Block Based Business", "Eco-friendly/Sustainable", "Handmade"]
    })
    const [downloadCSVModalOpen, setdownloadCSVModalOpen] = useState(false);
    const [dataalreadypresent, setdataalreadypresent] = useState(false)
    console.log(socialTagState)

    function handleShipementModalClose() {
        setdownloadCSVModalOpen(false);
        setsocialTagState((prev)=>{
            return {...prev,has_Certificate_check:false , certificate_link: "" }
        })
        setSelectedCities([])
    }

    const handleAutocompleteChange = (event, newSelectedCities) => {
        setSelectedCities(newSelectedCities); // Update the selectedCities state
    };

    const submtHandler = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/addQuery`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: socialTagState
            });

            if (data.status === 200) {
                toast.success("Saved")
            } else {
                toast.error("Something went wrong")
            }


        } catch (error) {
            toast.error("Error from API")
        }
    };
    const certificateSubmitHandler = async () => {
        let apiEndpoint =  baseUrlDefiner();
        const {certificate_link, has_Certificate_check} = socialTagState
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/updateDetails`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {id: socialTagState?.id,certificate_link,has_certification:has_Certificate_check,subtags:selectedCities }
            });

            if (data.status === 200) {
                toast.success("Saved")
            } else {
                toast.error("Something went wrong")
            }


        } catch (error) {
            toast.error("Error from API")
        }
    };




    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1" style={{color: "black", fontSize: "15px", fontWeight: "500"}}>
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className='font-weight-bold text-primary'>Social Tagging</h6>

                            </div>
                            <div className={`card-body p-3`} style={{ minHeight: "70vh" }}>
                                <div>
                                    <p>ONDC has rolled out tagging and credentialing for social sector sellers on the network to improve seller health monitoring for this category of sellers, as well as enhance visibility and marketability on buyer applications</p>
                                    <p className='d-flex' style={{ gap: "5px" }}>
                                        <input type="checkbox" className='form-check' name='hasAgreed' checked={socialTagState?.hasAgreed} onChange={(e) => {
                                            setsocialTagState((prev) => {
                                                return { ...prev, [e.target.name]: e.target.checked }
                                            })
                                        }} />
                                        Before proceeding, please agree to the terms of ONDC for tagging and credentialing social sector sellers on the network.
                                    </p>

                                    {
                                        (socialTagState?.hasAgreed && !socialTagState?.startTagging) && <button className='btn btn-primary' name='startTagging' onClick={(e) => {
                                            setsocialTagState((prev) => {
                                                return { ...prev, [e.target.name]: !socialTagState?.startTagging }
                                            })
                                        }} >Start </button>
                                    }


                                </div>

                                <div>
                                    {
                                        socialTagState?.startTagging && <>

                                            <div className='mt-3'>

                                                <div className='form-group '>
                                                    <label htmlFor="">Seller is a </label>
                                                    <select name="" id="" className='form-control' value={socialTagState.tag_description} onChange={(e) => {
                                                        setsocialTagState((prev) => {
                                                            return { ...prev, tag_description: e.target.value }
                                                        })
                                                    }}>
                                                        <option value="" disabled selected>Select</option>
                                                        <option value="SHG">Self Help Group</option>
                                                        <option value="FPO">Farmer Producer Organisation</option>
                                                        <option value="0FPO">Micro/Nano enterprise producing
                                                            processed food products</option>
                                                        <option value="00">None of the above</option>
                                                    </select>

                                                </div>


                                                <>
                                                    {
                                                        socialTagState?.tag_description === "00" ? <>
                                                            <div className='form-group '>
                                                                <p>Seller is a D2C brand that  procures  product/raw material from farmers, SHGs,  FPOs, OFPOs,  artisans, handloom  producers,  handicraft producers</p>
                                                                <select name="" id="" className='form-control'
                                                                    value={socialTagState.isd2c_brand}
                                                                    onChange={(e) => {
                                                                        setsocialTagState((prev) => {
                                                                            return { ...prev, isd2c_brand: e.target.value }
                                                                        })
                                                                    }}>
                                                                    <option value="" disabled selected>Select</option>
                                                                    <option value={true}>Yes</option>
                                                                    <option value={false}>No</option>

                                                                </select>

                                                            </div>

                                                            {
                                                                (socialTagState?.isd2c_brand === "false" || socialTagState?.isd2c_brand === false) ? <>
                                                                    <div className='form-group '>
                                                                        <p>Seller Produces  Handloom/Handicra
                                                                            ft/food product</p>
                                                                        <select name="" id="" className='form-control' value={socialTagState.handloom_value}
                                                                            onChange={(e) => {
                                                                                setsocialTagState((prev) => {
                                                                                    return { ...prev, produces_handloom: e.target.value }
                                                                                })
                                                                            }}>
                                                                            <option value="" disabled selected>Select</option>
                                                                            <option value={true}>Yes</option>
                                                                            <option value={false}>No</option>

                                                                        </select>

                                                                    </div>
                                                                </> : null
                                                            }
                                                            {
                                                                socialTagState?.produces_handloom === "false" ? <>
                                                                    <div className='form-group '>
                                                                        <p>Sorry! You are not eligilble for social tagging.</p>


                                                                    </div>
                                                                </> : null
                                                            }
                                                            {
                                                                (socialTagState?.produces_handloom == "true" || socialTagState?.produces_handloom == true) ? <>
                                                                    <div className='form-group '>
                                                                        <label>Please select from the following</label>
                                                                        <select name="" id="" className='form-control'
                                                                            value={socialTagState.handloom_value}
                                                                            onChange={(e) => {
                                                                                setsocialTagState((prev) => {
                                                                                    return { ...prev, handloom_value: e.target.value }
                                                                                })
                                                                            }}>
                                                                            <option value="" disabled selected>Select</option>
                                                                            <option value="Has a turnover under 1 CR per Annum">Has a turnover under 1 CR per
                                                                                Annum</option>
                                                                            <option value="Is a non-profit organisation">Is a non-profit organisation</option>
                                                                            <option value="Is a social enterprise—working  towards environmental impact or  social welfare through their production model">Is a social enterprise—working  towards environmental impact or  social welfare through their production model</option>
                                                                            <option value="Is part of a craft cluster">Is part of a craft cluster</option>
                                                                            <option value="Is a Tribal Owned Business">Is a Tribal Owned Business</option>
                                                                            <option value="Is based in an Aspirational District/Block">Is based in an Aspirational
                                                                                District/Block</option>
                                                                        </select>


                                                                    </div>
                                                                </> : null
                                                            }


                                                        </> : null
                                                    }

                                                    {
                                                        socialTagState?.handloom_value !== "" || socialTagState?.isd2c_brand === "true" || (socialTagState?.tag_description !== "00" && socialTagState?.tag_description !== "") ? <button className='btn btn-primary' onClick={() => {

                                                            submtHandler();

                                                        }} disabled={socialTagState?.id}>Tag as social selector</button> : null
                                                    }

                                                    {socialTagState?.id && <button className='btn btn-secondary ml-2' onClick={() => {
                                                        setdownloadCSVModalOpen(true);
                                                    }}>Go for Subtagging</button>}
                                                </>




                                            </div>

                                        </>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </ThemeMaker>


            <Modal
                open={downloadCSVModalOpen}
                onClose={handleShipementModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={Sstyle} className="col-md-4 ">

                    <div className="modal-content " >
                        <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                            <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>
                                Mandatory Attributes
                            </p>

                        </div>
                        <div className="modal-body">
                            <div>
                                <div className='form-group d-flex align-items-center' style={{ gap: "4px" }} >
                                    <label htmlFor="" className='mb-0'>Do you have certification?</label>
                                    <input type="checkbox" className='form-check'  checked={socialTagState.has_Certificate_check}  onChange={(e) => {
                                        setsocialTagState((prev) => { return { ...prev, has_Certificate_check: e.target.checked } })
                                    }} />
                                </div>
                                {socialTagState?.has_Certificate_check ?
                                    <div>
                                        <input type="text" className="form-control" placeholder='Please paste the url of the certificate'
                                            onChange={(e) => {
                                                setsocialTagState((prev) => {
                                                    return { ...prev, certificate_link: e.target.value }
                                                })
                                            }}
                                            value={socialTagState?.certificate_link}
                                        />

                                        <div className='mt-2'>

                                            <Autocomplete
                                                multiple
                                                id="checkboxes-tags-demo"
                                                options={socialTagState.allCities}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option}
                                                onChange={handleAutocompleteChange} // Handle changes and update state
                                                value={selectedCities} // Set the selected values
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                                style={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Sub Tags" placeholder="Search" />
                                                )}
                                            />

                                            <div className='mt-2 d-flex justify-content-end align-items-center'>
                                                <button className='btn btn-success' onClick={certificateSubmitHandler}>Save </button>
                                            </div>


                                        </div>
                                    </div>
                                    : null
                                }

                                <div>

                                </div>

                            </div>
                        </div>

                    </div>
                </Box>
            </Modal>



        </>
    )
}
