import React from 'react'
import ThemeMaker from '../../components/thememaker'
import { Link } from "react-router-dom"
export default function Pendingpage() {
    let z = localStorage.getItem("showthank");
    console.log(z)
    if (localStorage.getItem("showthank")) {
        localStorage.removeItem("showthank");
    }
    return (
        <div>
            <ThemeMaker>
                <div className='w-100 d-flex justify-content-start text-dark mt-2 flex-column align-items-center' style={{minHeight:"80vh"}}>
                    Approval Status is Pending!
                </div>
            </ThemeMaker>

        </div>
    )
}
