import React, { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup"
import { toast } from 'react-toastify';
import { baseUrlDefiner } from "../../utils/config";
import { NodebaseUrlDefiner } from "../../utils/config";
import { tokenDecode } from "../../utils/helper";
import axios from "axios";
import { config } from "../../utils/config";
import { useLocation, useHistory } from 'react-router-dom';
const { apiEndpoint } = config;

export default function Addshipping() {
    const location = useLocation();
    const history = useHistory();
    const { info } = location?.state || {};
    const decoded = tokenDecode();
    const [data, setdata] = useState('');

    // const [initialValues, setInitialValues] = useState(() => ({
    //     price: info?.price || "",
    //     uom: info?.uom || "",
    //     maxWeight: info?.max_weight || "",
    //     minWeight: info?.min_weight || ""
    // }));


    const initialValues = {
        price: info?.price || '',
        uom: info?.uom || '',
        maxWeight: info?.max_weight || '',
        minWeight: info?.min_weight || ''
    };

    const validationSchema = Yup.object({
        price: Yup.number().typeError("Price should be a number").required("Price is required!"),
        uom: Yup.string().required("UOM is required!"),
        maxWeight: Yup.number().typeError("Max Weight should be a number").required("Max Weight is required!"),
        minWeight: Yup.number().typeError("Min Weight should be a number").required("Min Weight is required!")
    });

    async function onSubmitHandler(values, { resetForm }) {
        let apiEndpoint = NodebaseUrlDefiner();
        const datas = {
            price: values?.price,
            uom: values?.uom,
            max_weight: values?.maxWeight,
            min_weight: values?.minWeight,
            ...(info && { id: info?.id })
        };
        try {
            debugger
            const endpoint = info
                ? `${apiEndpoint}/admin/api/updateshippingchar`
                : `${apiEndpoint}/admin/api/createshippingchar`;

            const { data } = await axios({
                url: endpoint,
                method: 'POST',
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: datas
            });

            const successMessage = info ? 'Charges Updated Successfully' : 'Charges Added Successfully';
            toast.success(successMessage);
            resetForm({ values: initialValues });
        } catch (error) {
            toast.error('An error occurred');
            console.error(error);
        }
    }

    useEffect(() => {
        if (!info) {
            history.replace('/addshippingcharge');
        }
    }, [info, history]);

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className="font-weight-bold text-primary">Add Shipping Charges</h6>
                                <Link to="/shippingchargelist">
                                    <button className="btn btn-primary">Shipping Charge List</button>
                                </Link>
                            </div>
                            <div className="card-body p-3" style={{ minHeight: '70vh' }}>
                                <Formik
                                    validationSchema={validationSchema}
                                    initialValues={initialValues}
                                    onSubmit={onSubmitHandler}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="genericlabel">Min Weight</label>
                                                        <Field className="form-control" type="text" name="minWeight" />
                                                        <ErrorMessage name="minWeight" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="genericlabel">Max Weight</label>
                                                        <Field className="form-control" type="text" name="maxWeight" />
                                                        <ErrorMessage name="maxWeight" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="genericlabel">UOM (Unit of measurement)</label>
                                                        <Field name="uom" as="select" className="form-control">
                                                            <option value="" label="Select category" />
                                                            <option value="unit" label="Unit" />
                                                            <option value="dozen" label="Dozen" />
                                                            <option value="gram" label="Gram" />
                                                            <option value="kilogram" label="Kilogram" />
                                                            <option value="tonne" label="Ton" />
                                                            <option value="litre" label="Litre" />
                                                            <option value="millilitre" label="Millilitre" />
                                                        </Field>
                                                        <ErrorMessage name="uom" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="genericlabel">Price</label>
                                                        <Field className="form-control" type="number" name="price" />
                                                        <ErrorMessage name="price" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                    Submit
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}
