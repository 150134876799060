import React from 'react'
import searchicon from "../../icons/searchicon.png"
import searchicon_black from "../../icons/searchicon_black.png"
import styles from "./Search&Options.module.css"
export default function InputTemplate({ placeholder }) {
  return (
    <div className={styles.inputMain}>
      <img src={searchicon_black} alt="" /> <input type="text" placeholder={placeholder} />
    </div>
  )
}
