
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { tokenDecode } from "../../../utils/helper";
import shikharapplogo from "../../../icons/logoimg.png"
import styles from "./navbar.module.css"
import bars from "../../../icons/MenuBar.png"
import shop from "../../../icons/shop.png"
import PublicIcon from '@mui/icons-material/Public';
import Cookies from 'js-cookie';
import { useEffect, useState, useCallback, useRef } from "react";
import { config } from "../../../utils/config";
import { toast } from 'react-toastify';
import axios from "axios";
import { baseUrlDefiner } from "../../../utils/config";

const Navbar = (props) => {
    const history = useHistory();
    const [logoutExecuted, setLogoutExecuted] = useState(false);
    const logoutTimerRef = useRef(null);
    const { apiEndpoint, awsKeyId, awsSecretKey } = config;
    const token = sessionStorage.getItem('us_st_d');
    const decoded = tokenDecode(token);
    const { data } = decoded;
    const { user } = data;
    let current_sellerid = data.user.seller_id;
    // const logouthandler = useCallback(async () => {
    //     let apiEndpoint = baseUrlDefiner();
    //     try {
    //         const response = await axios.post(`${apiEndpoint}/logout`, null, {
    //             headers: {
    //                 "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
    //             },
    //         });
    //         if (response.status) {
    //             sessionStorage.removeItem('us_st_d');
    //             history.push('/');
    //         } else {
    //             console.error('Logout unsuccessful');
    //         }
    //     } catch (error) {
    //         console.error('Error during logout:', error);
    //     }
    // }, [apiEndpoint, history]);

    function logouthandler(){
        sessionStorage.removeItem('us_st_d');
        history.push('/');
    }

    const handleLogout = useCallback(() => {
        if (!logoutExecuted) {
            setLogoutExecuted(true);
            logouthandler();
            history.push('/');
        }
    }, [history, logoutExecuted, logouthandler]);

    useEffect(() => {
        const decoded = token && tokenDecode(token);
        const expirationTime = decoded?.exp * 1000;
        // const expirationDateTime = new Date(decoded.exp * 1000);
        // console.log('Token expired on:', expirationDateTime);
        if (expirationTime && expirationTime > Date.now()) {
            const delay = expirationTime - Date.now();
            logoutTimerRef.current = setTimeout(handleLogout, delay);
        } else {
            logouthandler();
        }
        return () => {
            clearTimeout(logoutTimerRef.current);
        };
    }, [token, handleLogout, logouthandler, history]);

    return (
        <>
            <nav className={`navbar navbar-expand navbar-light  topbar  static-top shadow ${styles.mobile_navbar}`}>
                <button id="sidebarToggleTop" className={`btn btn-link rounded-circle mr-3 ${styles.burgerButton}`} onClick={() => props.Showmenu()}>
                    <img src={bars} alt="" style={{ height: "70%" }} />
                </button>
                <ul className="navbar-nav  ">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <li className={`nav-item dropdown no-arrow ${styles.plus}`} >
                        <Link to="#" className="nav-link dropdown-toggle" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{user?.name}</span>
                            <img className={`img-profile rounded-circle ${styles.dropdownimg}`}
                                src={`/img/undraw_profile.svg`} alt="im here" />
                            <div className={styles.searchOpener}>
                                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            </div>
                        </Link>
                        <div className={`dropdown-menu  shadow animated--grow-in p-0  ${styles.dropDownPosition}`}
                            aria-labelledby="userDropdown">
                            <div className={styles.doropdownnavmenu}>
                                {/* <Link to="/profile" >
                                    <a href="replace" className="dropdown-item" >
                                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                        <span> Profile  </span>
                                    </a>
                                </Link> */}

                                {/* <div className="dropdown-divider"></div> */}
                                <Link to="/sellerdetail?tab=shopdetails"  >
                                    <a href="replace" className={`dropdown-item ${styles.bottomPadding}`} data-toggle="modal" data-target="#logoutModal" >
                                        <i className="fas fa-home fa-sm fa-fw mr-2 text-gray-400"></i>
                                        {/* <img src={shop} alt="" className={`${styles.shopDetailsImg}`} /> */}
                                        {/* <i className="fas fa-shop"></i> */}
                                        <span> Shop details </span>
                                    </a>
                                </Link>
                                <div className="dropdown-divider"></div>
                                <Link to="/serviceability"  >
                                    <a href="replace" className={`dropdown-item ${styles.bottomPadding}`} data-toggle="modal" data-target="#logoutModal" >
                                        <PublicIcon sx={{ fontSize: 15, color: "lightgray" }} className={`mr-2 ${styles.servicabilityIcon}`}></PublicIcon>
                                        {/* <img src={shop} alt="" className={`${styles.shopDetailsImg}`} /> */}
                                        {/* <i className="fas fa-shop"></i> */}
                                        <span> Serviceability </span>
                                    </a>
                                </Link>

                                <div className="dropdown-divider"></div>
                                <Link to="/updatepassword"  >
                                    <a href="replace" className={`dropdown-item ${styles.bottomPadding}`} data-toggle="modal" data-target="#logoutModal">
                                        <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                        <span> Update Password </span>
                                    </a>
                                </Link>

                                {current_sellerid == 1 ? <> <div className="dropdown-divider"></div>
                                    <Link to="/adminsettings"  >
                                        <a href="replace" className={`dropdown-item ${styles.bottomPadding}`} data-toggle="modal" data-target="#logoutModal">
                                            <i className="fas fa-tools fa-sm fa-fw mr-2 text-gray-400"></i>
                                            <span> Configurations </span>
                                        </a>
                                    </Link> </> : null}

                                {/* code as per new figma start */}

                                {/* <div className="dropdown-divider"></div> */}
                                {/* <Link to="#" onClick={logouthandler} > */}
                                {/* <Link to="/notifications" className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                                    <i className="far fa-bell"></i>
                                    <span>Notifications</span>
                                </Link> */}
                                {/* </Link> */}
                                <div className="dropdown-divider"></div>
                                <Link to="#" onClick={logouthandler} >
                                    <a href="replace" className={`dropdown-item ${styles.bottomPadding}`} data-toggle="modal" data-target="#logoutModal">
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        <span> Logout </span>
                                    </a>
                                </Link>
                                {/* code as per new figma end */}
                            </div>
                        </div>
                    </li>
                    {/* } */}

                </ul>

            </nav>
        </>
    )
}

export default Navbar;
