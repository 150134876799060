import React from 'react'
import { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import ThemeMaker from '../../components/thememaker';
// import { toast } from "react-toastify";
import axios from 'axios';
import thanks from "../../icons/thanks.gif"
import styles from "./seller_register.module.css"
import { config } from "../../utils/config";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { baseUrlDefiner } from '../../utils/config';
const { apiEndpoint } = config
export default function Registerseller() {

    const history = useHistory()

    const rawData = {
        name: '',
        email: '',
        company_name: '',
        contact_no: '',
        gst_no: '',
        pan_no: '',
        provider_id: ""

    }
    const rawErrorData = {
        name: false,
        email: false,
        company_name: false,
        contact_no: false,
        gst_no: false,
        pan_no: false,
        provider_id: false

    }

    const [sellerData, setSellerData] = useState(rawData);
    const [Buttonon, setButtonon] = useState(false);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const hulid = urlParams.get('hulid');
        let rawToken = sessionStorage.getItem("us_st_d");
        var decode = jwt_decode(rawToken);
        // console.log("decode-->", decode)
        let decodedUserdata = decode.data;
        setSellerData({ ...sellerData, name: decodedUserdata.user.name, company_name: decodedUserdata.seller_data.company_name, contact_no: decodedUserdata.seller_data.contact_no, email: decodedUserdata.user.email, provider_id: hulid })
        // getId();
    }, [])


    const [sellerDataError, setSellerDataError] = useState(rawErrorData);
    const [registerationStatus, setregisterationStatus] = useState(false);
    const [sellerId, setsellerId] = useState("")
    const inputHandler = (e) => {
        setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }

    const errorHandler = (e) => {
        if (e.target.value) {
            setSellerDataError({ ...sellerDataError, [e.target.name]: "valid" });
        } else {
            setSellerDataError({ ...sellerDataError, [e.target.name]: true });
        }
    }

    const newUserCreatehandler = async () => {
        // console.log("sellerData => ", sellerData);
        // console.log("sellerDataError => ", sellerDataError);
        const { name,
            email,
            company_name,
            contact_no,
            gst_no,
            pan_no,
            provider_id
        } = sellerData;
        if (name && email && company_name && contact_no && gst_no && pan_no && provider_id) {
            // dispatch(registerSeller(sellerData));
            // console.log("data to send>>>>>>>>>>>>", sellerData)
            try {
                const { data } = await axios({
                    url: `${apiEndpoint}/sellerregisters`,
                    method: "POST",
                    data: sellerData

                })
                // console.log("data from server---", data)
                if (data.status === 200) {

                    setsellerId(data.response.seller_id)

                    toast.success("Details submitted Successfully", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    localStorage.setItem("showthank", "yes")
                    setTimeout(() => {
                        history.push('/sellerdetail?tab=shopdetails')
                    }, 2500)
                    // setregisterationStatus(true)


                }

            } catch (err) {
                console.log(err)
            }
        } else {
            toast.error("Please fill all the fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function getId() {
        let apiEndpoint =  baseUrlDefiner();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const hulid = urlParams.get('hulid');
        // console.log("hulid from URL---->", hulid)
        setSellerData({ ...sellerData, provider_id: hulid })
        // const { data } = await axios({
        //     url: "https://shikahrapp.hulsecure.in/public/index.php/api/checkheader",
        //     method: "POST",
        //     data: { hulid: hulid }
        // })

        // if (data) {
        //     console.log("data--->", data)
        // }

    }
    return (
        <>
            <ThemeMaker>

                <div className="row m-0 w-100 mt-1">
                    <div className="col-md-12" style={{ background: "white" }}>
                        {/* {!registerationStatus ?  */}
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-bg-gradient-dark">
                                    Complete Your Profile
                                </h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >
                                                HUL ID
                                                <span className="text-danger" > * </span>
                                                {
                                                    sellerDataError?.provider_id !== "valid" && sellerDataError?.provider_id !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="provider_id" placeholder="HUL ID"
                                                value={sellerData.provider_id}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >
                                                Name
                                                <span className="text-danger" > * </span>
                                                {
                                                    sellerDataError?.name !== "valid" && sellerDataError?.name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="name" placeholder="Name"
                                                value={sellerData.name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Company name<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.company_name !== "valid" && sellerDataError?.company_name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="company_name" placeholder="Company name"
                                                value={sellerData.company_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Email address<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.email !== "valid" && sellerDataError?.email !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" name="email" className="form-control" placeholder="Email"
                                                value={sellerData.email}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Contact No.<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.contact_no !== "valid" && sellerDataError?.contact_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="number" name="contact_no" className="form-control" placeholder="Contact number"
                                                value={sellerData.contact_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >GST<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.gst_no !== "valid" && sellerDataError?.gst_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="gst_no" placeholder="GST number"
                                                maxlength="15"
                                                value={sellerData.gst_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >PAN Number <span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.pan_no !== "valid" && sellerDataError?.pan_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="pan_no" placeholder="PAN number"
                                                maxlength="10"
                                                value={sellerData.pan_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>

                                        <div className='d-flex justify-content-start align-items-center'>
                                            <input type="checkbox" className='mx-2' onChange={() => {
                                                setButtonon(!Buttonon)
                                            }} /> <label htmlFor="" className='m-0'>Agree to submit the details.</label>
                                        </div>
                                    </div>




                                    {/* <Link to="/onboarding?name=adnan"> to to onboarding page</Link> */}




                                    <div className="col-md-12">

                                        <button type="submit" className="btn btn-dark mt-2" style={{ background: "#0094ff" }}
                                            onClick={newUserCreatehandler} disabled={Buttonon ? "" : "disabled"}>
                                            Submit
                                        </button>
                                    </div>
                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                </div>

                            </div>
                        </div>
                        {/* : <>
                            <div className={styles.thanks_container}>
                                <div>
                                    <img src={thanks} alt="" />
                                </div>

                                <div className={styles.thanks_statement}>
                                    <p>Hi {sellerData.name}!</p>
                                    <p className='m-1'>You have successfully registered to Shikhar App.</p>
                                </div>
                                <div className={`{styles.thanks_statement_redirection} d-flex justify-content-center`}>
                                  
                                </div>

                                <div className={`${styles.thanks_button} d-flex justify-content-center`}>
                                    <Link to={`/sellerdetail/?tab=shopdetails`}>    <button className='btn btn-success'>Shop Details Page</button> </Link>
                                </div>


                            </div>
                        </> */}
                    </div>
                </div>

            </ThemeMaker>
        </>
    )
}
