import axios from 'axios';
import React from 'react'
import { useEffect, useState } from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import { useHistory } from 'react-router-dom';
import { config } from "../../utils/config";
import { baseUrlDefiner } from '../../utils/config';
const { apiEndpoint } = config;
export default function Acceptedorderdetails() {

    // link= http://localhost:3001/acceptedorderdetail?HULID=DEMO-14&ACCESSTOKEN=eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjoiI3tjaGVja3BhcmFtfSIsImV4cCI6MTU5NDY0MDY3NzA2fQ.0QjbFM17KftX_7ZO1dBX3rwNH3GpwFeszK4SHzl53Gw&ODERID=12345678
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const HULID = urlParams.get('HULID');
    const ACCESSTOKEN = urlParams.get('ACCESSTOKEN');
    const ODERID = urlParams.get('ODERID');
    console.log(HULID)
    console.log(ACCESSTOKEN)
    console.log(ODERID)
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            senddata()
        }, 200)


    })
    async function senddata() {
        let apiEndpoint =  baseUrlDefiner();
        const data = await axios({
            url: `${apiEndpoint}/authenticatejwt`,
            method: "POST",
            data: {
                "hulid": HULID,
                "accesstoken": ACCESSTOKEN,
                "order_id": ODERID,
                "order_state": "Accepted",
                "fulfillment_state": "Pending",
                "tracking_url": ""
            }
        })
        console.log("data---->", data)
        if (data.data.code == 200) {
            // console.log("working")
            history.push(`/orderCompleteDetail/${ODERID}`)
        }

    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <BeatLoader color="#0094ff" />
        </div>
    )
}
