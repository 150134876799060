import { createSlice, createAsyncThunk, PayloadAction, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrlDefiner } from "../../../utils/config";
import Cookies from 'js-cookie';
import { config } from "../../../utils/config";
import { toast } from "react-toastify";
const { apiEndpoint } = config;
interface CoreState {
    loading?: boolean,
    data?: any,
    error?: any
};

const initialState: CoreState = {
    loading: false,
    data: '',
    error: ''
}

export const checkLogin = createAsyncThunk('login/checkLogin', async (cred: any) => {
    let apiEndpoint =  baseUrlDefiner();
    try {
        
        const { data } = await axios({
            url: `${apiEndpoint}/login`,
            method: "POST",
            data: cred
        });
        const { token, status } = data;
        if (status === 200) {
            sessionStorage.setItem("us_st_d", token)
            return token;
        } else {
            toast.error("Wrong credentials !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }
    } catch (error: any) {
        console.log("errrr-------", error);
        return false;
    }
})

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(checkLogin.pending, (state, action) => {
            state.loading = true;
            state.data = '';
            state.error = '';

        })
        builder.addCase(checkLogin.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = '';
        })
        builder.addCase(checkLogin.rejected, (state, action) => {
            state.loading = false;
            state.data = '';
            state.error = 'error';
        })
    }
})


export default loginSlice.reducer;
