import { useState, useEffect, useRef, useCallback } from "react";
//import "datatables.net-dt/js/dataTables.dataTables"
//import "datatables.net-dt/css/jquery.dataTables.min.css"
import { Link } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import Cookies from 'js-cookie';
import { config } from "../../utils/config";
import { toast } from "react-toastify";
import { AnyAaaaRecord } from "dns";
import { BasicTable } from '../../components/Table';
import { Paginated } from "../../components/Paginated";
import Sellerpagination from "../../components/Paginated/Sellerpagination";
import styles from "./sellerList.module.css"
import '../../components/table.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import SellerTemplate from "../../components/SellerViewTemplate/SellerTemplate";
import { baseUrlDefiner } from "../../utils/config";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
const { apiEndpoint } = config;


const A = () => <span className="badge badge-primary">New Request</span>
const B = () => <span className="badge badge-success">Approved</span>
const C = () => <span className="badge badge-danger">Blocked</span>
const valueToApp: any = {
    "0": <A />,
    "1": <B />,
    "2": <C />
}
var count = 1;

var $ = require('jquery');
const SellerList = () => {
    const decoded: any = tokenDecode();
    const { data } = decoded;
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    const [sellerList, setSellerList] = useState([]);
    const [allseller, setAllseller] = useState(true)
    const [sellerPending, setSellerPending] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
    const [showImportButton, setShowImportButton] = useState(false);
    const [sellerFile, setSellerFile]: any = useState(null);
    const [Loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);


    const fetchSellerList = async () => {
        let apiEndpoint = baseUrlDefiner();
        setIsLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
            });
            console.log("all data", data);
            if (data.status === 200) {
                setIsLoading(false);
                setSellerList(data.data);
                /*setTimeout(() => {
                    $('#dataTable').DataTable({
                        "pageLength": 50,
                        "bDestroy": true
                    });
                }, 500);*/

            }
        } catch (error) {
            console.log("error", error);

            setIsLoading(false);
        }
    }
    function handleShipementModalClose() {
        setshipmentModalOpen(false)
    }
    // const handleImportSeller = async () => {
    //     // console.log(file)
    //     Papa.parse(sellerFile, {
    //         header: true,
    //         dynamicTyping: true,
    //         complete: ({ data }: any) => {
    //             console.log("FileData", data)
    //             let abc = data.filter((item: any) => item?.name !== null)
    //             let sellerList = abc.map((item: any) => ({
    //                 name: item?.name,
    //                 company_name: item?.company_name,
    //                 email: item?.email,
    //                 ondc_domain: item?.ondc_domain,
    //                 contact_no: item?.contact_no,
    //                 password: item?.password,
    //                 personal_address: item?.personal_address,
    //                 bank_account: item?.bank_account,
    //                 beneficiary_name: item?.beneficiary_name,
    //                 ifsc_code: item?.ifsc_code,
    //                  image: item?.image,
    //                 short_description: item?.short_description,
    //                 long_description: item?.long_description
    //             }))
    //             sendDataToDb(sellerList)
    //         },
    //     });
    // };
    // async function sendDataToDb(data: any) {
    //     let apiEndpoint = baseUrlDefiner();
    //     try {
    //         setLoading(true);
    //         console.log(data)
    //         let result = await axios({
    //             url: `${apiEndpoint}/impSellerCsv`,
    //             method: 'POST',
    //             headers: {
    //                 "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
    //             },
    //             data,
    //         })
    //         setLoading(false);
    //         if (result.status === 200) {
    //             toast.success("Products added successfully !", {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //             setSellerFile(null)
    //             // categoryListing()
    //             // document.getElementById('inputFile').value = ''
    //             const inputFile = document.getElementById('inputFile');
    //             if (inputFile !== null && inputFile !== undefined) {
    //                 //   inputFile?.value = '';
    //             }
    //         } else {
    //             toast.error("403 error !", {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         }

    //     } catch (error) {
    //         setLoading(false);
    //         toast.error("403 error !", {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     }
    // }

    const handleImport = async () => {
        try {
            if (file) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    let dataToSend = json.filter((item: any) => item.name !== null)
                    sendDataToDb(dataToSend);

                };
                reader.readAsArrayBuffer(file);
            }

        } catch (error) {
            console.error('Error handling import:', error);
        }
    };

    async function sendDataToDb(jsonData: any) {
        let apiEndpoint = baseUrlDefiner();

        try {

            let result = await axios({
                url: `${apiEndpoint}/seller_register`,
                method: 'POST',
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,

                },
                data: jsonData,

            })

            if (result.data.status == 200) {
                toast.success(`Seller Registered successfully!`);
                setFile(null);
                let element: any = document.getElementById('inputFile')
                element.value = ''
            } else {
                console.log(result?.data?.error[0]?.message)
                toast.error(`${result?.data?.error[0]?.message} for ${result?.data?.error[0]?.email}`);
                console.log(result?.data?.error[0]?.message)
                toast.error(`${result?.data?.error[0]?.message} for ${result?.data?.error[0]?.email}`);
            }
        } catch (error: any) {
            console.log(error?.response?.data?.error[0]?.message)
            toast.error(`${error?.response?.data?.error[0]?.message} for ${error?.response?.data?.error[0]?.email}`);
        }


    }
    const Sstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: "10px",
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 2,
    };
    const inputHandler = async (e: any) => {
        let apiEndpoint = baseUrlDefiner();
        try {
            var optionvalue = e.target.value;
            console.log(optionvalue);
            if (optionvalue === "pendingseller") {
                const { data } = await axios({
                    url: `${apiEndpoint}/pendingsellerlist`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                });
                console.log("pending data", data.data);
                setIsLoading(false);
                if (data.status === 200) {
                    setSellerList(data.data);
                }
            }
            else if (optionvalue === "approveseller") {
                const { data } = await axios({
                    url: `${apiEndpoint}/approvesellerlist`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                });
                console.log("pending data", data.data);
                setIsLoading(false);
                if (data.status === 200) {
                    setSellerList(data.data);


                }
            }
            else {
                const { data } = await axios({
                    url: `${apiEndpoint}/getsellerlist`,
                    method: "GET",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                });
                console.log("allseller data", data.data);
                setIsLoading(false);
                if (data.status === 200) {
                    setSellerList(data.data);


                }
            }

        } catch (error) {
            console.log("error", error);

            setIsLoading(false);
        }
    };
    const sellerDelete = async (id: any) => {
        let apiEndpoint = baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/sellerdelete`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    seller_id: id
                }
            });
            //   console.log("sellerList", sellerList);

            const updateSellerList = sellerList.filter((item: any) => item.id !== id);
            //setSellerList(updateSellerList);
            //  console.log("updateSellerList", updateSellerList);
            //fetchSellerList()
            if (data.status === 200) {
                toast.success("Seller Removed Successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            window.location.reload();
            //console.log("delet data data", data);
            // if (data.status === 200) {
            //     toast.success("Seller removed successfully !", {
            //         position: "top-right",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //     });

            // } else {
            //     toast.error("403 error !", {
            //         position: "top-right",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //     });
            // }
        } catch (error) {
            toast.error("403 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Company Name",
            Footer: "Company Name",
            accessor: "company_name",
            sticky: "left"
        },
        {
            Header: "Email",
            Footer: "Email",
            accessor: "email",
            sticky: "left"
        },
        {
            Header: "Contact",
            Footer: "Contact",
            accessor: "contact_no",
            sticky: "left"
        },
        {
            Header: "Provider Id",
            Footer: "Provider Id",
            accessor: "provider_id",
            sticky: "left"
        },
        {
            Header: "Status",
            Footer: "Status",
            accessor: (row: any) => {
                return <>{valueToApp[row.approve_status]}
                </>
            },
            sticky: "left"
        },
        {
            Header: "Action",
            Footer: "Action",
            accessor: (row: any) => {
                return (
                    <>
                        {seller.update ? <Link to={`../../updateseller/${row.id}`} className="btn btn-success btn-circle"> <i className="fas fa-eye"></i></Link> : null}{seller.delete ? <button className="btn btn-danger btn-circle  d-none" onClick={() => sellerDelete(row.id)}><i className="fas fa-trash"></i></button> : null}

                        <span className="badge badge-secondary" style={{ cursor: "pointer" }}
                            onClick={() => {

                                const fullUrl = window.location.host;
                                let redirecturl = "";

                                switch (fullUrl) {
                                    case "multipleselleradmin.thesellerapp.com":
                                        redirecturl = "https://multipleselleradmin.thesellerapp.com/";
                                        break;
                                    case "preprodadmin.xpressbaazaar.com":
                                        redirecturl = "https://preprodadmin.xpressbaazaar.com/";
                                        break;
                                    case "multisellersadmin.thesellerapp.com":
                                        redirecturl = "https://multisellersadmin.thesellerapp.com/";
                                        break;

                                    default:
                                        redirecturl = "https://preprodadmin.xpressbaazaar.com/";
                                        break;
                                }
                                let url = `${redirecturl}?navigateToSeller=true&token=${sessionStorage.getItem("us_st_d")}&provider_id=${row.provider_id}&email=${row.email}`
                                window.open(url, "_blank", "width=800,height=800,noopener,noreferrer");

                            }}
                        >Log In</span>

                    </>
                )
            },
            sticky: "left"
        },

    ];
    count++
    const [searchHandler, setsearchHandler] = useState("");
    useEffect(() => {



        const endpoint = baseUrlDefiner();
        // console.log("endpoint",endpoint)
        // if (endpoint) {
        //     setShowImportButton(true);
        // } 
        // setShowImportButton(false);
        setShowImportButton(endpoint === "https://multisellerpreprod.hulsecure.in/public/index.php/api");

        if (searchHandler === "") {
            fetchSellerList();
            exportAllseller()
        } else {
            let timer = setTimeout(() => {
                let allProductTypes = [...sellerList]
                let searchItem = searchHandler.toLowerCase().trim()
                let filteredItems = allProductTypes?.filter((item: any) => item?.company_name?.toLowerCase()?.trim()?.includes(searchItem) || item?.provider_id?.toLowerCase()?.trim()?.includes(searchItem))
                setSellerList([...filteredItems])
            }, 1000)

            return () => clearTimeout(timer);

        }
    }, [searchHandler])

    const [exportData, setExportData] = useState([])

    async function exportAllseller() {
        let apiEndpoint = baseUrlDefiner();
        try {

            const { data } = await axios({
                // url: `${apiEndpoint}/sellerexport`,
                url: `https://multisellerpreprod.hulsecure.in/public/index.php/api/sellerexport`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },

            })
            console.log("export data-------", data)
            setExportData(data?.response)

        } catch (err) {
            console.log("Server Error----", err)
            toast.error("Server Error in fetching export data!!")
        }
    }

    const exportToExcel = (data: { key: string, value: any }[]) => {
        const headers = Object.keys(data[0]); // Use the keys of the first object as headers

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data, { header: headers });

        XLSX.utils.book_append_sheet(wb, ws, 'Orders');

        const fileName = `allsellers_${new Date().getTime()}.xlsx`;

        XLSX.writeFile(wb, fileName);
    };

    function getSampleExcelFile() {
        const data = [
            {
                company_name: "Company A",
                name: "John Doe",
                email: "john.doe@example.com",
                contact_no: "1234567890",
                customer_care_contact: "9876543210",
                pan_name: "Company A Pvt. Ltd.",
                pan_no: "ABCDE1234F",
                gst_no: "GSTIN1234567",
                personal_address: "123, Main Street",
                street: "Main Street",
                locality: "Downtown",
                city: "Cityville",
                state: "State A",
                area_code: "12345",
                seller_city_code: "",
                fssai_licence: "FSSAI1234567890",
                latitude: "12.3456",
                longitude: "78.9101",
                bank_account: "12345678901234",
                ifsc_code: "ABCD0123456",
                beneficiary_name: "John Doe",
                bank_name: "Bank of Example",
                branch_name: "Main Branch",
                radius: "10",
                ondc_commission: "5",
                short_description: "Short description of products",
                description: "Detailed description of products",
                opening_days: "1,2,3,4,5,6,7",
                closing_days: "",
                opening_time: "00:00",
                closing_time: "23:59",
                returnable: false,
                replaceable: false,
                cancelable: false,
                return_window: "P2D",
                seller_pickup_return: false,
                time_to_ship: "P2D",
                address_proof_type: "Aadhar Card",
                gst_registered_address: "Same as personal address",
                cod: false,
                delivery_fee: "0",
                packing_fee: "0",
                holidays: "",
                service_city_code: "",
                ondc_domain: "",
                service_type: "",
                service_range: "",
                service_city: ""
            }
        ];

        const headers = Object.keys(data[0]); // Use the keys of the first object as headers

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data, { header: headers });

        XLSX.utils.book_append_sheet(wb, ws, 'Orders');

        const fileName = `Sample_Seller_${new Date().getTime()}.xlsx`;

        XLSX.writeFile(wb, fileName);

    }

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-dark text-left d-flex justify-content-between">
                                    <span>Seller list</span>
                                    <div className="d-flex  " style={{ gap: "10px" }}>
                                        <input type="text w-25" className="form-control" placeholder="Search Seller" onChange={(e) => {
                                            setsearchHandler(e.target.value)
                                        }} />
                                        {showImportButton && <button style={{ width: "200px" }} disabled={exportData.length === 0 ? true : false} className="btn btn-primary" onClick={() => setTimeout(() => {
                                            exportToExcel(exportData)
                                        }, 2000)}>Export Sellers</button>
                                        }
                                    </div>
                                </h6>
                                <div className="d-none"> <b>Filter By: </b><select name="status" id="" onChange={(e: any) => inputHandler(e)}>
                                    <option value="allseller" selected >All</option>
                                    <option value="pendingseller">Pending Seller</option>
                                    <option value="approveseller">Approved Seller</option>
                                </select> </div>

                            </div>

                            <div className={`card-body p-0 ${styles.cardbody}`}>
                                <div className="table-responsive">

                                    <div className="d-flex justify-content-end m-2">
                                        <span className="text-center" style={{ background: "lightgrey", borderRadius: "12px", fontSize: "14px", width: "150px", color: "black", fontWeight: "600", height: "27px", cursor: "pointer", paddingTop: "3px" }}
                                            onClick={() => {
                                                setshipmentModalOpen(true)
                                            }}
                                        >Import Sellers</span>
                                    </div>

                                    <div className={styles.sellerListing_desktop}>
                                        <Paginated data={sellerList} columns={COLUMNS} />
                                    </div>
                                    <div className={styles.sellerListing_mobile}>
                                        {/* <SellerTemplate data={sellerList} sellerDelete={sellerDelete}></SellerTemplate> */}
                                        <Sellerpagination itemsPerPage={20} items={sellerList} sellerDelete={sellerDelete} paginationFor="seller"></Sellerpagination>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    <Modal
                        open={shipmentModalOpen}
                        // onClose={handleShipementModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={Sstyle} className="col-md-4 w-100 w-md-25">

                            <div className="modal-content ">
                                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                                    <p className="modal-title genericlabel d-flex justify-content-between w-100" style={{ letterSpacing: "1px" }}> <span>Import File..</span>  <a onClick={() => { getSampleExcelFile() }} style={{cursor: "pointer"}} >Download Sample Excel</a> </p>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="importingSection">
                                        <input type="file" id="inputFile" onChange={(e) => {
                                            const file: any = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
                                            setFile(file);
                                        }}
                                            style={{ marginTop: "30px" }}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" onClick={handleImport} > Submit </button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </>
            </ThemeMaker>
        </>
    )
}

export default SellerList;
