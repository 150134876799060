import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../../utils/config";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';

export default function Xtraasettings() {
    const { baseNodeapiUrl, apiEndpoint } = config
    const [formData, setformData] = useState({
        name: "",
        password: "",
        email: ""
    })


    function onChangeHandler(e) {
        setformData((prev) => {
            return {
                ...prev, [e.target.name]: e.target.value
            }
        })
    }

    async function submitHandler() {
        let apiEndpoint =  baseUrlDefiner();
        let { name, email, password } = formData
        if (name === "" || email === "" || password === "") {
            toast.error("Please Fill All Details")
            return
        }

        try {
            const { data } = await axios({
                url: `${apiEndpoint}/smtpconfigdetails`,
                method: "POST",
                data: {
                   ...formData
                },
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                }
            })

            toast.success("Data Saved Successfully!")
        } catch (err) {
            toast.error("Something Went Wrong! Please try again later.")
        }
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };
    //   https://multisellerpreprod.hulsecure.in/public/index.php/api/smtpconfigdetails
    // {
    //     "name":"ideamaster",
    //     "email":"samsshopping99@gmail.com",
    //     "password":"hwzx dfdu jfhl chwn"
    // }

    return (
        <ThemeMaker>
            <div className="row w-100 m-0 mt-1">
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary text-left d-flex justify-content-between align-items-center"> Configurations </h6>
                        </div>
                        <div className="card-body" style={{ minHeight: "70vh" }}>
                            <h6 className="m-0 font-weight-bold text-primary text-left d-flex justify-content-between align-items-center"> Email Configuration</h6>
                            <div className="row mt-4">


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="genericlabel">
                                             Name
                                            <span className="text-danger" > * </span>

                                        </label>
                                        <input type="text" name="name" className="form-control" placeholder="Enter Name" value={formData.name} onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="genericlabel">
                                             Email
                                            <span className="text-danger" > * </span>

                                        </label>
                                        <input type="text" name="email" className="form-control" placeholder="Enter Email" value={formData.email} onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="genericlabel">
                                            Password
                                            <span className="text-danger" > * </span>

                                        </label>
                                        <input type="password" name="password" className="form-control" placeholder="Enter Password" value={formData.password}  onChange={onChangeHandler} />
                                    </div>

                                </div>
                            </div>

                            <div className="row px-2 ">

                                <button className="btn btn-primary" onClick={submitHandler}>Submit</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </ThemeMaker>
    )
}
