import { useState, useEffect, useCallback } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import Cookies from 'js-cookie';
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { BasicTable } from "../../components/Table";
import OrderTemplate from "../../components/OrderTemplate/OrderTemplate";
import "../NewmasterPage/Master.css";
// import "../../components/table.css";
import InputTemplate from "../../components/Search&Options/InputTemplate";
// import styles from "../transaction/transaction.module.css";
import searchicon_black from "../../icons/searchicon_black.png";
import style from "../../components/Search&Options/Search&Options.module.css";
//import { toast } from "react-toastify";

import eye from "../../icons/eye.png";
import mailicon from "../../icons/mailicon.png";
import phoneicon from "../../icons/phoneicon.png";
import styles2 from "../../components/OrderTemplate/OrderTemplate.module.css";
import styles from "./returnorders.module.css";
import { baseUrlDefiner } from "../../utils/config";

const { apiEndpoint } = config;

var $ = require("jquery");

const badgeMaker: any = {
  Created: (order_state: any) => (
    <span className="badge badge-primary">{order_state}</span>
  ),
  Shipped: (order_state: any) => (
    <span className="badge badge-warning">{order_state}</span>
  ),
  Delivered: (order_state: any) => (
    <span className="badge badge-success">{order_state}</span>
  ),
  Canceled: (order_state: any) => (
    <span className="badge badge-danger">{order_state}</span>
  ),
};

const ReturnOrders = () => {
  const decoded: any = tokenDecode();
  const [orderList, setOrderList] = useState([]);
  const [sorderList, setSorderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sellerList, setSellerList] = useState([]);
  const [updateOrder_, setUpdateOrder_]: any = useState({
    order_id: "",
    tracking_url: "",
    order_state: "",
  });
  const [orderDetail, setorderDetail]: any = useState([]);
  const [_orderDetail, _setorderDetail]: any = useState({
    billing_address: "",
    contact_number: "",
    created_at: "",
    email: "",
    id: "",
    name: "",
    order_id: "",
    order_state: "",
    provider_id: "",
    shipping_address: "",
    status: "",
    total_price: 0,
  });
  const rawData = {
    provider_id: "",
  };

  const [toggle, setToggle] = useState(false);

  const [searchData, setSearchData]: any = useState(rawData);
  const [valuename, setValuename] = useState("");
  const [valueid, setValueid] = useState("");
  const [valueemail, setValueemail] = useState("");
  const [valuecontact, setValuecontact] = useState("");
  const [valuestatus, setValuestatus] = useState("");
  const [valueamount, setValueamount] = useState("");
  const [valuedate, setValuedate] = useState("");

  const [returnorderlist, setreturnorderlist]: any = useState();
  const [orderpopup, setorderpopup] = useState(false);
  const [orderdetails, setorderdetails] = useState([]);
  const [status, setstatus] = useState("");

  const { data } = decoded;
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;
  var count = 1;

  var myHeaders = new Headers();
  myHeaders.append(
    "x-custom-authorization",
    `Bearer ${sessionStorage.getItem("us_st_d")}`
  );

  var raw = "";

  var requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const returnorder = () => {
    let apiEndpoint =  baseUrlDefiner();
    fetch(`${apiEndpoint}/sellerordercancellist`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          console.log("response.status 797", response.status);
          return response.json();
        }
      })
      .then((result) => {
        console.log("response.status 801", result);
        let data = result.data;
        if (result.status === 200) {
          setreturnorderlist(data);
        }
        console.log("====================================");
        console.log("data", data);
        console.log("====================================");
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    returnorder();
  }, []);

  const vieworderdetails = async (order_id: any, item_id: any) => {
    console.log("====================================");
    console.log("order_id 734", order_id);
    console.log("product_id 734", item_id);
    console.log("====================================");
    const rowdata: any = { order_id: order_id, product_id: item_id };
    console.log("rowdata", rowdata);
    setorderpopup(true);
    let apiEndpoint =  baseUrlDefiner();
    try {

      const response = await axios({
        method: "post",
        url: `${apiEndpoint}/getorderlist`,
        headers: { "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}` },
        data: rowdata,
      });
      // console.log("response 777",response)
      setorderdetails(response.data.data);
    } catch (error) {
      console.error(" error 784", error);
    }
  };




  const orderStatusUpdate = async (orderId: any, itemId: any) => {
    let apiEndpoint =  baseUrlDefiner();

    console.log("orderStatusUpdate 160", orderId);
    console.log("orderStatusUpdate 161", itemId);
    // console.log("orderStatusUpdate 162", status);
    let statusData = {
      context: {
        action: "unsolicated_on_update"
      },
      order_id: orderId,
      item_id: itemId,
      order_status: status,
    };
    const id = toast.loading("Please wait...");
    
    try {
      const response = await axios({
        method: "post",
        url: `https://shikhar-preprod.herokuapp.com/v1/unsolicated_update`,
        headers: { "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}` },
        data: statusData,
      })
        .then(function (response) {
          console.log("response 173", response);
          toast.update(id, {
            render: "Status Updating",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        })
        .catch(function (error) {
          console.log("error 176", error);
          toast.update(id, {
            render: "something worng happned",
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
        })
        .finally(function () {
          console.log("response   // always executed 179");
        });
    } catch (error) {
      console.error(" error 784", error);
    }
  };

  const changeReturnStatus = (item: any, selectedStatus: any) => {
    console.log(
      "changeReturnStatus item: 763 ",
      item,
      "changeReturnStatus: selectedStatus 765",
      selectedStatus
    );
    item.status = selectedStatus;
    setstatus(selectedStatus);
  };

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-2">
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              <div className="card-header p-1">
                <h6 className="m-3 font-weight-bold text-primary text-left">
                  Return Orders list
                </h6>
              </div>

              <div className="card-body p-1" style={{ minHeight: "45vh" }}>
                <div className="table-responsive">
                  <div className="productListing_mobile">
                    {returnorderlist &&
                      returnorderlist.map((item: any, index: any) => {
                        return (
                          <div
                            className={`d-flex justify-content-between w-100 ${styles2.OrderTemplatemain}`}
                            key={item?.id}
                          >
                            <div className={`${styles2.OrderTemplateImage}`}>
                              <span>0{index + 1}</span>
                            </div>
                            <div className={`${styles2.OrderTemplateDesc}`}>
                              <div>
                                <p
                                  className="m-0 mb-1"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {item?.order_id}{" "}
                                </p>
                              </div>
                              <div>
                                <p className="m-0">
                                  {" "}
                                  <span style={{ fontSize: "13px" }}>
                                    Product Id:
                                  </span>{" "}
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    {" "}
                                    {item?.item_id}{" "}
                                  </span>
                                </p>
                              </div>
                              <div className="">
                                <p className="m-0">
                                  {" "}
                                  <span>Cancel Qty :</span>
                                  <span> {item?.cancel_qty} </span>
                                </p>
                              </div>
                              <div>
                                <p className="m-0">
                                  {" "}
                                  {/* <img src={mailicon} alt="" />{" "} */}
                                  <span style={{ fontSize: "13px" }}>
                                    Order Status:
                                  </span>
                                  <span style={{ fontSize: "13px" }}>
                                    {item?.order_status}
                                  </span>
                                </p>
                                <br />
                                <p className="m-0">
                                  {" "}
                                  <span style={{ fontSize: "13px" }}>
                                    {" "}
                                    Order Action:
                                  </span>
                                  <span style={{ fontSize: "13px" }}>
                                    {" "}
                                    {item?.action}{" "}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className="m-0" style={{ fontSize: "13px" }}>
                                  {" "}
                                  <span>Created At:</span>
                                  <span> {item?.created_at} </span>
                                </p>
                              </div>
                            </div>

                            <div className={`${styles2.OrderTemplateedit}`}>
                              {/* {item.status == "NOT-PAID" && (
                                <p className="m-0">COD</p>
                              )} */}

                              <p className="m-0">
                                &#8377; {item?.return_price}
                              </p>
                              <Link to={`../../orderCompleteDetail/${item?.order_id}`}>
                                {" "}
                                <img
                                  src={eye}
                                  alt=""
                                  onClick={() => {
                                    // getTransaction(item.order_id)
                                  }}
                                />{" "}
                              </Link>

                              <button
                                type="button"
                                className="border-0 mt-2"
                                onClick={() => vieworderdetails(item.order_id, item.item_id)}
                              >
                                <i className="fas fa-edit"></i>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="productListing_desktop">
                    <table>
                      <thead>
                        <tr role="row">
                          <th role="columnheader">Sn No.</th>
                          <th role="columnheader">Order Id</th>
                          <th role="columnheader">Item Id</th>
                          <th role="columnheader">Order Action</th>
                          <th role="columnheader">Return Price</th>
                          <th role="columnheader">Return Qty</th>
                          <th role="columnheader">fulfillment_type</th>
                          <th role="columnheader">Order Status</th>
                          <th role="columnheader">Created At</th>
                          <th role="columnheader">Updated At</th>
                          <th role="columnheader">Action</th>
                        </tr>
                      </thead>

                      <tbody role="rowgroup">
                        {returnorderlist &&
                          returnorderlist.map((item: any, index: any) => {
                            return (
                              <tr role="row">
                                <td role="cell">{index + 1}</td>
                                <td role="cell">{item?.order_id}</td>
                                <td role="cell">{item?.item_id}</td>
                                <td role="cell">{item?.action}</td>
                                <td role="cell">{item?.return_price}</td>
                                <td role="cell">{item?.return_qty}</td>
                                <td role="cell">{item?.fulfillment_type}</td>
                                <td role="cell">{item.order_status}</td>
                                <td role="cell">{item?.created_at}</td>
                                <td role="cell">{item?.updated_at}</td>
                                <td role="cell">
                                  <a
                                    className="btn btn-success m-2"
                                    href={`/orderCompleteDetail/${item?.order_id}`}
                                  >
                                    View
                                  </a>
                                  {/* <a
                                    className="btn btn-success btn-circle"
                                    href={`/orderdetails/${item?.order_id}`}
                                  >
                                    <i className="fas fa-pen"></i>
                                  </a> */}
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger m-2"
                                    onClick={() =>
                                      vieworderdetails(item.order_id, item.item_id)
                                    }
                                  >
                                    Update
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {orderpopup ? (
          <>
            <div
              onClick={() => setorderpopup(false)}
              className={styles.overlayordermodal}
            ></div>
            <div
              className={`shadow border rounded p-3 ${styles.ordermodalmain}`}
            >
              <div className="d-inline-flex justify-content-between align-items-center w-100">
                <h4>Order Details</h4>
                <button
                  className="btn btn-outline-dark mr-4 mb-2"
                  onClick={() => setorderpopup(false)}
                >
                  Close
                </button>
              </div>
              <div>
                <table className="table-responsive table">
                  <thead>
                    <tr role="row" className="text-capitalize text-nowrap overflow-hidden">
                      <th>Sn No.</th>
                      <th>Order Id</th>
                      <th>provider_id</th>
                      <th>product_id</th>
                      <th>product_name</th>
                      <th>price</th>
                      <th>quantity</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>fulfillment_state</th>
                      <th>tracking_url</th>
                      <th>tax</th>
                      <th>status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderdetails &&
                      orderdetails.map((items: any, index: any) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{items?.order_id}</td>
                            <td>{items?.provider_id}</td>
                            <td>{items.product_id}</td>
                            <td>{items?.product_name}</td>
                            <td>{items?.price}</td>
                            <td>{items?.quantity}</td>
                            <td>{items?.created_at}</td>
                            <td>{items?.updated_at}</td>
                            <td>{items?.fulfillment_state}</td>
                            <td>{items?.tracking_url}</td>
                            <td>{items?.tax}</td>
                            <td>{items?.status}</td>
                            <td>
                              <select
                                className="btn btn-outline-dark"
                                // value={items.fulfillment_state}
                                onChange={(e) =>
                                  changeReturnStatus(items, e.target.value)
                                }
                              >
                                <option value="Return_Initiated">
                                  Return_Initiated
                                </option>
                                <option value="Return_Approved">
                                  Return_Approved
                                </option>
                                <option value="Return_Rejected">
                                  Return_Rejected
                                </option>
                                <option value="Return_Picked">
                                  Return_Picked
                                </option>
                                <option value="Return_Delivered">
                                  Return_Delivered
                                </option>
                                <option value="Liquidated">Liquidated</option>
                                <option value="Cancelled">Cancelled</option>
                              </select>
                              <p className="d-none">{`You selected : ${status}`}</p>

                              <button
                                className="btn btn-outline-primary"
                                onClick={() =>
                                  orderStatusUpdate(
                                    items?.order_id,
                                    items?.product_id
                                  )
                                }
                              >
                                Update
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : null}
      </ThemeMaker>
    </>
  );
};

export default ReturnOrders;
