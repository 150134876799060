import piexif from 'piexifjs';
import { errorEmptyAlert } from '../../utils/helper';

export const removeExifData = async (imageFile) => {
    // Load the image data as a data URL
    const dataUrl = await readFileAsDataURL(imageFile);
    console.log('imageFile', imageFile);
    // Check if the image is in JPEG format
    if (imageFile.type === 'image/jpeg') {
        // Remove EXIF data from the data URL
        const newDataUrl = piexif.remove(dataUrl);

        // Convert the new data URL back to a Blob
        const byteString = atob(newDataUrl.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }

        return new Blob([arrayBuffer], { type: imageFile.type });
    } else {
        console.error('Unsupported image format. EXIF data removal is only supported for JPEG images.');
        // errorEmptyAlert('Unsupported image format. Please upload a JPEG image');
        return imageFile;
    }
};


const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.target.result);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};