
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import SellerTemplate from '../SellerViewTemplate/SellerTemplate';
import UserTemplate from '../UserTemplate/UserTemplate';
import "./pagination.css"
export default function Sellerpagination({ items, sellerDelete, itemsPerPage, paginationFor }) {

    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            {
                paginationFor == "seller" ? <SellerTemplate data={currentItems} sellerDelete={sellerDelete}  ></SellerTemplate> : paginationFor == "user" ? <UserTemplate userlist={currentItems} userDelete={sellerDelete}  ></UserTemplate> : null
            }

            <div className='pagination_main'>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    activeLinkClassName="current_active"

                />

            </div>
        </>
    );
}