import React from 'react'
import Notification from '../../components/Notification/Notification'
import ThemeMaker from '../../components/thememaker'
export default function index() {
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1" >
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex justify-content-between" >
                            <h6 className="m-0 font-weight-bold text-primary text-left">Notifications</h6>
                            </div>
                            <div className="card-header py-3 d-flex flex-column " style={{minHeight: "80vh", gap: "4px"}}>
                                <Notification></Notification>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>

        </>
    )
}
