
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";


const LoginPageIsLogedIn = ({ children }: any) => {
    // console.log("from logged in component")
    const history = useHistory();
    const token:any = localStorage.getItem("isLogedIn");
    try {
    //    console.log(token);
        var decoded = jwt_decode(token);
     //   console.log(decoded);
        if (decoded) {
            console.log('if block');
            history.push("/");
        } else {
            return children;
        }
    } catch (error) {
        return children;
    }
}

export default LoginPageIsLogedIn;