/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { useTable, usePagination } from "react-table";
import "./table.css";

export const Paginated = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="my-3 d-flex flex-column justify-content-center align-items-center ">
        <div className="d-flex" style={{ fontSize: "12px"}}>
          <button onClick={() => gotoPage(0)} className="page_link_item" disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} className="page_link_item" disabled={!canPreviousPage}>
            Previous
          </button>{" "}
          <div className="d-flex" style={{ fontSize: "12px" }}>
          <span className="page_link_item">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="page_link_item">
            | Go to page:{" "}
            <input 
              type="number" className=""
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              style={{ width: "50px" }}
            />
          </span>{" "}
        </div>
          <button onClick={() => nextPage()} className="page_link_item" disabled={!canNextPage}>
            Next
          </button>{" "}
          <button onClick={() => gotoPage(pageCount - 1)} className="page_link_item" disabled={!canNextPage}>
            {">>"}
          </button>{" "}
          <select value={pageSize} className="page_link_item" onChange={(e) => setPageSize(Number(e.target.value))}>
          {[10, 25, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        </div>
        
      </div>
    </>
  );
};
