import React, { useState, useEffect } from 'react'
import ThemeMaker from '../thememaker'
import styles from "./Discount.module.css"
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from "axios"
import { tokenDecode } from '../../utils/helper';
import { config } from "../../utils/config"
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { baseUrlDefiner } from "../../utils/config";
export default function OrderAmountDiscount() {
    const { apiEndpoint, baseNodeapiUrl } = config
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get('type');
    const [iopen, setiopen] = React.useState(false);
    const [igopen, setigopen] = React.useState(false);

    const handleimageClose = () => setiopen(false);
    const handleCGClose = () => setigopen(false);
    const handleImageOpen = () => setiopen(true);
    const handleCGOpen = () => setigopen(true);
    const [searchInput, setsearchInput] = useState("")
    const [filterHelper, setfilterHelper] = useState("")
    const decoded = tokenDecode();
    let current_sellerid = decoded.data.user.seller_id;
    useEffect(() => {
        getproductlist()
    }, [])

    useEffect(() => {

        if (searchInput === "") {
            setproductlist([...filterHelper])
        } else {
            let x = setTimeout(() => {
                let z = [...filterHelper];
                let filteredData = z.filter(item => item.name.includes(searchInput));

                setproductlist([...filteredData])

            }, 1000)

            return () => clearTimeout(x)
        }


    }, [searchInput])


    const smstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        minHeight: 400,
        maxHeight: 700,
        overflow: "scroll",
        color: "black",
        borderRadius: "15px"
    };
    // console.log("type----->", type)
    const rawData = {
        method: "DiscountCode",
        discountCode: "",
        discountText: "",
        discountvalue: "",
        valueType: "",
        minimumPurchaseRequirement: "",
        customerEligibility: "",
        maximumDiscountUses: "",
        max_discount_price: "",
        startdate: "",
        enddate: "",
        starttime: "",
        endtime: "",
        min_order_amount: "",
        Buyxquantity: "",
        GetYquantity: ""



    }
    const [value, setValue] = useState('plain');
    const [togglePerAmount, setTogglePerAmount] = useState(false);
    const [offerData, setOfferData] = useState(rawData);
    const [productList, setproductlist] = useState([])
    const [totalcheckedProducts, settotalcheckedProducts] = useState([])
    const [customerBuysItemsorAmount, setcustomerBuysItemsorAmount] = useState(true)
    const [methodType, setmethodType] = useState(false)
    const [creatingOffer, setcreatingOffer] = useState(false)


    function onInputChangeHandler(e) {

        setOfferData((prev) => {
            return { ...prev, [e.target.name]: e.target.value }
        })

    }

    async function onCreateHandler() {
        setcreatingOffer(true);
        let { discountCode, discountvalue, startdate, starttime, enddate, endtime, Buyxquantity, GetYquantity } = offerData
        let payload = { ...offerData, item_with_offer: checkedItems.map(item => item.id), seller_id: current_sellerid, image: "", offer_name: discountCode, discount: discountvalue, location: ["235490", "234780"], start: `${startdate}T${starttime}:00.000Z`, end: `${enddate}T${endtime}:00.000Z`, buyX: Buyxquantity, getY: GetYquantity, item_on_offer: customergetscheckedItems[0]?.id }

        console.log("payload------->", payload);

        let apiee;

        if (type === "amountofforder") {
            if (togglePerAmount === false) {
                apiee = "create_discount_percent";
            }
            if (togglePerAmount === true) {
                apiee = "create_discount_amount";
            }
        }
        if (type === "buyXgetY") {
            apiee = "create_discount_buyxgety";
        }

        try {
            const { data } = await axios({
                url: `${baseNodeapiUrl}/cms/offer/${apiee}`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    ...payload,
                },
            });


            if (data) {
                toast.success("Offer Created Successfully!")
            } else {
                toast.error("Error in creating Offer")
            }

            setcreatingOffer(false)

        } catch (error) {
            console.log(error);
            setcreatingOffer(false)
            toast.error("Server not working, please try Later!")
        }
    }

    async function getproductlist() {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/listproductsallsvar`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    seller_id: current_sellerid,
                },
            });

            if (data.status === 200) {

                setproductlist(data.data);
                setfilterHelper(data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }



    async function addProductsHandler() {
        console.log("data--------->", checkedItems);
        setiopen(false)
    }
    async function addCgProductsHandler() {
        console.log("data--------->", customergetscheckedItems);
        setigopen(false)
    }


    const [checkedItems, setcheckedItems] = useState([])
    const [customergetscheckedItems, setcustomergetscheckedItems] = useState([])

    function changeHandler(item) {
        let z = [...checkedItems]
        let x = z.findIndex(t => t.id == item.id)
        if (x === -1) {
            z.push(item)
            setcheckedItems([...z])

        } else {
            z.splice(x, 1)
            setcheckedItems([...z])
        }
    }

    function RemoveHandler(item) {
        console.log(item);
        let z = [...checkedItems]
        let i = z.findIndex((product) => product.id === item.id)
        console.log(i)
        z.splice(i, 1)
        setcheckedItems([...z])

    }
    function customerGetschangeHandler(item) {
        let z = [...customergetscheckedItems]
        let x = z.findIndex(t => t.id == item.id)
        if (x === -1) {
            z.push(item)
            setcustomergetscheckedItems([...z])

        } else {
            z.splice(x, 1)
            setcustomergetscheckedItems([...z])
        }
    }


    function customerGetsRemoveHandler(item) {
        console.log(item);
        let z = [...customergetscheckedItems]
        let i = z.findIndex((product) => product.id === item.id)
        console.log(i)
        z.splice(i, 1)
        setcustomergetscheckedItems([...z])

    }


    function generateRandomCode() {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const codeLength = 8;
        let code = "";

        for (let i = 0; i < codeLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters.charAt(randomIndex);
        }
        setOfferData(prev => ({
            ...prev, discountCode: code.toUpperCase()
        }))
        return code;
    }

    console.log("OutsidecheckedItems----->", checkedItems)
    return (
        <ThemeMaker>
            <div className="row w-100 m-0 mt-1" >
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">
                                {type === "amountofforder" ? "Create order discount" : type === "buyXgetY" ? "Buy X Get Y" : null}
                            </h6>

                            <Link to="/discount">  <button className='btn btn-primary'>Offer Lists</button> </Link>
                        </div>

                        <div className='card-body' style={{ minHeight: "80vh", background: "#f7f7f7" }}>

                            <div className='d-flex'>

                                <div style={{ width: "70%", minHeight: "80vh", color: "black" }} className=' p-2'>

                                    <div className='border p-3 bg-white' style={{ borderRadius: "20px" }}>

                                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Amount off order</p>

                                        <div>
                                            <p style={{ fontSize: "17px", fontWeight: "500" }}>Method  </p>

                                            <div className='w-25 '>

                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" style={{ width: "22px", height: "16px" }} name='method' value="DiscountCode" onChange={onInputChangeHandler} /> <label htmlFor="DiscountCode" className='p-0 m-0' onChange={onInputChangeHandler} >Discount Code</label>
                                                </div>


                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" style={{ width: "22px", height: "16px" }} id='AutomaticDiscount' name='method' onChange={onInputChangeHandler} value="AutomaticDiscount" /> <label className='p-0 m-0' htmlFor=" AutomaticDiscount" >Automatic Discount</label>
                                                </div>

                                            </div>

                                            {(offerData.method === "DiscountCode") ? <div className='mt-3 '>
                                                <p style={{ fontSize: "17px", fontWeight: "500" }}>Discount Code</p>
                                                <div className='d-flex' style={{ gap: "20px" }}>
                                                    <input type="text" className='form-control' name='discountCode' value={offerData.discountCode} onChange={onInputChangeHandler} />  <button className='btn btn-secondary' onClick={() => { generateRandomCode() }}>Generate</button>

                                                </div>

                                                <div>
                                                    <span >  Customers must enter this code at checkout.</span>
                                                </div>

                                            </div> : <>
                                                <div className='mt-3 '>
                                                    <p style={{ fontSize: "17px", fontWeight: "500" }}>Title</p>
                                                    <div className='d-flex' style={{ gap: "20px" }}>
                                                        <input type="text" className='form-control' name='discountText' onChange={onInputChangeHandler} />

                                                    </div>

                                                    <div>
                                                        <span >  Customers will see this in their cart and at checkout..</span>
                                                    </div>

                                                </div>
                                            </>}
                                        </div>

                                    </div>


                                    {type === "amountofforder" ? <div className='border p-3 mt-3 bg-white' style={{ borderRadius: "20px" }}>

                                        <p className='text-dark' style={{ fontSize: "18px", fontWeight: "500" }}>Value</p>

                                        <div className='d-flex w-100'>

                                            <div className=' d-flex border w-25 ' style={{ borderRadius: "12px", cursor: "pointer", color: 'black', }}>

                                                <div className={togglePerAmount ? `${styles.perAmount}` : `${styles.perAmount} ${styles.togglePerAmount}`} style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} onClick={() => setTogglePerAmount(false)}>
                                                    Percentage
                                                </div>
                                                <div className={!togglePerAmount ? `${styles.perAmount}` : `${styles.perAmount} ${styles.togglePerAmount}`} onClick={() => setTogglePerAmount(true)} style={{ borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} >
                                                    Fixed Amount
                                                </div>

                                            </div>
                                            <div className='w-75 ml-3'>
                                                <input type="text" className='form-control' placeholder={!togglePerAmount ? "Percentage" : "Amount"} name='discountvalue' onChange={onInputChangeHandler} />
                                            </div>

                                        </div>

                                    </div> : null}


                                    {type !== "buyXgetY" ? <> {!togglePerAmount ? <div className='border p-3 mt-3 bg-white' style={{ borderRadius: "20" }}>

                                        <p style={{ fontSize: "17px", fontWeight: "500" }}>Maximum Discount Price</p>
                                        <input type="text" className='form-control' name='max_discount_price' onChange={onInputChangeHandler} />

                                    </div> : null}</> : null}


                                    {type !== "buyXgetY" ? <div className='border p-3 mt-3 bg-white' style={{ borderRadius: "20px" }}>

                                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Minimum purchase requirements</p>

                                        <div className=''>
                                            <div className='w-50 '>

                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" className={`${styles.radioStyle}`} name='minimumPurchaseRequirement' value="minimumPurchaseRequirement" onChange={onInputChangeHandler} /> <label  >No minimum requirements</label>
                                                </div>


                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" className={`${styles.radioStyle}`} name='minimumPurchaseRequirement' value="minimumPurchaseRequirement" onChange={onInputChangeHandler} /> <label style={{ width: "230px" }} >Minimum purchase amount (₹)</label>
                                                    <input type="text" value={offerData.min_order_amount} name="min_order_amount" className='form-control' style={{ width: "100px" }} onChange={onInputChangeHandler} />
                                                </div>


                                                <div className='d-flex align-items-center mt-2' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" className={`${styles.radioStyle}`} name='minimumPurchaseRequirement' value="minimumPurchaseRequirement" onChange={onInputChangeHandler} /> <label style={{ width: "230px" }}  >Minimum quantity of items</label>
                                                    <input type="text" className='form-control' style={{ width: "100px" }} />
                                                </div>

                                            </div>

                                        </div>

                                    </div> : null
                                    }
                                    {/* ------------------------------------  CUSTOMER BUYS SECTION---------------------------- */}
                                    <div className='border p-3 mt-3 bg-white' style={{ borderRadius: "20px" }}>

                                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Customer Buys</p>

                                        <div className=''>
                                            <div className='w-50 '>

                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" className={`${styles.radioStyle}`} name='CUSTOMERGETS' value="minimumPurchaseRequirement" onChange={() => setcustomerBuysItemsorAmount(false)} /> <label  >Minimum quantity of items</label>
                                                </div>
                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" className={`${styles.radioStyle}`} name='CUSTOMERGETS' value="minimumPurchaseRequirement" onChange={() => setcustomerBuysItemsorAmount(true)} /> <label  >Minimum purchase amount</label>
                                                </div>

                                            </div>


                                            <div style={{ gap: "20px" }} className='w-100 d-flex mt-3'>
                                                {customerBuysItemsorAmount ? <div style={{ width: '30%' }}>
                                                    <p style={{ fontSize: "14px", fontWeight: "500" }}>Amount</p>
                                                    <input type="text" className='form-control' name='Buyamount' placeholder='Enter Amount' />
                                                </div> : <div style={{ width: '30%' }}>
                                                    <p style={{ fontSize: "14px", fontWeight: "500" }}>Quantity</p>
                                                    <input type="text" className='form-control' value={offerData?.Buyxquantity} name='Buyxquantity' placeholder='Enter Quantity' onChange={onInputChangeHandler} />
                                                </div>}
                                                <div style={{ width: '70%' }} className='d-none'>
                                                    <p style={{ fontSize: "14px", fontWeight: "500" }}>Any Items from</p>
                                                    <select name="" id="" className='form-control'>
                                                        <option value="">Specific Products</option>
                                                        <option value="">Specific Collection</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div style={{ gap: "20px" }} className='w-100 d-flex mt-3'>

                                                <div style={{ width: '70%' }}>
                                                    <input type="text" className='form-control' placeholder='Search Products' />
                                                </div>
                                                <div >
                                                    <button className='btn btn-secondary' onClick={handleImageOpen}>Browse</button>
                                                </div>


                                                <Modal open={iopen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >

                                                    <Box sx={smstyle}>


                                                        <div className="card-header py-3 position-sticky " style={{ top: "0" }}>
                                                            <h6 className="m-0 font-weight-bold text-left d-flex justify-content-between w-100">
                                                                <div className='d-flex align-items-center' style={{ gap: '50px' }}>
                                                                    <span style={{ color: "black", width: "150px" }}>Product List</span>

                                                                    <input type="text" placeholder='Search Product' className='form-control' value={searchInput} onChange={(e) => setsearchInput(e.target.value)} />

                                                                </div>


                                                                <CloseIcon style={{ cursor: "pointer" }} onClick={handleimageClose} />


                                                            </h6>
                                                        </div>
                                                        <div className='card-body p-0 m-0' >

                                                            <div className='mt-2'>

                                                                {
                                                                    productList.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <ProductList item={item} key={index} changeHandler={changeHandler} checkedItems={checkedItems} />
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </div>


                                                            {/* <div className={`${styles.addButtonSection}`}>
                                                                    <button className='btn btn-primary' onClick={addProductsHandler}>Add</button>
                                                                </div> */}

                                                        </div>


                                                    </Box>
                                                </Modal>


                                            </div>

                                        </div>


                                        <div className='mt-3' style={{ maxHeight: "300px", overflow: "scroll" }}>
                                            {
                                                checkedItems?.map(item => (
                                                    <>
                                                        <div className=' d-flex justify-content-between' style={{ gap: "20px" }}>
                                                            <div className='px-3 py-3 d-flex ' style={{ gap: "20px" }}>
                                                                <div style={{ width: "50px", height: "50px" }}>
                                                                    <img src={item.image} alt="" className='w-100 h-100' />
                                                                </div>
                                                                <p>{item.name}</p>
                                                            </div>


                                                            <CloseIcon className='mt-3 mr-2 text-gray' style={{ cursor: "pointer" }} onClick={() => RemoveHandler(item)} />



                                                        </div>


                                                        <hr className={`sidebar-divider my-0`} />
                                                    </>

                                                ))
                                            }
                                        </div>

                                    </div>
                                    {/* ------------------------------------  CUSTOMER BUYS SECTION---------------------------- */}



                                    {/* ------------------------------------  CUSTOMER GETS SECTION---------------------------- */}
                                    {type === "buyXgetY" ? <div className='border p-3 mt-3 bg-white' style={{ borderRadius: "20px" }}>

                                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Customer Gets</p>
                                        <p style={{ fontSize: "14px", fontWeight: "300", color: "grey" }}>Customers must add the quantity of items specified below to their cart.</p>

                                        <div className=''>



                                            <div style={{ gap: "20px" }} className='w-100 d-flex mt-3'>
                                                <div style={{ width: '30%' }}>
                                                    <p style={{ fontSize: "14px", fontWeight: "500" }}>Quantity</p>
                                                    <input type="text" className='form-control' value={offerData.GetYquantity} name="GetYquantity" placeholder='Enter Quantity' onChange={onInputChangeHandler} />
                                                </div>
                                              
                                                <div style={{ width: '70%' }} className='d-none'>
                                                    <p style={{ fontSize: "14px", fontWeight: "500" }}>Any Items from</p>
                                                    <select name="" id="" className='form-control'>
                                                        <option value="">Specific Products</option>
                                                        <option value="">Specific Collection</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div style={{ gap: "20px" }} className='w-100 d-flex mt-3'>

                                                <div style={{ width: '70%' }}>
                                                    <input type="text" className='form-control' placeholder='Search Products' />
                                                </div>
                                                <div >
                                                    <button className='btn btn-secondary' onClick={handleCGOpen}>Browse</button>
                                                </div>


                                                <Modal open={igopen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >

                                                    <Box sx={smstyle}>


                                                        <div className="card-header py-3 position-sticky " style={{ top: "0" }}>
                                                            <h6 className="m-0 font-weight-bold text-left d-flex justify-content-between w-100">
                                                                <div className='d-flex align-items-center' style={{ gap: '50px' }}>
                                                                    <span style={{ color: "black", width: "150px" }}>Add Products</span>

                                                                    <input type="text" placeholder='Search Product' className='form-control' value={searchInput} onChange={(e) => setsearchInput(e.target.value)} />

                                                                </div>


                                                                <CloseIcon style={{ cursor: "pointer" }} onClick={handleCGClose} />


                                                            </h6>
                                                        </div>
                                                        <div className='card-body p-0 m-0' >

                                                            <div className='mt-2'>

                                                                {
                                                                    productList.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <CustomerGetsProductList item={item} key={index} customerGetschangeHandler={customerGetschangeHandler} customergetscheckedItems={customergetscheckedItems} />
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </div>


                                                            {/* <div className={`${styles.addButtonSection}`}>
                                                                    <button className='btn btn-primary' onClick={addCgProductsHandler}>Add</button>
                                                                </div> */}

                                                        </div>


                                                    </Box>
                                                </Modal>


                                            </div>

                                        </div>


                                        <div className='mt-3' style={{ maxHeight: "300px", overflow: "scroll" }}>
                                            {
                                                customergetscheckedItems?.map(item => (
                                                    <>
                                                        <div className=' d-flex justify-content-between' style={{ gap: "20px" }}>
                                                            <div className='px-3 py-3 d-flex ' style={{ gap: "20px" }}>
                                                                <div style={{ width: "50px", height: "50px" }}>
                                                                    <img src={item.image} alt="" className='w-100 h-100' />
                                                                </div>
                                                                <p>{item.name}</p>
                                                            </div>


                                                            <CloseIcon className='mt-3 mr-2 text-gray' style={{ cursor: "pointer" }} onClick={() => customerGetsRemoveHandler(item)} />



                                                        </div>


                                                        <hr className={`sidebar-divider my-0`} />
                                                    </>

                                                ))
                                            }
                                        </div>

                                        <div>
                                            <p style={{ fontSize: "18px", fontWeight: "500" }}>At a discounted value</p>

                                            <div className='d-flex align-items-center' style={{ gap: "13px" }}>
                                                <input type="radio" name='discountValue' className={`${styles.radioStyle}`} />   <label htmlFor="" className='p-0 m-0'>Percentage</label>  <input type="text" className='form-control ' style={{ width: '200px' }} placeholder='Enter % Value' />
                                            </div>


                                            <div className='d-flex align-items-center' style={{ gap: "13px" }}>
                                                <input type="radio" name='discountValue' className={`${styles.radioStyle}`} /> <label htmlFor="">Free</label>
                                            </div>
                                        </div>

                                    </div> : null}

                                    {/* ------------------------------------  CUSTOMER GETS SECTION---------------------------- */}


                                    <div className='border p-3 mt-3 bg-white' style={{ borderRadius: "20px" }}>

                                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Customer eligibility</p>

                                        <div className=''>
                                            <div className='w-50 '>

                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" className={`${styles.radioStyle}`} name='customerEligibility' value="customerEligibility" onChange={onInputChangeHandler} /> <label htmlFor="method" >All customers</label>
                                                </div>
                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" className={`${styles.radioStyle}`} name='customerEligibility' value="customerEligibility" onChange={onInputChangeHandler} /> <label htmlFor="method" >Specific customer segments</label>
                                                </div>
                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" className={`${styles.radioStyle}`} name='customerEligibility' value="customerEligibility" onChange={onInputChangeHandler} /> <label htmlFor="method" >Specific customers</label>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className='border p-3 mt-3 bg-white' style={{ borderRadius: "20px" }}>

                                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Maximum discount uses</p>

                                        <div className=''>
                                            <div className='w-75 '>

                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" className={`${styles.radioStyle}`} name='maximumDiscountUses' value="1" onChange={onInputChangeHandler} /> <label htmlFor="method" >
                                                        Limit number of times this discount can be used in total</label>

                                                    <input type="text" className='form-control' style={{ width: "100px" }} />
                                                </div>


                                                <div className='d-flex align-items-center' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <input type="radio" className={`${styles.radioStyle}`} name='maximumDiscountUses' value="2" onChange={onInputChangeHandler} /> <label htmlFor="method" >Limit to one use per customer</label>
                                                </div>


                                            </div>

                                        </div>

                                    </div>


                                    <div className='border p-3 mt-3 bg-white w-100' style={{ borderRadius: "20px" }}>

                                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Active Dates</p>

                                        <div className='w-100'>
                                            <div className='w-100 d-flex ' style={{ gap: "50px" }}>

                                                <div className='d-flex align-items-start flex-column w-50' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <label htmlFor="method" className='p-0 m-0' > Start Date </label>
                                                    <input type="date" className='form-control' name='startdate' onChange={onInputChangeHandler} />
                                                </div>
                                                <div className='d-flex  align-items-start flex-column w-50' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <label htmlFor="method" className='p-0 m-0'>Start Time</label>
                                                    <input type="time" className='form-control' name='starttime' onChange={onInputChangeHandler} />
                                                </div>


                                            </div>
                                            <div className='w-100 d-flex mt-3' style={{ gap: "50px" }}>

                                                <div className='d-flex align-items-start flex-column w-50' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <label htmlFor="method" className='p-0 m-0' > End Date </label>
                                                    <input type="date" className='form-control' name='enddate' onChange={onInputChangeHandler} />
                                                </div>
                                                <div className='d-flex  align-items-start flex-column w-50' style={{ gap: "12px", fontSize: "16px", fontWeight: "500" }}>
                                                    <label htmlFor="method" className='p-0 m-0'>End Time</label>
                                                    <input type="time" className='form-control' name='endtime' onChange={onInputChangeHandler} />
                                                </div>


                                            </div>

                                        </div>

                                    </div>

                                    <div className='w-100 d-flex justify-content-end mt-2'>
                                        <button className='btn btn-secondary' onClick={onCreateHandler}>
                                            {creatingOffer ? "Please Wait" : "Create Offer"}
                                        </button>
                                    </div>

                                </div>

                                {/*------------------------------ Right Side------------------------------------ */}

                                <div style={{ width: "30%", minHeight: "80vh", color: "black" }} className=' p-2'>

                                    <div className='border p-3 mt-3 bg-white' style={{ borderRadius: "20px", minHeight: "400px" }}>

                                        <p className='' style={{ fontSize: "18px", fontWeight: "500" }}>Summary</p>

                                        <div className=''>

                                            <p className='font-weight-bold ' style={{ fontSize: "20px" }}>{offerData.discountCode}</p>
                                            <p className='font-weight-bold ' style={{ fontSize: "20px" }}>{offerData.discountText}</p>

                                            <div>
                                                <p className='font-weight-bold ' style={{ fontSize: "17px" }}>Type & Method </p>

                                                <ul className='pl-4'>
                                                    <li>Amount off order</li>
                                                    {offerData.method !== "" ? <li>{offerData.method}</li> : null}
                                                </ul>
                                            </div>
                                            <div>
                                                <p className='font-weight-bold ' style={{ fontSize: "17px" }}>Details </p>

                                                <ul className='pl-4'>
                                                    {offerData.customerEligibility !== "" ? <li>{offerData.customerEligibility}</li> : null}
                                                    {offerData.minimumPurchaseRequirement !== "" ? <li>{offerData.minimumPurchaseRequirement}</li> : null}
                                                    {offerData.method !== "" ? <li>{offerData.method}</li> : null}
                                                </ul>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ThemeMaker >
    )
}


const ProductList = ({ item, changeHandler, checkedItems }) => {

    let x = checkedItems.findIndex(product => product.id === item.id)

    return (
        <li style={{ listStyle: "none", padding: "10px", gap: '20px', paddingLeft: "15px", borderBottom: "1px solid lightgray" }} className='d-flex mt-2 align-items-center' >
            <section>
                <input type="checkbox" onChange={() => changeHandler(item)} style={{ height: "18px", width: "18px", cursor: "pointer" }}
                    checked={x !== -1 ? true : false}
                />
            </section>

            <section className='w-50 d-flex justify-content-start align-items-center' style={{ gap: "15px" }}>

                <div style={{ width: "50px", height: "50px" }}>
                    <img src={item.image} alt="" className='w-100 h-100' />
                </div>

                <label > {item.name} </label>



            </section>
            <section style={{ width: "100px" }}>
                {item.quantity} available
            </section>
            <section  >
                ₹ {item.selling_price}

            </section>
        </li>
    )
}

const CustomerGetsProductList = ({ item, customerGetschangeHandler, customergetscheckedItems }) => {

    let x = customergetscheckedItems.findIndex(product => product.id === item.id)

    return (
        <li style={{ listStyle: "none", padding: "10px", gap: '20px', paddingLeft: "15px", borderBottom: "1px solid lightgray" }} className='d-flex mt-2 align-items-center' >
            <section>
                <input type="checkbox" onChange={() => customerGetschangeHandler(item)} style={{ height: "18px", width: "18px", cursor: "pointer" }}
                    checked={x !== -1 ? true : false}
                />
            </section>

            <section className='w-50 d-flex justify-content-start align-items-center' style={{ gap: "15px" }}>

                <div style={{ width: "50px", height: "50px" }}>
                    <img src={item.image} alt="" className='w-100 h-100' />
                </div>

                <label > {item.name} </label>



            </section>
            <section style={{ width: "100px" }}>
                {item.quantity} available
            </section>
            <section  >
                ₹ {item.selling_price}

            </section>
        </li>
    )
}